var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c("v-app", [
        _c(
          "div",
          { staticClass: "inner-container only-heading-part mt-0" },
          [
            _c(
              "v-container",
              {
                staticClass: "v-main-content only-simple-content pt-0",
                attrs: { fluid: "" },
              },
              [
                [
                  _c(
                    "v-card",
                    {
                      staticClass:
                        " transparent rounded-lg px-0 h-100 pa-4 pb-8 pt-0 extra-padding v-card v-sheet theme--light",
                      attrs: { elevation: "0" },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { id: "app" } },
                        [
                          _c(
                            "v-card-title",
                            { staticClass: "px-0 py-0 mb-2" },
                            [
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "primarybase--text h5--text my-3",
                                },
                                [_vm._v(_vm._s(_vm.$t("tabs.campaigns")))]
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass:
                                    "sub-heading-with-btn d-flex flex-wrap justify-space-between",
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "px-0",
                                      attrs: {
                                        cols: "12",
                                        xl: "4",
                                        lg: "6",
                                        md: "3",
                                        sm: "12",
                                      },
                                    },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "mt-0 pt-0",
                                        attrs: {
                                          width: "200",
                                          "append-icon": "mdi-magnify",
                                          placeholder: _vm.$t("labels.search"),
                                          "hide-details": "",
                                        },
                                        model: {
                                          value: _vm.searchMain,
                                          callback: function ($$v) {
                                            _vm.searchMain = $$v
                                          },
                                          expression: "searchMain",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex justify-end",
                                      attrs: {
                                        cols: "12",
                                        xl: "8",
                                        lg: "6",
                                        md: "9",
                                        sm: "12",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-dialog",
                                        {
                                          attrs: {
                                            width: "745px",
                                            persistent: "",
                                            transition:
                                              "dialog-center-transition",
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function (ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _vm.isReadOnly()
                                                    ? _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              color:
                                                                "primary ml-3",
                                                              depressed: "",
                                                            },
                                                          },
                                                          !_vm.isReadOnly()
                                                            ? {}
                                                            : {
                                                                click: function () {
                                                                  return _vm.openPermissionReadOnlyPopup()
                                                                },
                                                              }
                                                        ),
                                                        [
                                                          _vm._v(
                                                            " Add Automated Campaign "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  !_vm.isReadOnly()
                                                    ? _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                color:
                                                                  "primary ml-3",
                                                                depressed: "",
                                                              },
                                                              on: {
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.generateUUID()
                                                                },
                                                              },
                                                            },
                                                            "v-btn",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            " Add Automated Campaign "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              },
                                            },
                                          ]),
                                          model: {
                                            value: _vm.dialog,
                                            callback: function ($$v) {
                                              _vm.dialog = $$v
                                            },
                                            expression: "dialog",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            { staticClass: "auto-camp" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "sticky-top d-flex w-100 ",
                                                },
                                                [
                                                  _c(
                                                    "h5",
                                                    {
                                                      staticClass:
                                                        "h5--text-bold primarybase--text text-center w-100",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "campaignGenerator.createCampaignHeading"
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass:
                                                        "close primarydark--text d-flex justify-end",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.dialog = false
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("mdi-close")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "inner-container",
                                                },
                                                [
                                                  _c(
                                                    "v-container",
                                                    {
                                                      staticClass:
                                                        "stepper-template camp-gen-stepper-popup pa-0 ",
                                                      attrs: { fluid: "" },
                                                    },
                                                    [
                                                      [
                                                        _c(
                                                          "v-stepper",
                                                          {
                                                            attrs: {
                                                              "alt-labels": "",
                                                            },
                                                            model: {
                                                              value: _vm.e1,
                                                              callback: function (
                                                                $$v
                                                              ) {
                                                                _vm.e1 = $$v
                                                              },
                                                              expression: "e1",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-stepper-header",
                                                              {
                                                                staticClass:
                                                                  "camp-gen-stepper margin-auto px-0",
                                                              },
                                                              [
                                                                _c(
                                                                  "v-stepper-step",
                                                                  {
                                                                    staticClass:
                                                                      "subtitle-2-bold",
                                                                    attrs: {
                                                                      complete:
                                                                        _vm.e1 >
                                                                        1,
                                                                      step: "1",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm.e1 >= 1
                                                                      ? [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "createCampaign.chooseApp"
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      : _vm._e(),
                                                                  ],
                                                                  2
                                                                ),
                                                                _c("v-divider"),
                                                                _c(
                                                                  "v-stepper-step",
                                                                  {
                                                                    staticClass:
                                                                      "subtitle-2-bold",
                                                                    attrs: {
                                                                      complete:
                                                                        _vm.e1 >
                                                                        2,
                                                                      step: "2",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm.e1 >= 2
                                                                      ? [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "labels.selectCategoryMsg"
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      : _vm._e(),
                                                                  ],
                                                                  2
                                                                ),
                                                                _c("v-divider"),
                                                                _c(
                                                                  "v-stepper-step",
                                                                  {
                                                                    staticClass:
                                                                      "subtitle-2-bold",
                                                                    attrs: {
                                                                      complete:
                                                                        _vm.e1 >
                                                                        3,
                                                                      step: "3",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm.e1 >= 3
                                                                      ? [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "campaignGenerator.Segmentation"
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      : _vm._e(),
                                                                  ],
                                                                  2
                                                                ),
                                                                _c("v-divider"),
                                                                _c(
                                                                  "v-stepper-step",
                                                                  {
                                                                    staticClass:
                                                                      "subtitle-2-bold",
                                                                    attrs: {
                                                                      complete:
                                                                        _vm.e1 >
                                                                        4,
                                                                      step: "4",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm.e1 >= 4
                                                                      ? [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "addKeys.addKeywords"
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      : _vm._e(),
                                                                  ],
                                                                  2
                                                                ),
                                                                _c("v-divider"),
                                                                _c(
                                                                  "v-stepper-step",
                                                                  {
                                                                    staticClass:
                                                                      " subtitle-2-bold",
                                                                    attrs: {
                                                                      complete:
                                                                        _vm.step >
                                                                        5,
                                                                      step: "5",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm.e1 >= 5
                                                                      ? [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "labels.totalSummary"
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      : _vm._e(),
                                                                  ],
                                                                  2
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-stepper-items",
                                                              {
                                                                staticClass:
                                                                  "stepper-items-custom",
                                                              },
                                                              [
                                                                _c(
                                                                  "v-stepper-content",
                                                                  {
                                                                    staticClass:
                                                                      "pa-0",
                                                                    attrs: {
                                                                      step: "1",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-card",
                                                                      {
                                                                        staticClass:
                                                                          "py-6 px-0",
                                                                        attrs: {
                                                                          elevation:
                                                                            "0",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "p",
                                                                          {
                                                                            staticClass:
                                                                              "body--text-1-bold  primarybase--text  mb-9 mt-3",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "createCampaign.para"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-form",
                                                                          {
                                                                            ref:
                                                                              "campgen1",
                                                                            model: {
                                                                              value:
                                                                                _vm.valid1,
                                                                              callback: function (
                                                                                $$v
                                                                              ) {
                                                                                _vm.valid1 = $$v
                                                                              },
                                                                              expression:
                                                                                "valid1",
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-row",
                                                                              {
                                                                                attrs: {
                                                                                  align:
                                                                                    "center",
                                                                                },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-col",
                                                                                  {
                                                                                    staticClass:
                                                                                      "py-2",
                                                                                    attrs: {
                                                                                      lg:
                                                                                        "12",
                                                                                      md:
                                                                                        "12",
                                                                                      sm:
                                                                                        "12",
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "field",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "label",
                                                                                          {
                                                                                            staticClass:
                                                                                              "d-flex",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "createCampaign.chooseCampGroup"
                                                                                                )
                                                                                              ) +
                                                                                                " "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "v-select",
                                                                                          {
                                                                                            attrs: {
                                                                                              "item-text":
                                                                                                "orgName",
                                                                                              "item-value":
                                                                                                "orgId",
                                                                                              items:
                                                                                                _vm.getCampaignGroups,
                                                                                              placeholder: _vm.$t(
                                                                                                "createCampaign.chooseCamp"
                                                                                              ),
                                                                                              dense:
                                                                                                "",
                                                                                              rules:
                                                                                                _vm.campaignGroupRules,
                                                                                            },
                                                                                            on: {
                                                                                              change:
                                                                                                _vm.orgChanged,
                                                                                            },
                                                                                            model: {
                                                                                              value:
                                                                                                _vm.orgId,
                                                                                              callback: function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.orgId = $$v
                                                                                              },
                                                                                              expression:
                                                                                                "orgId",
                                                                                            },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "v-col",
                                                                                  {
                                                                                    staticClass:
                                                                                      "py-2",
                                                                                    attrs: {
                                                                                      lg:
                                                                                        "12",
                                                                                      md:
                                                                                        "12",
                                                                                      sm:
                                                                                        "12",
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "field",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "label",
                                                                                          {
                                                                                            staticClass:
                                                                                              " d-flex",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "createCampaign.chooseApp"
                                                                                                )
                                                                                              ) +
                                                                                                " "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "v-autocomplete",
                                                                                          {
                                                                                            attrs: {
                                                                                              items:
                                                                                                _vm.appList,
                                                                                              dense:
                                                                                                "",
                                                                                              "item-text":
                                                                                                "appName",
                                                                                              "item-value":
                                                                                                "adamId",
                                                                                              placeholder: _vm.$t(
                                                                                                "createCampaign.selectApp"
                                                                                              ),
                                                                                              rules:
                                                                                                _vm.appRules,
                                                                                              "return-object":
                                                                                                "",
                                                                                              loading:
                                                                                                _vm
                                                                                                  .$apollo
                                                                                                  .queries
                                                                                                  .apps
                                                                                                  .loading,
                                                                                            },
                                                                                            on: {
                                                                                              change:
                                                                                                _vm.appChanged,
                                                                                            },
                                                                                            scopedSlots: _vm._u(
                                                                                              [
                                                                                                {
                                                                                                  key:
                                                                                                    "item",
                                                                                                  fn: function (
                                                                                                    data
                                                                                                  ) {
                                                                                                    return [
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-img",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "rounded-lg mr-2",
                                                                                                            attrs: {
                                                                                                              color:
                                                                                                                "secondary",
                                                                                                              "lazy-src":
                                                                                                                data
                                                                                                                  .item
                                                                                                                  .appIcon,
                                                                                                              "max-width":
                                                                                                                "30",
                                                                                                              "max-height":
                                                                                                                "30",
                                                                                                              "min-width":
                                                                                                                "20",
                                                                                                              "min-height":
                                                                                                                "20",
                                                                                                              src:
                                                                                                                data
                                                                                                                  .item
                                                                                                                  .appIcon,
                                                                                                            },
                                                                                                          }
                                                                                                        ),
                                                                                                        _c(
                                                                                                          "v-list-item-content",
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-list-item-title",
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  _vm._s(
                                                                                                                    data
                                                                                                                      .item
                                                                                                                      .appName
                                                                                                                  )
                                                                                                                ),
                                                                                                              ]
                                                                                                            ),
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                      ],
                                                                                                    ]
                                                                                                  },
                                                                                                },
                                                                                              ]
                                                                                            ),
                                                                                            model: {
                                                                                              value:
                                                                                                _vm.selectedApp,
                                                                                              callback: function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.selectedApp = $$v
                                                                                              },
                                                                                              expression:
                                                                                                "selectedApp",
                                                                                            },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-card-actions",
                                                                      {
                                                                        staticClass:
                                                                          " d-flex justify-end sticky-bottom",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "mr-4 px-6",
                                                                            attrs: {
                                                                              color:
                                                                                "primary",
                                                                              large:
                                                                                "",
                                                                              outlined:
                                                                                "",
                                                                            },
                                                                            on: {
                                                                              click: function (
                                                                                $event
                                                                              ) {
                                                                                _vm.draftList = true
                                                                              },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "campaignGenerator.showDraft"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "px-6 ml-0",
                                                                            attrs: {
                                                                              color:
                                                                                "primary",
                                                                              depressed:
                                                                                "",
                                                                              large:
                                                                                "",
                                                                              disabled: !_vm.valid1,
                                                                            },
                                                                            on: {
                                                                              click: function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.appSelected()
                                                                              },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "createCampaign.next"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-stepper-content",
                                                                  {
                                                                    staticClass:
                                                                      "pa-0",
                                                                    attrs: {
                                                                      step: "2",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-card",
                                                                      {
                                                                        staticClass:
                                                                          " px-0 py-3 ",
                                                                        attrs: {
                                                                          elevation:
                                                                            "0",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-form",
                                                                          {
                                                                            ref:
                                                                              "campgen2",
                                                                            staticClass:
                                                                              "campaignGeneratorForm",
                                                                            model: {
                                                                              value:
                                                                                _vm.valid2,
                                                                              callback: function (
                                                                                $$v
                                                                              ) {
                                                                                _vm.valid2 = $$v
                                                                              },
                                                                              expression:
                                                                                "valid2",
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-row",
                                                                              [
                                                                                _c(
                                                                                  "v-col",
                                                                                  {
                                                                                    staticClass:
                                                                                      " ",
                                                                                    attrs: {
                                                                                      lg:
                                                                                        "12",
                                                                                      md:
                                                                                        "12",
                                                                                      sm:
                                                                                        "12",
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "field",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-checkbox",
                                                                                          {
                                                                                            staticClass:
                                                                                              "ma-1",
                                                                                            attrs: {
                                                                                              label: _vm.$t(
                                                                                                "campaignGenerator.Brand"
                                                                                              ),
                                                                                              color:
                                                                                                "primarylight",
                                                                                              value: true,
                                                                                              "hide-details":
                                                                                                "",
                                                                                            },
                                                                                            model: {
                                                                                              value:
                                                                                                _vm.brandCheck,
                                                                                              callback: function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.brandCheck = $$v
                                                                                              },
                                                                                              expression:
                                                                                                "brandCheck",
                                                                                            },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "field",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-checkbox",
                                                                                          {
                                                                                            staticClass:
                                                                                              "ma-1",
                                                                                            attrs: {
                                                                                              label: _vm.$t(
                                                                                                "campaignGenerator.Category"
                                                                                              ),
                                                                                              color:
                                                                                                "primarylight",
                                                                                              value: true,
                                                                                              "hide-details":
                                                                                                "",
                                                                                            },
                                                                                            model: {
                                                                                              value:
                                                                                                _vm.categoryCheck,
                                                                                              callback: function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.categoryCheck = $$v
                                                                                              },
                                                                                              expression:
                                                                                                "categoryCheck",
                                                                                            },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "field",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-checkbox",
                                                                                          {
                                                                                            staticClass:
                                                                                              "ma-1",
                                                                                            attrs: {
                                                                                              label: _vm.$t(
                                                                                                "campaignGenerator.Discovery"
                                                                                              ),
                                                                                              color:
                                                                                                "primarylight",
                                                                                              value: true,
                                                                                              "hide-details":
                                                                                                "",
                                                                                            },
                                                                                            model: {
                                                                                              value:
                                                                                                _vm.discoveryCheck,
                                                                                              callback: function (
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.discoveryCheck = $$v
                                                                                              },
                                                                                              expression:
                                                                                                "discoveryCheck",
                                                                                            },
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "d-flex",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "field",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-checkbox",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "ma-1",
                                                                                                attrs: {
                                                                                                  label: _vm.$t(
                                                                                                    "campaignGenerator.Competitor"
                                                                                                  ),
                                                                                                  color:
                                                                                                    "primarylight",
                                                                                                  "hide-details":
                                                                                                    "",
                                                                                                },
                                                                                                model: {
                                                                                                  value:
                                                                                                    _vm.competitorCheck,
                                                                                                  callback: function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.competitorCheck = $$v
                                                                                                  },
                                                                                                  expression:
                                                                                                    "competitorCheck",
                                                                                                },
                                                                                              }
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                        _vm.competitorCheck
                                                                                          ? _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "listfields",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "listfieldsInner",
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-row",
                                                                                                      {
                                                                                                        attrs: {
                                                                                                          align:
                                                                                                            "center",
                                                                                                        },
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-col",
                                                                                                          {
                                                                                                            attrs: {
                                                                                                              cols:
                                                                                                                "12",
                                                                                                              sm:
                                                                                                                "12",
                                                                                                              md:
                                                                                                                "12",
                                                                                                            },
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "titleHead black--text mb-3 d-flex justify-space-between",
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "div",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "lable-text black--text mb-0",
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      " " +
                                                                                                                        _vm._s(
                                                                                                                          _vm.$t(
                                                                                                                            "keyHeaders.organicRank"
                                                                                                                          )
                                                                                                                        ) +
                                                                                                                        " "
                                                                                                                    ),
                                                                                                                  ]
                                                                                                                ),
                                                                                                              ]
                                                                                                            ),
                                                                                                            _c(
                                                                                                              "v-row",
                                                                                                              {
                                                                                                                attrs: {
                                                                                                                  align:
                                                                                                                    "center",
                                                                                                                },
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "v-col",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "pb-0",
                                                                                                                    attrs: {
                                                                                                                      cols:
                                                                                                                        "12",
                                                                                                                      sm:
                                                                                                                        "12",
                                                                                                                      md:
                                                                                                                        "6",
                                                                                                                    },
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "v-select",
                                                                                                                      {
                                                                                                                        attrs: {
                                                                                                                          items:
                                                                                                                            _vm.criteriaItems,
                                                                                                                          rules: [
                                                                                                                            function (
                                                                                                                              v
                                                                                                                            ) {
                                                                                                                              return (
                                                                                                                                !!v ||
                                                                                                                                _vm.$t(
                                                                                                                                  "campaignGenerator.pleaseSelectCriteria"
                                                                                                                                )
                                                                                                                              )
                                                                                                                            },
                                                                                                                          ],
                                                                                                                          label: _vm.$t(
                                                                                                                            "campaignGenerator.Criteria"
                                                                                                                          ),
                                                                                                                          required:
                                                                                                                            "",
                                                                                                                          outlined:
                                                                                                                            "",
                                                                                                                          dense:
                                                                                                                            "",
                                                                                                                          attach:
                                                                                                                            "",
                                                                                                                          disabled: !_vm.organicRankSwitch,
                                                                                                                        },
                                                                                                                        model: {
                                                                                                                          value:
                                                                                                                            _vm.organicSelect,
                                                                                                                          callback: function (
                                                                                                                            $$v
                                                                                                                          ) {
                                                                                                                            _vm.organicSelect = $$v
                                                                                                                          },
                                                                                                                          expression:
                                                                                                                            "\n                                                              organicSelect\n                                                            ",
                                                                                                                        },
                                                                                                                      }
                                                                                                                    ),
                                                                                                                  ],
                                                                                                                  1
                                                                                                                ),
                                                                                                                _c(
                                                                                                                  "v-col",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "pb-0",
                                                                                                                    attrs: {
                                                                                                                      cols:
                                                                                                                        "12",
                                                                                                                      sm:
                                                                                                                        "12",
                                                                                                                      md:
                                                                                                                        "6",
                                                                                                                    },
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "div",
                                                                                                                      {
                                                                                                                        staticClass:
                                                                                                                          "field",
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _vm.organicSelect !=
                                                                                                                        "IN_RANGE"
                                                                                                                          ? _c(
                                                                                                                              "v-text-field",
                                                                                                                              {
                                                                                                                                attrs: {
                                                                                                                                  label: _vm.$t(
                                                                                                                                    "campaignGenerator.Value"
                                                                                                                                  ),
                                                                                                                                  required:
                                                                                                                                    "",
                                                                                                                                  outlined:
                                                                                                                                    "",
                                                                                                                                  dense:
                                                                                                                                    "",
                                                                                                                                  rules: [
                                                                                                                                    function (
                                                                                                                                      v
                                                                                                                                    ) {
                                                                                                                                      return (
                                                                                                                                        !!v ||
                                                                                                                                        _vm.$t(
                                                                                                                                          "campaignGenerator.pleaseEnterValue"
                                                                                                                                        )
                                                                                                                                      )
                                                                                                                                    },
                                                                                                                                  ],
                                                                                                                                  disabled: !_vm.organicRankSwitch,
                                                                                                                                },
                                                                                                                                model: {
                                                                                                                                  value:
                                                                                                                                    _vm.organicValue,
                                                                                                                                  callback: function (
                                                                                                                                    $$v
                                                                                                                                  ) {
                                                                                                                                    _vm.organicValue = $$v
                                                                                                                                  },
                                                                                                                                  expression:
                                                                                                                                    "\n                                                                organicValue\n                                                              ",
                                                                                                                                },
                                                                                                                              }
                                                                                                                            )
                                                                                                                          : _vm._e(),
                                                                                                                        _vm.organicSelect ==
                                                                                                                        "IN_RANGE"
                                                                                                                          ? _c(
                                                                                                                              "v-text-field",
                                                                                                                              {
                                                                                                                                attrs: {
                                                                                                                                  label: _vm.$t(
                                                                                                                                    "campaignGenerator.From"
                                                                                                                                  ),
                                                                                                                                  required:
                                                                                                                                    "",
                                                                                                                                  outlined:
                                                                                                                                    "",
                                                                                                                                  dense:
                                                                                                                                    "",
                                                                                                                                  disabled: !_vm.organicRankSwitch,
                                                                                                                                  rules: [
                                                                                                                                    function (
                                                                                                                                      v
                                                                                                                                    ) {
                                                                                                                                      return (
                                                                                                                                        !!v ||
                                                                                                                                        _vm.$t(
                                                                                                                                          "campaignGenerator.pleaseEnterValue"
                                                                                                                                        )
                                                                                                                                      )
                                                                                                                                    },
                                                                                                                                  ],
                                                                                                                                },
                                                                                                                                model: {
                                                                                                                                  value:
                                                                                                                                    _vm.organicFrom,
                                                                                                                                  callback: function (
                                                                                                                                    $$v
                                                                                                                                  ) {
                                                                                                                                    _vm.organicFrom = $$v
                                                                                                                                  },
                                                                                                                                  expression:
                                                                                                                                    "\n                                                                organicFrom\n                                                              ",
                                                                                                                                },
                                                                                                                              }
                                                                                                                            )
                                                                                                                          : _vm._e(),
                                                                                                                        _vm.organicSelect ==
                                                                                                                        "IN_RANGE"
                                                                                                                          ? _c(
                                                                                                                              "v-text-field",
                                                                                                                              {
                                                                                                                                attrs: {
                                                                                                                                  label: _vm.$t(
                                                                                                                                    "campaignGenerator.To"
                                                                                                                                  ),
                                                                                                                                  required:
                                                                                                                                    "",
                                                                                                                                  outlined:
                                                                                                                                    "",
                                                                                                                                  dense:
                                                                                                                                    "",
                                                                                                                                  disabled:
                                                                                                                                    !_vm.organicRankSwitch ||
                                                                                                                                    !_vm.organicFrom,
                                                                                                                                  rules: [
                                                                                                                                    function (
                                                                                                                                      v
                                                                                                                                    ) {
                                                                                                                                      return (
                                                                                                                                        !!(
                                                                                                                                          v >
                                                                                                                                          parseInt(
                                                                                                                                            _vm.organicFrom
                                                                                                                                          ) +
                                                                                                                                            10
                                                                                                                                        ) ||
                                                                                                                                        _vm.$t(
                                                                                                                                          "campaignGenerator.FromTo10"
                                                                                                                                        )
                                                                                                                                      )
                                                                                                                                    },
                                                                                                                                  ],
                                                                                                                                },
                                                                                                                                model: {
                                                                                                                                  value:
                                                                                                                                    _vm.organicTo,
                                                                                                                                  callback: function (
                                                                                                                                    $$v
                                                                                                                                  ) {
                                                                                                                                    _vm.organicTo = $$v
                                                                                                                                  },
                                                                                                                                  expression:
                                                                                                                                    "\n                                                                organicTo\n                                                              ",
                                                                                                                                },
                                                                                                                              }
                                                                                                                            )
                                                                                                                          : _vm._e(),
                                                                                                                      ],
                                                                                                                      1
                                                                                                                    ),
                                                                                                                  ]
                                                                                                                ),
                                                                                                              ],
                                                                                                              1
                                                                                                            ),
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                  ],
                                                                                                  1
                                                                                                ),
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "listfieldsInner",
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-row",
                                                                                                      {
                                                                                                        attrs: {
                                                                                                          align:
                                                                                                            "center",
                                                                                                        },
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-col",
                                                                                                          {
                                                                                                            attrs: {
                                                                                                              cols:
                                                                                                                "12",
                                                                                                              sm:
                                                                                                                "12",
                                                                                                              md:
                                                                                                                "12",
                                                                                                            },
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "titleHead black--text mb-3 d-flex justify-space-between",
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "div",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "lable-text mb-0",
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      " " +
                                                                                                                        _vm._s(
                                                                                                                          _vm.$t(
                                                                                                                            "keyHeaders.popularity"
                                                                                                                          )
                                                                                                                        ) +
                                                                                                                        " "
                                                                                                                    ),
                                                                                                                  ]
                                                                                                                ),
                                                                                                              ]
                                                                                                            ),
                                                                                                            _c(
                                                                                                              "v-row",
                                                                                                              {
                                                                                                                attrs: {
                                                                                                                  align:
                                                                                                                    "center",
                                                                                                                },
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "v-col",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "pb-0",
                                                                                                                    attrs: {
                                                                                                                      cols:
                                                                                                                        "12",
                                                                                                                      sm:
                                                                                                                        "12",
                                                                                                                      md:
                                                                                                                        "6",
                                                                                                                    },
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "v-select",
                                                                                                                      {
                                                                                                                        attrs: {
                                                                                                                          items:
                                                                                                                            _vm.criteriaItems,
                                                                                                                          rules: [
                                                                                                                            function (
                                                                                                                              v
                                                                                                                            ) {
                                                                                                                              return (
                                                                                                                                !!v ||
                                                                                                                                _vm.$t(
                                                                                                                                  "campaignGenerator.pleaseSelectCriteria"
                                                                                                                                )
                                                                                                                              )
                                                                                                                            },
                                                                                                                          ],
                                                                                                                          label: _vm.$t(
                                                                                                                            "campaignGenerator.Criteria"
                                                                                                                          ),
                                                                                                                          required:
                                                                                                                            "",
                                                                                                                          outlined:
                                                                                                                            "",
                                                                                                                          dense:
                                                                                                                            "",
                                                                                                                          attach:
                                                                                                                            "",
                                                                                                                          disabled: !_vm.popularitySwitch,
                                                                                                                        },
                                                                                                                        model: {
                                                                                                                          value:
                                                                                                                            _vm.popularitySelect,
                                                                                                                          callback: function (
                                                                                                                            $$v
                                                                                                                          ) {
                                                                                                                            _vm.popularitySelect = $$v
                                                                                                                          },
                                                                                                                          expression:
                                                                                                                            "\n                                                              popularitySelect\n                                                            ",
                                                                                                                        },
                                                                                                                      }
                                                                                                                    ),
                                                                                                                  ],
                                                                                                                  1
                                                                                                                ),
                                                                                                                _c(
                                                                                                                  "v-col",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "pb-0",
                                                                                                                    attrs: {
                                                                                                                      cols:
                                                                                                                        "12",
                                                                                                                      sm:
                                                                                                                        "12",
                                                                                                                      md:
                                                                                                                        "6",
                                                                                                                    },
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "div",
                                                                                                                      {
                                                                                                                        staticClass:
                                                                                                                          "field",
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _vm.popularitySelect !=
                                                                                                                        "IN_RANGE"
                                                                                                                          ? _c(
                                                                                                                              "v-text-field",
                                                                                                                              {
                                                                                                                                attrs: {
                                                                                                                                  label: _vm.$t(
                                                                                                                                    "campaignGenerator.Value"
                                                                                                                                  ),
                                                                                                                                  rules: [
                                                                                                                                    function (
                                                                                                                                      v
                                                                                                                                    ) {
                                                                                                                                      return (
                                                                                                                                        !!v ||
                                                                                                                                        _vm.$t(
                                                                                                                                          "campaignGenerator.pleaseEnterValue"
                                                                                                                                        )
                                                                                                                                      )
                                                                                                                                    },
                                                                                                                                  ],
                                                                                                                                  required:
                                                                                                                                    "",
                                                                                                                                  outlined:
                                                                                                                                    "",
                                                                                                                                  dense:
                                                                                                                                    "",
                                                                                                                                  disabled: !_vm.popularitySwitch,
                                                                                                                                },
                                                                                                                                model: {
                                                                                                                                  value:
                                                                                                                                    _vm.popularityValue,
                                                                                                                                  callback: function (
                                                                                                                                    $$v
                                                                                                                                  ) {
                                                                                                                                    _vm.popularityValue = $$v
                                                                                                                                  },
                                                                                                                                  expression:
                                                                                                                                    "\n                                                                popularityValue\n                                                              ",
                                                                                                                                },
                                                                                                                              }
                                                                                                                            )
                                                                                                                          : _vm._e(),
                                                                                                                        _vm.popularitySelect ==
                                                                                                                        "IN_RANGE"
                                                                                                                          ? _c(
                                                                                                                              "v-text-field",
                                                                                                                              {
                                                                                                                                attrs: {
                                                                                                                                  label: _vm.$t(
                                                                                                                                    "campaignGenerator.From"
                                                                                                                                  ),
                                                                                                                                  required:
                                                                                                                                    "",
                                                                                                                                  outlined:
                                                                                                                                    "",
                                                                                                                                  dense:
                                                                                                                                    "",
                                                                                                                                  disabled: !_vm.popularitySwitch,
                                                                                                                                  rules: [
                                                                                                                                    function (
                                                                                                                                      v
                                                                                                                                    ) {
                                                                                                                                      return (
                                                                                                                                        !!v ||
                                                                                                                                        _vm.$t(
                                                                                                                                          "campaignGenerator.pleaseEnterValue"
                                                                                                                                        )
                                                                                                                                      )
                                                                                                                                    },
                                                                                                                                  ],
                                                                                                                                },
                                                                                                                                model: {
                                                                                                                                  value:
                                                                                                                                    _vm.popularityFrom,
                                                                                                                                  callback: function (
                                                                                                                                    $$v
                                                                                                                                  ) {
                                                                                                                                    _vm.popularityFrom = $$v
                                                                                                                                  },
                                                                                                                                  expression:
                                                                                                                                    "\n                                                                popularityFrom\n                                                              ",
                                                                                                                                },
                                                                                                                              }
                                                                                                                            )
                                                                                                                          : _vm._e(),
                                                                                                                        _vm.popularitySelect ==
                                                                                                                        "IN_RANGE"
                                                                                                                          ? _c(
                                                                                                                              "v-text-field",
                                                                                                                              {
                                                                                                                                attrs: {
                                                                                                                                  label: _vm.$t(
                                                                                                                                    "campaignGenerator.To"
                                                                                                                                  ),
                                                                                                                                  required:
                                                                                                                                    "",
                                                                                                                                  outlined:
                                                                                                                                    "",
                                                                                                                                  dense:
                                                                                                                                    "",
                                                                                                                                  disabled:
                                                                                                                                    !_vm.popularitySwitch ||
                                                                                                                                    !_vm.popularityFrom,
                                                                                                                                  rules: [
                                                                                                                                    function (
                                                                                                                                      v
                                                                                                                                    ) {
                                                                                                                                      return (
                                                                                                                                        !!(
                                                                                                                                          v >
                                                                                                                                          parseInt(
                                                                                                                                            _vm.popularityFrom
                                                                                                                                          ) +
                                                                                                                                            10
                                                                                                                                        ) ||
                                                                                                                                        _vm.$t(
                                                                                                                                          "campaignGenerator.FromTo10"
                                                                                                                                        )
                                                                                                                                      )
                                                                                                                                    },
                                                                                                                                  ],
                                                                                                                                },
                                                                                                                                model: {
                                                                                                                                  value:
                                                                                                                                    _vm.popularityTo,
                                                                                                                                  callback: function (
                                                                                                                                    $$v
                                                                                                                                  ) {
                                                                                                                                    _vm.popularityTo = $$v
                                                                                                                                  },
                                                                                                                                  expression:
                                                                                                                                    "\n                                                                popularityTo\n                                                              ",
                                                                                                                                },
                                                                                                                              }
                                                                                                                            )
                                                                                                                          : _vm._e(),
                                                                                                                      ],
                                                                                                                      1
                                                                                                                    ),
                                                                                                                  ]
                                                                                                                ),
                                                                                                              ],
                                                                                                              1
                                                                                                            ),
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                  ],
                                                                                                  1
                                                                                                ),
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "listfieldsInner",
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-row",
                                                                                                      {
                                                                                                        attrs: {
                                                                                                          align:
                                                                                                            "center",
                                                                                                        },
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-col",
                                                                                                          {
                                                                                                            attrs: {
                                                                                                              cols:
                                                                                                                "12",
                                                                                                              sm:
                                                                                                                "12",
                                                                                                              md:
                                                                                                                "12",
                                                                                                            },
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "titleHead black--text mb-3 d-flex justify-space-between",
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "div",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "lable-text mb-0",
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      " " +
                                                                                                                        _vm._s(
                                                                                                                          _vm.$t(
                                                                                                                            "kai.totalApps"
                                                                                                                          )
                                                                                                                        ) +
                                                                                                                        " "
                                                                                                                    ),
                                                                                                                  ]
                                                                                                                ),
                                                                                                              ]
                                                                                                            ),
                                                                                                            _c(
                                                                                                              "v-row",
                                                                                                              {
                                                                                                                attrs: {
                                                                                                                  align:
                                                                                                                    "center",
                                                                                                                },
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "v-col",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "pb-0",
                                                                                                                    attrs: {
                                                                                                                      cols:
                                                                                                                        "12",
                                                                                                                      sm:
                                                                                                                        "12",
                                                                                                                      md:
                                                                                                                        "6",
                                                                                                                    },
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "v-select",
                                                                                                                      {
                                                                                                                        attrs: {
                                                                                                                          items:
                                                                                                                            _vm.criteriaItems,
                                                                                                                          rules: [
                                                                                                                            function (
                                                                                                                              v
                                                                                                                            ) {
                                                                                                                              return (
                                                                                                                                !!v ||
                                                                                                                                _vm.$t(
                                                                                                                                  "campaignGenerator.pleaseSelectCriteria"
                                                                                                                                )
                                                                                                                              )
                                                                                                                            },
                                                                                                                          ],
                                                                                                                          label: _vm.$t(
                                                                                                                            "campaignGenerator.Criteria"
                                                                                                                          ),
                                                                                                                          required:
                                                                                                                            "",
                                                                                                                          outlined:
                                                                                                                            "",
                                                                                                                          attach:
                                                                                                                            "",
                                                                                                                          dense:
                                                                                                                            "",
                                                                                                                          disabled: !_vm.totalAppsSwitch,
                                                                                                                        },
                                                                                                                        model: {
                                                                                                                          value:
                                                                                                                            _vm.totalAppsSelect,
                                                                                                                          callback: function (
                                                                                                                            $$v
                                                                                                                          ) {
                                                                                                                            _vm.totalAppsSelect = $$v
                                                                                                                          },
                                                                                                                          expression:
                                                                                                                            "\n                                                              totalAppsSelect\n                                                            ",
                                                                                                                        },
                                                                                                                      }
                                                                                                                    ),
                                                                                                                  ],
                                                                                                                  1
                                                                                                                ),
                                                                                                                _c(
                                                                                                                  "v-col",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "pb-0",
                                                                                                                    attrs: {
                                                                                                                      cols:
                                                                                                                        "12",
                                                                                                                      sm:
                                                                                                                        "12",
                                                                                                                      md:
                                                                                                                        "6",
                                                                                                                    },
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "div",
                                                                                                                      {
                                                                                                                        staticClass:
                                                                                                                          "field",
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _vm.totalAppsSelect !=
                                                                                                                        "IN_RANGE"
                                                                                                                          ? _c(
                                                                                                                              "v-text-field",
                                                                                                                              {
                                                                                                                                attrs: {
                                                                                                                                  label: _vm.$t(
                                                                                                                                    "campaignGenerator.Value"
                                                                                                                                  ),
                                                                                                                                  required:
                                                                                                                                    "",
                                                                                                                                  outlined:
                                                                                                                                    "",
                                                                                                                                  dense:
                                                                                                                                    "",
                                                                                                                                  rules: [
                                                                                                                                    function (
                                                                                                                                      v
                                                                                                                                    ) {
                                                                                                                                      return (
                                                                                                                                        !!v ||
                                                                                                                                        _vm.$t(
                                                                                                                                          "campaignGenerator.pleaseEnterValue"
                                                                                                                                        )
                                                                                                                                      )
                                                                                                                                    },
                                                                                                                                  ],
                                                                                                                                  disabled: !_vm.totalAppsSwitch,
                                                                                                                                },
                                                                                                                                model: {
                                                                                                                                  value:
                                                                                                                                    _vm.totalAppsValue,
                                                                                                                                  callback: function (
                                                                                                                                    $$v
                                                                                                                                  ) {
                                                                                                                                    _vm.totalAppsValue = $$v
                                                                                                                                  },
                                                                                                                                  expression:
                                                                                                                                    "\n                                                                totalAppsValue\n                                                              ",
                                                                                                                                },
                                                                                                                              }
                                                                                                                            )
                                                                                                                          : _vm._e(),
                                                                                                                        _vm.totalAppsSelect ==
                                                                                                                        "IN_RANGE"
                                                                                                                          ? _c(
                                                                                                                              "v-text-field",
                                                                                                                              {
                                                                                                                                attrs: {
                                                                                                                                  label: _vm.$t(
                                                                                                                                    "campaignGenerator.From"
                                                                                                                                  ),
                                                                                                                                  required:
                                                                                                                                    "",
                                                                                                                                  outlined:
                                                                                                                                    "",
                                                                                                                                  dense:
                                                                                                                                    "",
                                                                                                                                  disabled: !_vm.totalAppsSwitch,
                                                                                                                                  rules: [
                                                                                                                                    function (
                                                                                                                                      v
                                                                                                                                    ) {
                                                                                                                                      return (
                                                                                                                                        !!v ||
                                                                                                                                        _vm.$t(
                                                                                                                                          "campaignGenerator.pleaseEnterValue"
                                                                                                                                        )
                                                                                                                                      )
                                                                                                                                    },
                                                                                                                                  ],
                                                                                                                                },
                                                                                                                                model: {
                                                                                                                                  value:
                                                                                                                                    _vm.totalAppsFrom,
                                                                                                                                  callback: function (
                                                                                                                                    $$v
                                                                                                                                  ) {
                                                                                                                                    _vm.totalAppsFrom = $$v
                                                                                                                                  },
                                                                                                                                  expression:
                                                                                                                                    "\n                                                                totalAppsFrom\n                                                              ",
                                                                                                                                },
                                                                                                                              }
                                                                                                                            )
                                                                                                                          : _vm._e(),
                                                                                                                        _vm.totalAppsSelect ==
                                                                                                                        "IN_RANGE"
                                                                                                                          ? _c(
                                                                                                                              "v-text-field",
                                                                                                                              {
                                                                                                                                attrs: {
                                                                                                                                  label: _vm.$t(
                                                                                                                                    "campaignGenerator.To"
                                                                                                                                  ),
                                                                                                                                  required:
                                                                                                                                    "",
                                                                                                                                  outlined:
                                                                                                                                    "",
                                                                                                                                  dense:
                                                                                                                                    "",
                                                                                                                                  disabled:
                                                                                                                                    !_vm.totalAppsSwitch ||
                                                                                                                                    !_vm.totalAppsFrom,
                                                                                                                                  rules: [
                                                                                                                                    function (
                                                                                                                                      v
                                                                                                                                    ) {
                                                                                                                                      return (
                                                                                                                                        !!(
                                                                                                                                          v >
                                                                                                                                          parseInt(
                                                                                                                                            _vm.totalAppsFrom
                                                                                                                                          ) +
                                                                                                                                            10
                                                                                                                                        ) ||
                                                                                                                                        _vm.$t(
                                                                                                                                          "campaignGenerator.FromTo10"
                                                                                                                                        )
                                                                                                                                      )
                                                                                                                                    },
                                                                                                                                  ],
                                                                                                                                },
                                                                                                                                model: {
                                                                                                                                  value:
                                                                                                                                    _vm.totalAppsTo,
                                                                                                                                  callback: function (
                                                                                                                                    $$v
                                                                                                                                  ) {
                                                                                                                                    _vm.totalAppsTo = $$v
                                                                                                                                  },
                                                                                                                                  expression:
                                                                                                                                    "\n                                                                totalAppsTo\n                                                              ",
                                                                                                                                },
                                                                                                                              }
                                                                                                                            )
                                                                                                                          : _vm._e(),
                                                                                                                      ],
                                                                                                                      1
                                                                                                                    ),
                                                                                                                  ]
                                                                                                                ),
                                                                                                              ],
                                                                                                              1
                                                                                                            ),
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                  ],
                                                                                                  1
                                                                                                ),
                                                                                              ]
                                                                                            )
                                                                                          : _vm._e(),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "d-flex",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "field custom-field",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-checkbox",
                                                                                              {
                                                                                                attrs: {
                                                                                                  label: _vm.$t(
                                                                                                    "campaignGenerator.Custom"
                                                                                                  ),
                                                                                                  color:
                                                                                                    "primarylight",
                                                                                                  value: true,
                                                                                                  "hide-details":
                                                                                                    "",
                                                                                                },
                                                                                                model: {
                                                                                                  value:
                                                                                                    _vm.customCheck,
                                                                                                  callback: function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.customCheck = $$v
                                                                                                  },
                                                                                                  expression:
                                                                                                    "customCheck",
                                                                                                },
                                                                                              }
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                        _vm.customCheck
                                                                                          ? _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "listfields px-6 py-3",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "listfieldsInner",
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-row",
                                                                                                      {
                                                                                                        attrs: {
                                                                                                          align:
                                                                                                            "center",
                                                                                                        },
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-col",
                                                                                                          {
                                                                                                            attrs: {
                                                                                                              cols:
                                                                                                                "12",
                                                                                                              sm:
                                                                                                                "12",
                                                                                                              md:
                                                                                                                "12",
                                                                                                            },
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "titleHead mb-3 d-flex justify-space-between",
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "div",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "lable-text black--text mb-0",
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      " " +
                                                                                                                        _vm._s(
                                                                                                                          _vm.$t(
                                                                                                                            "campaignGenerator.customSlugHeading"
                                                                                                                          )
                                                                                                                        ) +
                                                                                                                        " "
                                                                                                                    ),
                                                                                                                  ]
                                                                                                                ),
                                                                                                              ]
                                                                                                            ),
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "d-flex flex-column",
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "div",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "field d-flex align-start cursor-pointer categories-input",
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "v-text-field",
                                                                                                                      {
                                                                                                                        attrs: {
                                                                                                                          placeholder: _vm.$t(
                                                                                                                            "campaignGenerator.customSlugPH"
                                                                                                                          ),
                                                                                                                          required:
                                                                                                                            "",
                                                                                                                          outlined:
                                                                                                                            "",
                                                                                                                          dense:
                                                                                                                            "",
                                                                                                                          rules: [
                                                                                                                            function (
                                                                                                                              v
                                                                                                                            ) {
                                                                                                                              return (
                                                                                                                                !_vm.customSlugs.find(
                                                                                                                                  function (
                                                                                                                                    kw
                                                                                                                                  ) {
                                                                                                                                    return (
                                                                                                                                      kw ===
                                                                                                                                      v
                                                                                                                                    )
                                                                                                                                  }
                                                                                                                                ) ||
                                                                                                                                _vm.$t(
                                                                                                                                  "campaignGenerator.customSlugDup"
                                                                                                                                )
                                                                                                                              )
                                                                                                                            },
                                                                                                                          ],
                                                                                                                          "max-width":
                                                                                                                            "300px",
                                                                                                                        },
                                                                                                                        model: {
                                                                                                                          value:
                                                                                                                            _vm.customCampaignSlug,
                                                                                                                          callback: function (
                                                                                                                            $$v
                                                                                                                          ) {
                                                                                                                            _vm.customCampaignSlug = $$v
                                                                                                                          },
                                                                                                                          expression:
                                                                                                                            "\n                                                              customCampaignSlug\n                                                            ",
                                                                                                                        },
                                                                                                                      }
                                                                                                                    ),
                                                                                                                    _c(
                                                                                                                      "v-btn",
                                                                                                                      {
                                                                                                                        staticClass:
                                                                                                                          "ml-3 primary--text d-flex align-center",
                                                                                                                        attrs: {
                                                                                                                          outline:
                                                                                                                            "",
                                                                                                                          primary:
                                                                                                                            "",
                                                                                                                          text:
                                                                                                                            "",
                                                                                                                        },
                                                                                                                        on: {
                                                                                                                          click: function (
                                                                                                                            $event
                                                                                                                          ) {
                                                                                                                            return _vm.addCustomSlug()
                                                                                                                          },
                                                                                                                        },
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _c(
                                                                                                                          "v-icon",
                                                                                                                          {
                                                                                                                            staticClass:
                                                                                                                              "primary--text",
                                                                                                                          },
                                                                                                                          [
                                                                                                                            _vm._v(
                                                                                                                              "mdi-plus"
                                                                                                                            ),
                                                                                                                          ]
                                                                                                                        ),
                                                                                                                        _vm._v(
                                                                                                                          " " +
                                                                                                                            _vm._s(
                                                                                                                              _vm.$t(
                                                                                                                                "campaignGenerator.customSlugAdd"
                                                                                                                              )
                                                                                                                            ) +
                                                                                                                            " "
                                                                                                                        ),
                                                                                                                      ],
                                                                                                                      1
                                                                                                                    ),
                                                                                                                  ],
                                                                                                                  1
                                                                                                                ),
                                                                                                                _c(
                                                                                                                  "div",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "titleHead black--text mb-3 d-flex justify-space-between",
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "v-list",
                                                                                                                      {
                                                                                                                        staticClass:
                                                                                                                          "pa-0",
                                                                                                                        attrs: {
                                                                                                                          dense:
                                                                                                                            "",
                                                                                                                        },
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _c(
                                                                                                                          "v-list-item-group",
                                                                                                                          {
                                                                                                                            staticClass:
                                                                                                                              "d-flex",
                                                                                                                            attrs: {
                                                                                                                              color:
                                                                                                                                "primary",
                                                                                                                            },
                                                                                                                          },
                                                                                                                          _vm._l(
                                                                                                                            _vm.customSlugs,
                                                                                                                            function (
                                                                                                                              campaign
                                                                                                                            ) {
                                                                                                                              return _c(
                                                                                                                                "div",
                                                                                                                                {
                                                                                                                                  key: campaign,
                                                                                                                                  staticClass:
                                                                                                                                    "pa-0 pr-2 hover-disable",
                                                                                                                                },
                                                                                                                                [
                                                                                                                                  _c(
                                                                                                                                    "div",
                                                                                                                                    {
                                                                                                                                      staticClass:
                                                                                                                                        "d-flex flex-row align-center custom-chips font-14",
                                                                                                                                    },
                                                                                                                                    [
                                                                                                                                      _c(
                                                                                                                                        "span",
                                                                                                                                        {
                                                                                                                                          staticClass:
                                                                                                                                            "black--text",
                                                                                                                                          domProps: {
                                                                                                                                            textContent: _vm._s(
                                                                                                                                              campaign
                                                                                                                                            ),
                                                                                                                                          },
                                                                                                                                        }
                                                                                                                                      ),
                                                                                                                                      _c(
                                                                                                                                        "v-icon",
                                                                                                                                        {
                                                                                                                                          staticClass:
                                                                                                                                            "primary--text font-18 ml-1",
                                                                                                                                          on: {
                                                                                                                                            click: function (
                                                                                                                                              $event
                                                                                                                                            ) {
                                                                                                                                              return _vm.deleteCustomSlug(
                                                                                                                                                campaign
                                                                                                                                              )
                                                                                                                                            },
                                                                                                                                          },
                                                                                                                                        },
                                                                                                                                        [
                                                                                                                                          _vm._v(
                                                                                                                                            "mdi-close"
                                                                                                                                          ),
                                                                                                                                        ]
                                                                                                                                      ),
                                                                                                                                    ],
                                                                                                                                    1
                                                                                                                                  ),
                                                                                                                                ]
                                                                                                                              )
                                                                                                                            }
                                                                                                                          ),
                                                                                                                          0
                                                                                                                        ),
                                                                                                                      ],
                                                                                                                      1
                                                                                                                    ),
                                                                                                                  ],
                                                                                                                  1
                                                                                                                ),
                                                                                                                _c(
                                                                                                                  "v-row",
                                                                                                                  {
                                                                                                                    attrs: {
                                                                                                                      align:
                                                                                                                        "center",
                                                                                                                    },
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "v-col",
                                                                                                                      {
                                                                                                                        staticClass:
                                                                                                                          "pb-0",
                                                                                                                        attrs: {
                                                                                                                          cols:
                                                                                                                            "12",
                                                                                                                          sm:
                                                                                                                            "12",
                                                                                                                          md:
                                                                                                                            "12",
                                                                                                                        },
                                                                                                                      }
                                                                                                                    ),
                                                                                                                  ],
                                                                                                                  1
                                                                                                                ),
                                                                                                              ],
                                                                                                              1
                                                                                                            ),
                                                                                                          ]
                                                                                                        ),
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                  ],
                                                                                                  1
                                                                                                ),
                                                                                              ]
                                                                                            )
                                                                                          : _vm._e(),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          " text-right sticky-bottom ",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "mr-4 px-6",
                                                                            attrs: {
                                                                              color:
                                                                                "primary",
                                                                              outlined:
                                                                                "",
                                                                              large:
                                                                                "",
                                                                            },
                                                                            on: {
                                                                              click: function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.draftTemplate()
                                                                              },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "campaignGenerator.saveAsDraft"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "mr-4 px-6",
                                                                            attrs: {
                                                                              color:
                                                                                "primary",
                                                                              outlined:
                                                                                "",
                                                                              large:
                                                                                "",
                                                                            },
                                                                            on: {
                                                                              click:
                                                                                _vm.goBack,
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Back "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "px-6",
                                                                            attrs: {
                                                                              color:
                                                                                "primary",
                                                                              depressed:
                                                                                "",
                                                                              large:
                                                                                "",
                                                                              disabled:
                                                                                (!_vm.brandCheck &&
                                                                                  !_vm.categoryCheck &&
                                                                                  !_vm.discoveryCheck &&
                                                                                  !_vm.competitorCheck &&
                                                                                  (!_vm.customCheck ||
                                                                                    _vm
                                                                                      .customSlugs
                                                                                      .length ==
                                                                                      0)) ||
                                                                                !_vm.valid2,
                                                                            },
                                                                            on: {
                                                                              click: function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.categorySelected()
                                                                              },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "createCampaign.next"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-stepper-content",
                                                                  {
                                                                    staticClass:
                                                                      "pa-0",
                                                                    attrs: {
                                                                      step: "3",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-card",
                                                                      {
                                                                        staticClass:
                                                                          " px-0 py-3",
                                                                        attrs: {
                                                                          elevation:
                                                                            "0",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-form",
                                                                          {
                                                                            ref:
                                                                              "campgen3",
                                                                            model: {
                                                                              value:
                                                                                _vm.valid3,
                                                                              callback: function (
                                                                                $$v
                                                                              ) {
                                                                                _vm.valid3 = $$v
                                                                              },
                                                                              expression:
                                                                                "valid3",
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "segmentation-rules pt-0",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-row",
                                                                                  [
                                                                                    _c(
                                                                                      "v-col",
                                                                                      {
                                                                                        staticClass:
                                                                                          "py-2",
                                                                                        attrs: {
                                                                                          lg:
                                                                                            "12",
                                                                                          md:
                                                                                            "12",
                                                                                          sm:
                                                                                            "12",
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "field",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "label",
                                                                                              {
                                                                                                staticClass:
                                                                                                  " mb-1 d-flex",
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    _vm.$t(
                                                                                                      "campaignGenerator.age"
                                                                                                    )
                                                                                                  )
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "v-select",
                                                                                              {
                                                                                                attrs: {
                                                                                                  items:
                                                                                                    _vm.ageItems,
                                                                                                  placeholder: _vm.$t(
                                                                                                    "createCampaign.ageRange"
                                                                                                  ),
                                                                                                  required:
                                                                                                    "",
                                                                                                  dense:
                                                                                                    "",
                                                                                                  attach:
                                                                                                    "",
                                                                                                  multiple:
                                                                                                    "",
                                                                                                  "small-chips":
                                                                                                    "",
                                                                                                  "deletable-chips":
                                                                                                    "",
                                                                                                  rules: [
                                                                                                    function (
                                                                                                      v
                                                                                                    ) {
                                                                                                      return (
                                                                                                        !!(
                                                                                                          v &&
                                                                                                          v.length
                                                                                                        ) ||
                                                                                                        _vm.$t(
                                                                                                          "campaignGenerator.selectAge"
                                                                                                        )
                                                                                                      )
                                                                                                    },
                                                                                                  ],
                                                                                                },
                                                                                                model: {
                                                                                                  value:
                                                                                                    _vm.selectedAge,
                                                                                                  callback: function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.selectedAge = $$v
                                                                                                  },
                                                                                                  expression:
                                                                                                    "selectedAge",
                                                                                                },
                                                                                              }
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "v-col",
                                                                                      {
                                                                                        staticClass:
                                                                                          "py-2",
                                                                                        attrs: {
                                                                                          lg:
                                                                                            "12",
                                                                                          md:
                                                                                            "12",
                                                                                          sm:
                                                                                            "12",
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "field",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "label",
                                                                                              {
                                                                                                staticClass:
                                                                                                  " mb-1 d-flex",
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    _vm.$t(
                                                                                                      "campaignGenerator.geo"
                                                                                                    )
                                                                                                  )
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "v-autocomplete",
                                                                                              {
                                                                                                attrs: {
                                                                                                  clearable:
                                                                                                    "",
                                                                                                  placeholder: _vm.$t(
                                                                                                    "createCampaign.countries"
                                                                                                  ),
                                                                                                  attach:
                                                                                                    "",
                                                                                                  dense:
                                                                                                    "",
                                                                                                  chips:
                                                                                                    "",
                                                                                                  "small-chips":
                                                                                                    "",
                                                                                                  items:
                                                                                                    _vm.appCountries,
                                                                                                  "item-text":
                                                                                                    "Name",
                                                                                                  "item-value":
                                                                                                    "Code",
                                                                                                  rules: [
                                                                                                    function (
                                                                                                      v
                                                                                                    ) {
                                                                                                      return (
                                                                                                        !!(
                                                                                                          v &&
                                                                                                          v.length
                                                                                                        ) ||
                                                                                                        _vm.$t(
                                                                                                          "createCampaignRules.selectCountry"
                                                                                                        )
                                                                                                      )
                                                                                                    },
                                                                                                  ],
                                                                                                },
                                                                                                scopedSlots: _vm._u(
                                                                                                  [
                                                                                                    {
                                                                                                      key:
                                                                                                        "item",
                                                                                                      fn: function (
                                                                                                        data
                                                                                                      ) {
                                                                                                        return [
                                                                                                          [
                                                                                                            _c(
                                                                                                              "country-flag",
                                                                                                              {
                                                                                                                attrs: {
                                                                                                                  country: data.item.Code.toLowerCase(),
                                                                                                                },
                                                                                                              }
                                                                                                            ),
                                                                                                            _c(
                                                                                                              "v-list-item-content",
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "v-list-item-title",
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      _vm._s(
                                                                                                                        data
                                                                                                                          .item
                                                                                                                          .Name
                                                                                                                      )
                                                                                                                    ),
                                                                                                                  ]
                                                                                                                ),
                                                                                                              ],
                                                                                                              1
                                                                                                            ),
                                                                                                          ],
                                                                                                        ]
                                                                                                      },
                                                                                                    },
                                                                                                  ]
                                                                                                ),
                                                                                                model: {
                                                                                                  value:
                                                                                                    _vm.selectedCountries,
                                                                                                  callback: function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.selectedCountries = $$v
                                                                                                  },
                                                                                                  expression:
                                                                                                    "selectedCountries",
                                                                                                },
                                                                                              }
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "v-col",
                                                                                      {
                                                                                        staticClass:
                                                                                          "py-2",
                                                                                        attrs: {
                                                                                          lg:
                                                                                            "12",
                                                                                          md:
                                                                                            "12",
                                                                                          sm:
                                                                                            "12",
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "field",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "label",
                                                                                              {
                                                                                                staticClass:
                                                                                                  " mb-1 d-flex",
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    _vm.$t(
                                                                                                      "overview.gender"
                                                                                                    )
                                                                                                  )
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "v-select",
                                                                                              {
                                                                                                attrs: {
                                                                                                  items:
                                                                                                    _vm.gender,
                                                                                                  placeholder: _vm.$t(
                                                                                                    "overview.gender"
                                                                                                  ),
                                                                                                  required:
                                                                                                    "",
                                                                                                  dense:
                                                                                                    "",
                                                                                                  attach:
                                                                                                    "",
                                                                                                  multiple:
                                                                                                    "",
                                                                                                  "small-chips":
                                                                                                    "",
                                                                                                  "deletable-chips":
                                                                                                    "",
                                                                                                  rules: [
                                                                                                    function (
                                                                                                      v
                                                                                                    ) {
                                                                                                      return (
                                                                                                        !!(
                                                                                                          v &&
                                                                                                          v.length
                                                                                                        ) ||
                                                                                                        _vm.$t(
                                                                                                          "campaignGenerator.selectGender"
                                                                                                        )
                                                                                                      )
                                                                                                    },
                                                                                                  ],
                                                                                                },
                                                                                                model: {
                                                                                                  value:
                                                                                                    _vm.selectedGender,
                                                                                                  callback: function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.selectedGender = $$v
                                                                                                  },
                                                                                                  expression:
                                                                                                    "selectedGender",
                                                                                                },
                                                                                              }
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "v-col",
                                                                                      {
                                                                                        staticClass:
                                                                                          "py-2",
                                                                                        attrs: {
                                                                                          lg:
                                                                                            "12",
                                                                                          md:
                                                                                            "12",
                                                                                          sm:
                                                                                            "12",
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "field",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "label",
                                                                                              {
                                                                                                staticClass:
                                                                                                  " mb-1 d-flex",
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    _vm.$t(
                                                                                                      "createCampaign.custType"
                                                                                                    )
                                                                                                  )
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "v-select",
                                                                                              {
                                                                                                attrs: {
                                                                                                  items:
                                                                                                    _vm.customerTypes,
                                                                                                  placeholder: _vm.$t(
                                                                                                    "createCampaign.custType"
                                                                                                  ),
                                                                                                  required:
                                                                                                    "",
                                                                                                  attach:
                                                                                                    "",
                                                                                                  dense:
                                                                                                    "",
                                                                                                  "small-chips":
                                                                                                    "",
                                                                                                  "deletable-chips":
                                                                                                    "",
                                                                                                  multiple:
                                                                                                    "",
                                                                                                  rules: [
                                                                                                    function (
                                                                                                      v
                                                                                                    ) {
                                                                                                      return (
                                                                                                        !!(
                                                                                                          v &&
                                                                                                          v.length
                                                                                                        ) ||
                                                                                                        _vm.$t(
                                                                                                          "campaignGenerator.selectCustomerType"
                                                                                                        )
                                                                                                      )
                                                                                                    },
                                                                                                  ],
                                                                                                },
                                                                                                model: {
                                                                                                  value:
                                                                                                    _vm.selectedCustomerType,
                                                                                                  callback: function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.selectedCustomerType = $$v
                                                                                                  },
                                                                                                  expression:
                                                                                                    "\n                                                    selectedCustomerType\n                                                  ",
                                                                                                },
                                                                                              }
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "v-col",
                                                                                      {
                                                                                        staticClass:
                                                                                          "py-2",
                                                                                        attrs: {
                                                                                          lg:
                                                                                            "12",
                                                                                          md:
                                                                                            "12",
                                                                                          sm:
                                                                                            "12",
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "field",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "label",
                                                                                              {
                                                                                                staticClass:
                                                                                                  " mb-1 d-flex",
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    _vm.$t(
                                                                                                      "overview.device"
                                                                                                    )
                                                                                                  )
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "v-select",
                                                                                              {
                                                                                                attrs: {
                                                                                                  items:
                                                                                                    _vm.Devices,
                                                                                                  placeholder: _vm.$t(
                                                                                                    "overview.device"
                                                                                                  ),
                                                                                                  required:
                                                                                                    "",
                                                                                                  dense:
                                                                                                    "",
                                                                                                  attach:
                                                                                                    "",
                                                                                                  multiple:
                                                                                                    "",
                                                                                                  "small-chips":
                                                                                                    "",
                                                                                                  "deletable-chips":
                                                                                                    "",
                                                                                                  rules: [
                                                                                                    function (
                                                                                                      v
                                                                                                    ) {
                                                                                                      return (
                                                                                                        !!(
                                                                                                          v &&
                                                                                                          v.length
                                                                                                        ) ||
                                                                                                        _vm.$t(
                                                                                                          "createCampaignRules.selectDevices"
                                                                                                        )
                                                                                                      )
                                                                                                    },
                                                                                                  ],
                                                                                                },
                                                                                                scopedSlots: _vm._u(
                                                                                                  [
                                                                                                    {
                                                                                                      key:
                                                                                                        "item",
                                                                                                      fn: function (
                                                                                                        data
                                                                                                      ) {
                                                                                                        return [
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-icon",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "mr-2",
                                                                                                                attrs: {
                                                                                                                  small:
                                                                                                                    "",
                                                                                                                },
                                                                                                              },
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  _vm._s(
                                                                                                                    data
                                                                                                                      .item
                                                                                                                      .src
                                                                                                                  )
                                                                                                                ),
                                                                                                              ]
                                                                                                            ),
                                                                                                            _c(
                                                                                                              "v-list-item-content",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "ml-2",
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "v-list-item-title",
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      _vm._s(
                                                                                                                        data
                                                                                                                          .item
                                                                                                                          .text
                                                                                                                      )
                                                                                                                    ),
                                                                                                                  ]
                                                                                                                ),
                                                                                                              ],
                                                                                                              1
                                                                                                            ),
                                                                                                          ],
                                                                                                        ]
                                                                                                      },
                                                                                                    },
                                                                                                  ]
                                                                                                ),
                                                                                                model: {
                                                                                                  value:
                                                                                                    _vm.selectedDevice,
                                                                                                  callback: function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.selectedDevice = $$v
                                                                                                  },
                                                                                                  expression:
                                                                                                    "selectedDevice",
                                                                                                },
                                                                                              }
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          " text-right pt-5 sticky-bottom ",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "mr-4 px-6",
                                                                            attrs: {
                                                                              color:
                                                                                "primary",
                                                                              outlined:
                                                                                "",
                                                                              large:
                                                                                "",
                                                                            },
                                                                            on: {
                                                                              click: function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.draftTemplate()
                                                                              },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "campaignGenerator.saveAsDraft"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "mr-4 px-6",
                                                                            attrs: {
                                                                              color:
                                                                                "primary",
                                                                              outlined:
                                                                                "",
                                                                              large:
                                                                                "",
                                                                            },
                                                                            on: {
                                                                              click:
                                                                                _vm.goBack,
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Back "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "px-6",
                                                                            attrs: {
                                                                              color:
                                                                                "primary",
                                                                              depressed:
                                                                                "",
                                                                              large:
                                                                                "",
                                                                              disabled:
                                                                                !_vm.valid3 ||
                                                                                _vm.editDataDialog,
                                                                            },
                                                                            on: {
                                                                              click: function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.segmentationSelected()
                                                                              },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "createCampaign.next"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-stepper-content",
                                                                  {
                                                                    staticClass:
                                                                      "pa-0",
                                                                    attrs: {
                                                                      step: "4",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-card",
                                                                      {
                                                                        staticClass:
                                                                          " px-0 py-3",
                                                                        attrs: {
                                                                          elevation:
                                                                            "0",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "p",
                                                                          {
                                                                            staticClass:
                                                                              "body--text-1-bold grey--text mb-8",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "createCampaign.keyPara"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm
                                                                          .manualKeyCategory
                                                                          .length ===
                                                                        0
                                                                          ? _c(
                                                                              "p",
                                                                              {
                                                                                staticClass:
                                                                                  "text-subtitle-2 primarybase--text mb-6",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " Note : " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "campaignGenerator.nokeyPara"
                                                                                      )
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _c(
                                                                          "v-expansion-panels",
                                                                          {
                                                                            staticClass:
                                                                              "expansion-panal-list expansion-panal-list-4 pa-0 add-keyword-panalList",
                                                                          },
                                                                          _vm._l(
                                                                            _vm.manualKeyCategory,
                                                                            function (
                                                                              item
                                                                            ) {
                                                                              return _c(
                                                                                "v-expansion-panel",
                                                                                {
                                                                                  key: item,
                                                                                  attrs: {
                                                                                    elevation:
                                                                                      "0",
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-expansion-panel-header",
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "d-flex align-center justify-space-between expand-sec text-overflow body--text-2",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            " " +
                                                                                              _vm._s(
                                                                                                _vm.selectedAccount
                                                                                              ) +
                                                                                              "-" +
                                                                                              _vm._s(
                                                                                                _vm.selectedApp &&
                                                                                                  _vm
                                                                                                    .selectedApp
                                                                                                    .appName
                                                                                              ) +
                                                                                              "-" +
                                                                                              _vm._s(
                                                                                                item
                                                                                              ) +
                                                                                              "-" +
                                                                                              _vm._s(
                                                                                                _vm.currentDate
                                                                                              ) +
                                                                                              "-" +
                                                                                              _vm._s(
                                                                                                _vm.currentTime
                                                                                              ) +
                                                                                              " "
                                                                                          ),
                                                                                          _c(
                                                                                            "v-tooltip",
                                                                                            {
                                                                                              attrs: {
                                                                                                bottom:
                                                                                                  "",
                                                                                              },
                                                                                              scopedSlots: _vm._u(
                                                                                                [
                                                                                                  {
                                                                                                    key:
                                                                                                      "activator",
                                                                                                    fn: function (
                                                                                                      ref
                                                                                                    ) {
                                                                                                      var on =
                                                                                                        ref.on
                                                                                                      var attrs =
                                                                                                        ref.attrs
                                                                                                      return [
                                                                                                        _c(
                                                                                                          "v-btn",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "px-2",
                                                                                                            attrs: {
                                                                                                              color:
                                                                                                                "primary",
                                                                                                              text:
                                                                                                                "",
                                                                                                            },
                                                                                                            on: {
                                                                                                              click: function (
                                                                                                                $event
                                                                                                              ) {
                                                                                                                return _vm.addTargetKeywords(
                                                                                                                  item
                                                                                                                )
                                                                                                              },
                                                                                                            },
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-icon",
                                                                                                              _vm._g(
                                                                                                                _vm._b(
                                                                                                                  {
                                                                                                                    attrs: {
                                                                                                                      color:
                                                                                                                        "primary",
                                                                                                                      dark:
                                                                                                                        "",
                                                                                                                    },
                                                                                                                  },
                                                                                                                  "v-icon",
                                                                                                                  attrs,
                                                                                                                  false
                                                                                                                ),
                                                                                                                on
                                                                                                              ),
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  " mdi-plus "
                                                                                                                ),
                                                                                                              ]
                                                                                                            ),
                                                                                                            _vm._v(
                                                                                                              " " +
                                                                                                                _vm._s(
                                                                                                                  _vm.$t(
                                                                                                                    "campaignGenerator.keyManually"
                                                                                                                  )
                                                                                                                ) +
                                                                                                                " "
                                                                                                            ),
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                      ]
                                                                                                    },
                                                                                                  },
                                                                                                ],
                                                                                                null,
                                                                                                true
                                                                                              ),
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " " +
                                                                                                      _vm._s(
                                                                                                        _vm.$t(
                                                                                                          "campaignGenerator.keyManually"
                                                                                                        )
                                                                                                      )
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "v-expansion-panel-content",
                                                                                    {
                                                                                      attrs: {
                                                                                        elevation:
                                                                                          "0",
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "expansionTitle titleHead black--textd-flex justify-space-between",
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "h4",
                                                                                            {
                                                                                              staticClass:
                                                                                                "body-2",
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                " " +
                                                                                                  _vm._s(
                                                                                                    _vm.$t(
                                                                                                      "createCampaign.targKey"
                                                                                                    )
                                                                                                  ) +
                                                                                                  " "
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _c(
                                                                                        "v-data-table",
                                                                                        {
                                                                                          staticClass:
                                                                                            "elevation-0 table-td",
                                                                                          attrs: {
                                                                                            headers:
                                                                                              _vm.tKeysheaders,
                                                                                            items: _vm.tarKey(
                                                                                              item
                                                                                            ),
                                                                                            "items-per-page": 10,
                                                                                          },
                                                                                          scopedSlots: _vm._u(
                                                                                            [
                                                                                              {
                                                                                                key:
                                                                                                  "item.delete",
                                                                                                fn: function (
                                                                                                  ref
                                                                                                ) {
                                                                                                  var item =
                                                                                                    ref.item
                                                                                                  return [
                                                                                                    _c(
                                                                                                      "v-icon",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "icon-hover",
                                                                                                        attrs: {
                                                                                                          color:
                                                                                                            "primary",
                                                                                                          medium:
                                                                                                            "",
                                                                                                        },
                                                                                                        on: {
                                                                                                          click: function (
                                                                                                            $event
                                                                                                          ) {
                                                                                                            return _vm.deleteTkeyFromTable(
                                                                                                              item
                                                                                                            )
                                                                                                          },
                                                                                                        },
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          "mdi-delete-outline"
                                                                                                        ),
                                                                                                      ]
                                                                                                    ),
                                                                                                  ]
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                            null,
                                                                                            true
                                                                                          ),
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              )
                                                                            }
                                                                          ),
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          " text-right sticky-bottom",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "mr-4 px-4",
                                                                            attrs: {
                                                                              color:
                                                                                "primary",
                                                                              outlined:
                                                                                "",
                                                                              large:
                                                                                "",
                                                                            },
                                                                            on: {
                                                                              click:
                                                                                _vm.goBack,
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " Back "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "mr-4 px-4",
                                                                            attrs: {
                                                                              color:
                                                                                "primary",
                                                                              outlined:
                                                                                "",
                                                                              large:
                                                                                "",
                                                                            },
                                                                            on: {
                                                                              click: function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.draftTemplate()
                                                                              },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "campaignGenerator.saveAsDraft"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            staticClass:
                                                                              "px-4",
                                                                            attrs: {
                                                                              color:
                                                                                "primary",
                                                                              depressed:
                                                                                "",
                                                                              large:
                                                                                "",
                                                                              disabled:
                                                                                _vm
                                                                                  .manualKeyCategory
                                                                                  .length !=
                                                                                _vm
                                                                                  .tKeys
                                                                                  .length,
                                                                            },
                                                                            on: {
                                                                              click: function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.keysAdded()
                                                                              },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "createCampaign.next"
                                                                                  )
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "AddKeysForAutCamp",
                                                                      {
                                                                        attrs: {
                                                                          addKeysData:
                                                                            _vm.addKeywordsData,
                                                                        },
                                                                        on: {
                                                                          addKeysSaveClicked: function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.addTargetKeysSaveClicked(
                                                                              $event
                                                                            )
                                                                          },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "v-stepper-content",
                                                                  {
                                                                    staticClass:
                                                                      "pa-0",
                                                                    attrs: {
                                                                      step: "5",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-form",
                                                                      {
                                                                        ref:
                                                                          "campgen5",
                                                                        model: {
                                                                          value:
                                                                            _vm.valid5,
                                                                          callback: function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.valid5 = $$v
                                                                          },
                                                                          expression:
                                                                            "valid5",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-card",
                                                                          {
                                                                            staticClass:
                                                                              " px-9 py-3 pb-6",
                                                                            attrs: {
                                                                              elevation:
                                                                                "0",
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-row",
                                                                              {
                                                                                staticClass:
                                                                                  "mt-0",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-col",
                                                                                  {
                                                                                    staticClass:
                                                                                      "py-0",
                                                                                    attrs: {
                                                                                      lg:
                                                                                        "12",
                                                                                      md:
                                                                                        "12",
                                                                                      sm:
                                                                                        "12",
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "d-flex justify-space-between primary--text mb-6",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "body--text-1",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              " " +
                                                                                                _vm._s(
                                                                                                  _vm.$t(
                                                                                                    "campaignGenerator.CampaignTitle"
                                                                                                  )
                                                                                                ) +
                                                                                                " "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "body--text-1",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              " " +
                                                                                                _vm._s(
                                                                                                  _vm.$t(
                                                                                                    "campaignGenerator.TotalCampaigns"
                                                                                                  )
                                                                                                ) +
                                                                                                ": " +
                                                                                                _vm._s(
                                                                                                  _vm
                                                                                                    .campList
                                                                                                    .length
                                                                                                ) +
                                                                                                " "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "body--text-1",
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              " " +
                                                                                                _vm._s(
                                                                                                  _vm.$t(
                                                                                                    "campaignGenerator.TotalAdgroups"
                                                                                                  )
                                                                                                ) +
                                                                                                ": " +
                                                                                                _vm._s(
                                                                                                  _vm
                                                                                                    .adList
                                                                                                    .length
                                                                                                ) +
                                                                                                " "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    _c(
                                                                                      "v-expansion-panels",
                                                                                      {
                                                                                        staticClass:
                                                                                          "expansion-panal-list campaign-expansion-panel ad-group-panel-list",
                                                                                      },
                                                                                      _vm._l(
                                                                                        _vm.campList,
                                                                                        function (
                                                                                          camp,
                                                                                          i
                                                                                        ) {
                                                                                          return _c(
                                                                                            "v-expansion-panel",
                                                                                            {
                                                                                              key:
                                                                                                camp.id,
                                                                                              staticClass:
                                                                                                "mb-2",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "d-flex align-start",
                                                                                                  attrs: {
                                                                                                    id:
                                                                                                      "idiv",
                                                                                                  },
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-expansion-panel-header",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "px-0",
                                                                                                      staticStyle: {
                                                                                                        "max-width":
                                                                                                          "fit-content",
                                                                                                      },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "div",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "panel-header-left",
                                                                                                        },
                                                                                                        [
                                                                                                          _c(
                                                                                                            "div",
                                                                                                            {
                                                                                                              staticClass:
                                                                                                                "arrows mb-1",
                                                                                                            },
                                                                                                            [
                                                                                                              _c(
                                                                                                                "v-icon",
                                                                                                                {
                                                                                                                  staticClass:
                                                                                                                    "plus",
                                                                                                                },
                                                                                                                [
                                                                                                                  _vm._v(
                                                                                                                    "mdi-plus-circle-outline"
                                                                                                                  ),
                                                                                                                ]
                                                                                                              ),
                                                                                                              _c(
                                                                                                                "v-icon",
                                                                                                                {
                                                                                                                  staticClass:
                                                                                                                    "minus",
                                                                                                                },
                                                                                                                [
                                                                                                                  _vm._v(
                                                                                                                    "mdi-minus-circle-outline"
                                                                                                                  ),
                                                                                                                ]
                                                                                                              ),
                                                                                                            ],
                                                                                                            1
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "font-14 black--text  d-flex align-baseline w-100 justify-space-between",
                                                                                                      staticStyle: {},
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-text-field",
                                                                                                        {
                                                                                                          attrs: {
                                                                                                            label: camp.editMode
                                                                                                              ? "Enter some text and press enter key"
                                                                                                              : "",
                                                                                                            placeholder:
                                                                                                              "Enter some text and press enter key",
                                                                                                            outlined: camp.editMode
                                                                                                              ? true
                                                                                                              : false,
                                                                                                            solo:
                                                                                                              "",
                                                                                                            dense:
                                                                                                              "",
                                                                                                            required:
                                                                                                              "",
                                                                                                            readonly: !camp.editMode,
                                                                                                            rules: [
                                                                                                              function (
                                                                                                                v
                                                                                                              ) {
                                                                                                                return (
                                                                                                                  !!v ||
                                                                                                                  _vm.$t(
                                                                                                                    "campaignGenerator.pleaseEnterValue"
                                                                                                                  )
                                                                                                                )
                                                                                                              },
                                                                                                              function (
                                                                                                                v
                                                                                                              ) {
                                                                                                                return (
                                                                                                                  _vm.campList
                                                                                                                    .map(
                                                                                                                      function (
                                                                                                                        e
                                                                                                                      ) {
                                                                                                                        return e.name
                                                                                                                      }
                                                                                                                    )
                                                                                                                    .filter(
                                                                                                                      function (
                                                                                                                        f
                                                                                                                      ) {
                                                                                                                        return (
                                                                                                                          f ===
                                                                                                                          v
                                                                                                                        )
                                                                                                                      }
                                                                                                                    )
                                                                                                                    .length ===
                                                                                                                    1 ||
                                                                                                                  _vm.$t(
                                                                                                                    "campaignGenerator.campNameUniq"
                                                                                                                  )
                                                                                                                )
                                                                                                              },
                                                                                                              function (
                                                                                                                v
                                                                                                              ) {
                                                                                                                return (
                                                                                                                  camp.nameUnique ||
                                                                                                                  _vm.$t(
                                                                                                                    "campaignGenerator.campNamExists"
                                                                                                                  )
                                                                                                                )
                                                                                                              },
                                                                                                            ],
                                                                                                          },
                                                                                                          on: {
                                                                                                            keyup: function (
                                                                                                              $event
                                                                                                            ) {
                                                                                                              if (
                                                                                                                !$event.type.indexOf(
                                                                                                                  "key"
                                                                                                                ) &&
                                                                                                                _vm._k(
                                                                                                                  $event.keyCode,
                                                                                                                  "enter",
                                                                                                                  13,
                                                                                                                  $event.key,
                                                                                                                  "Enter"
                                                                                                                )
                                                                                                              ) {
                                                                                                                return null
                                                                                                              }
                                                                                                              camp.editMode = false
                                                                                                              _vm.$refs.campgen5.validate()
                                                                                                              _vm.checkCampaignName(
                                                                                                                camp
                                                                                                              )
                                                                                                            },
                                                                                                            mouseup: function (
                                                                                                              $event
                                                                                                            ) {
                                                                                                              if (
                                                                                                                !$event.type.indexOf(
                                                                                                                  "key"
                                                                                                                ) &&
                                                                                                                _vm._k(
                                                                                                                  $event.keyCode,
                                                                                                                  "left",
                                                                                                                  37,
                                                                                                                  $event.key,
                                                                                                                  [
                                                                                                                    "Left",
                                                                                                                    "ArrowLeft",
                                                                                                                  ]
                                                                                                                )
                                                                                                              ) {
                                                                                                                return null
                                                                                                              }
                                                                                                              if (
                                                                                                                "button" in
                                                                                                                  $event &&
                                                                                                                $event.button !==
                                                                                                                  0
                                                                                                              ) {
                                                                                                                return null
                                                                                                              }
                                                                                                              camp.editMode = true
                                                                                                            },
                                                                                                            blur: function (
                                                                                                              $event
                                                                                                            ) {
                                                                                                              _vm.$refs.campgen5.validate()
                                                                                                              _vm.checkCampaignName(
                                                                                                                camp
                                                                                                              )
                                                                                                            },
                                                                                                          },
                                                                                                          model: {
                                                                                                            value:
                                                                                                              camp.name,
                                                                                                            callback: function (
                                                                                                              $$v
                                                                                                            ) {
                                                                                                              _vm.$set(
                                                                                                                camp,
                                                                                                                "name",
                                                                                                                $$v
                                                                                                              )
                                                                                                            },
                                                                                                            expression:
                                                                                                              "camp.name",
                                                                                                          },
                                                                                                        }
                                                                                                      ),
                                                                                                      !camp.editMode
                                                                                                        ? _c(
                                                                                                            "v-icon",
                                                                                                            {
                                                                                                              staticClass:
                                                                                                                "ml- ",
                                                                                                              attrs: {
                                                                                                                color:
                                                                                                                  "primary",
                                                                                                              },
                                                                                                              on: {
                                                                                                                click: function (
                                                                                                                  $event
                                                                                                                ) {
                                                                                                                  $event.stopPropagation()
                                                                                                                  return _vm.editCampListItem(
                                                                                                                    camp,
                                                                                                                    i
                                                                                                                  )
                                                                                                                },
                                                                                                              },
                                                                                                            },
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "mdi-pencil-outline"
                                                                                                              ),
                                                                                                            ]
                                                                                                          )
                                                                                                        : _vm._e(),
                                                                                                      camp.editMode
                                                                                                        ? _c(
                                                                                                            "v-icon",
                                                                                                            {
                                                                                                              staticClass:
                                                                                                                "ml-2 ",
                                                                                                              attrs: {
                                                                                                                disabled: !camp.name,
                                                                                                                color:
                                                                                                                  "primary",
                                                                                                              },
                                                                                                              on: {
                                                                                                                click: function (
                                                                                                                  $event
                                                                                                                ) {
                                                                                                                  $event.stopPropagation()
                                                                                                                  return _vm.saveCampListItem(
                                                                                                                    camp,
                                                                                                                    i
                                                                                                                  )
                                                                                                                },
                                                                                                              },
                                                                                                            },
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                "mdi-checkbox-marked-circle-outline"
                                                                                                              ),
                                                                                                            ]
                                                                                                          )
                                                                                                        : _vm._e(),
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                              _c(
                                                                                                "v-expansion-panel-content",
                                                                                                [
                                                                                                  _c(
                                                                                                    "div",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "expansionTitle titleHead primary--text d-flex justify-space-between",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "h6",
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            " " +
                                                                                                              _vm._s(
                                                                                                                _vm.$t(
                                                                                                                  "tabs.adgroups"
                                                                                                                )
                                                                                                              ) +
                                                                                                              " "
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "v-card",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "adGroupsCard card mt-0",
                                                                                                      attrs: {
                                                                                                        elevation:
                                                                                                          "0",
                                                                                                        width:
                                                                                                          "100%",
                                                                                                      },
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-simple-table",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "overflow-y-auto",
                                                                                                          attrs: {
                                                                                                            "max-height":
                                                                                                              "300px ",
                                                                                                          },
                                                                                                          scopedSlots: _vm._u(
                                                                                                            [
                                                                                                              {
                                                                                                                key:
                                                                                                                  "default",
                                                                                                                fn: function () {
                                                                                                                  return [
                                                                                                                    _c(
                                                                                                                      "tbody",
                                                                                                                      {
                                                                                                                        staticClass:
                                                                                                                          "table-td",
                                                                                                                        attrs: {
                                                                                                                          "max-height":
                                                                                                                            "",
                                                                                                                        },
                                                                                                                      },
                                                                                                                      _vm._l(
                                                                                                                        _vm.adList,
                                                                                                                        function (
                                                                                                                          ad,
                                                                                                                          i
                                                                                                                        ) {
                                                                                                                          return _c(
                                                                                                                            "tr",
                                                                                                                            {
                                                                                                                              key:
                                                                                                                                _vm
                                                                                                                                  .adList
                                                                                                                                  .id,
                                                                                                                            },
                                                                                                                            [
                                                                                                                              ad.type ===
                                                                                                                              camp.type
                                                                                                                                ? _c(
                                                                                                                                    "td",
                                                                                                                                    [
                                                                                                                                      _c(
                                                                                                                                        "div",
                                                                                                                                        {
                                                                                                                                          staticClass:
                                                                                                                                            "d-flex align-baseline",
                                                                                                                                        },
                                                                                                                                        [
                                                                                                                                          _c(
                                                                                                                                            "v-text-field",
                                                                                                                                            {
                                                                                                                                              staticClass:
                                                                                                                                                "px-0",
                                                                                                                                              attrs: {
                                                                                                                                                label: ad.editMode
                                                                                                                                                  ? "Enter some text and press enter key"
                                                                                                                                                  : "",
                                                                                                                                                placeholder:
                                                                                                                                                  "Enter some text and press enter key",
                                                                                                                                                outlined: ad.editMode
                                                                                                                                                  ? true
                                                                                                                                                  : false,
                                                                                                                                                solo:
                                                                                                                                                  "",
                                                                                                                                                dense:
                                                                                                                                                  "",
                                                                                                                                                required:
                                                                                                                                                  "",
                                                                                                                                                readonly: !ad.editMode,
                                                                                                                                                rules: [
                                                                                                                                                  function (
                                                                                                                                                    v
                                                                                                                                                  ) {
                                                                                                                                                    return (
                                                                                                                                                      !!v ||
                                                                                                                                                      _vm.$t(
                                                                                                                                                        "campaignGenerator.pleaseEnterValue"
                                                                                                                                                      )
                                                                                                                                                    )
                                                                                                                                                  },
                                                                                                                                                  function (
                                                                                                                                                    v
                                                                                                                                                  ) {
                                                                                                                                                    return (
                                                                                                                                                      _vm.adList
                                                                                                                                                        .map(
                                                                                                                                                          function (
                                                                                                                                                            e
                                                                                                                                                          ) {
                                                                                                                                                            return e.name
                                                                                                                                                          }
                                                                                                                                                        )
                                                                                                                                                        .filter(
                                                                                                                                                          function (
                                                                                                                                                            f
                                                                                                                                                          ) {
                                                                                                                                                            return (
                                                                                                                                                              f ===
                                                                                                                                                              v
                                                                                                                                                            )
                                                                                                                                                          }
                                                                                                                                                        )
                                                                                                                                                        .length ===
                                                                                                                                                        1 ||
                                                                                                                                                      _vm.$t(
                                                                                                                                                        "campaignGenerator.adNameUniq"
                                                                                                                                                      )
                                                                                                                                                    )
                                                                                                                                                  },
                                                                                                                                                ],
                                                                                                                                              },
                                                                                                                                              on: {
                                                                                                                                                keyup: function (
                                                                                                                                                  $event
                                                                                                                                                ) {
                                                                                                                                                  if (
                                                                                                                                                    !$event.type.indexOf(
                                                                                                                                                      "key"
                                                                                                                                                    ) &&
                                                                                                                                                    _vm._k(
                                                                                                                                                      $event.keyCode,
                                                                                                                                                      "enter",
                                                                                                                                                      13,
                                                                                                                                                      $event.key,
                                                                                                                                                      "Enter"
                                                                                                                                                    )
                                                                                                                                                  ) {
                                                                                                                                                    return null
                                                                                                                                                  }
                                                                                                                                                  ad.editMode = false
                                                                                                                                                  _vm.$refs.campgen5.validate()
                                                                                                                                                },
                                                                                                                                                mouseup: function (
                                                                                                                                                  $event
                                                                                                                                                ) {
                                                                                                                                                  if (
                                                                                                                                                    !$event.type.indexOf(
                                                                                                                                                      "key"
                                                                                                                                                    ) &&
                                                                                                                                                    _vm._k(
                                                                                                                                                      $event.keyCode,
                                                                                                                                                      "left",
                                                                                                                                                      37,
                                                                                                                                                      $event.key,
                                                                                                                                                      [
                                                                                                                                                        "Left",
                                                                                                                                                        "ArrowLeft",
                                                                                                                                                      ]
                                                                                                                                                    )
                                                                                                                                                  ) {
                                                                                                                                                    return null
                                                                                                                                                  }
                                                                                                                                                  if (
                                                                                                                                                    "button" in
                                                                                                                                                      $event &&
                                                                                                                                                    $event.button !==
                                                                                                                                                      0
                                                                                                                                                  ) {
                                                                                                                                                    return null
                                                                                                                                                  }
                                                                                                                                                  ad.editMode = true
                                                                                                                                                },
                                                                                                                                                blur: function (
                                                                                                                                                  $event
                                                                                                                                                ) {
                                                                                                                                                  return _vm.$refs.campgen5.validate()
                                                                                                                                                },
                                                                                                                                              },
                                                                                                                                              model: {
                                                                                                                                                value:
                                                                                                                                                  ad.name,
                                                                                                                                                callback: function (
                                                                                                                                                  $$v
                                                                                                                                                ) {
                                                                                                                                                  _vm.$set(
                                                                                                                                                    ad,
                                                                                                                                                    "name",
                                                                                                                                                    $$v
                                                                                                                                                  )
                                                                                                                                                },
                                                                                                                                                expression:
                                                                                                                                                  "ad.name",
                                                                                                                                              },
                                                                                                                                            }
                                                                                                                                          ),
                                                                                                                                          !ad.editMode
                                                                                                                                            ? _c(
                                                                                                                                                "v-icon",
                                                                                                                                                {
                                                                                                                                                  staticClass:
                                                                                                                                                    "ml-2",
                                                                                                                                                  attrs: {
                                                                                                                                                    color:
                                                                                                                                                      "primary",
                                                                                                                                                  },
                                                                                                                                                  on: {
                                                                                                                                                    click: function (
                                                                                                                                                      $event
                                                                                                                                                    ) {
                                                                                                                                                      $event.stopPropagation()
                                                                                                                                                      return _vm.editAdsListItem(
                                                                                                                                                        ad,
                                                                                                                                                        i
                                                                                                                                                      )
                                                                                                                                                    },
                                                                                                                                                  },
                                                                                                                                                },
                                                                                                                                                [
                                                                                                                                                  _vm._v(
                                                                                                                                                    "mdi-pencil-outline"
                                                                                                                                                  ),
                                                                                                                                                ]
                                                                                                                                              )
                                                                                                                                            : _vm._e(),
                                                                                                                                          ad.editMode
                                                                                                                                            ? _c(
                                                                                                                                                "v-icon",
                                                                                                                                                {
                                                                                                                                                  staticClass:
                                                                                                                                                    "ml-2 ",
                                                                                                                                                  attrs: {
                                                                                                                                                    disabled: !ad.name,
                                                                                                                                                    color:
                                                                                                                                                      "primary",
                                                                                                                                                  },
                                                                                                                                                  on: {
                                                                                                                                                    click: function (
                                                                                                                                                      $event
                                                                                                                                                    ) {
                                                                                                                                                      $event.stopPropagation()
                                                                                                                                                      return _vm.saveAdsListItem(
                                                                                                                                                        ad,
                                                                                                                                                        i
                                                                                                                                                      )
                                                                                                                                                    },
                                                                                                                                                  },
                                                                                                                                                },
                                                                                                                                                [
                                                                                                                                                  _vm._v(
                                                                                                                                                    "mdi-checkbox-marked-circle-outline"
                                                                                                                                                  ),
                                                                                                                                                ]
                                                                                                                                              )
                                                                                                                                            : _vm._e(),
                                                                                                                                        ],
                                                                                                                                        1
                                                                                                                                      ),
                                                                                                                                    ]
                                                                                                                                  )
                                                                                                                                : _vm._e(),
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        }
                                                                                                                      ),
                                                                                                                      0
                                                                                                                    ),
                                                                                                                  ]
                                                                                                                },
                                                                                                                proxy: true,
                                                                                                              },
                                                                                                            ],
                                                                                                            null,
                                                                                                            true
                                                                                                          ),
                                                                                                        }
                                                                                                      ),
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        }
                                                                                      ),
                                                                                      1
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              " text-right sticky-bottom ",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                staticClass:
                                                                                  "mr-4 px-4",
                                                                                attrs: {
                                                                                  color:
                                                                                    "primary",
                                                                                  outlined:
                                                                                    "",
                                                                                  large:
                                                                                    "",
                                                                                },
                                                                                on: {
                                                                                  click:
                                                                                    _vm.goBack,
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " Back "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                staticClass:
                                                                                  "mr-4 px-4",
                                                                                attrs: {
                                                                                  color:
                                                                                    "primary",
                                                                                  outlined:
                                                                                    "",
                                                                                  large:
                                                                                    "",
                                                                                },
                                                                                on: {
                                                                                  click: function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.draftTemplate()
                                                                                  },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "campaignGenerator.saveAsDraft"
                                                                                      )
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                staticClass:
                                                                                  "px-4",
                                                                                attrs: {
                                                                                  color:
                                                                                    "primary",
                                                                                  depressed:
                                                                                    "",
                                                                                  large:
                                                                                    "",
                                                                                  disabled:
                                                                                    _vm.editMode ||
                                                                                    _vm.editMode1,
                                                                                },
                                                                                on: {
                                                                                  click: function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.saveTemplate()
                                                                                  },
                                                                                },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "editAdgroups.save"
                                                                                      )
                                                                                    ) +
                                                                                    " "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                    ],
                                                    2
                                                  ),
                                                  _c(
                                                    "v-dialog",
                                                    {
                                                      attrs: {
                                                        "max-width": "900px",
                                                        persistent: "",
                                                      },
                                                      model: {
                                                        value: _vm.draftList,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.draftList = $$v
                                                        },
                                                        expression: "draftList",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-card",
                                                        {
                                                          staticClass:
                                                            "v-modal-fixed style-2",
                                                        },
                                                        [
                                                          _c(
                                                            "v-card-title",
                                                            {
                                                              staticClass:
                                                                "headline sticky-header v-modal-header d-flex justify-space-between px-5 py-3",
                                                            },
                                                            [
                                                              _c("h5", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "campaignGenerator.draftHeading"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "black--text",
                                                                  on: {
                                                                    click: function (
                                                                      $event
                                                                    ) {
                                                                      _vm.draftList = false
                                                                    },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-close"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card-text",
                                                            {
                                                              staticClass:
                                                                "px-5",
                                                            },
                                                            [
                                                              [
                                                                _c(
                                                                  "v-card",
                                                                  {
                                                                    staticClass:
                                                                      "rounded",
                                                                    attrs: {
                                                                      elevation:
                                                                        "0",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "v-search mx-3 d-flex align-center justify-end w-100",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-text-field",
                                                                          {
                                                                            staticClass:
                                                                              "  max-275 pr-4",
                                                                            attrs: {
                                                                              "append-icon":
                                                                                "mdi-magnify",
                                                                              label: _vm.$t(
                                                                                "campaignGenerator.draftSearch"
                                                                              ),
                                                                              "hide-details":
                                                                                "",
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                _vm.search,
                                                                              callback: function (
                                                                                $$v
                                                                              ) {
                                                                                _vm.search = $$v
                                                                              },
                                                                              expression:
                                                                                "search",
                                                                            },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-card-text",
                                                                      {
                                                                        staticClass:
                                                                          "pa-0",
                                                                      },
                                                                      [
                                                                        [
                                                                          _c(
                                                                            "v-card",
                                                                            {
                                                                              staticClass:
                                                                                "rounded-0 br-0",
                                                                              attrs: {
                                                                                outlined:
                                                                                  "",
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-data-table",
                                                                                {
                                                                                  staticClass:
                                                                                    "elevation-0 pa-0 mt-2",
                                                                                  attrs: {
                                                                                    loading:
                                                                                      _vm
                                                                                        .$apollo
                                                                                        .queries
                                                                                        .getDraftTemplateList
                                                                                        .loading,
                                                                                    "items-per-page": 5,
                                                                                    headers:
                                                                                      _vm.draftListHeaders,
                                                                                    items:
                                                                                      _vm.getDraftTemplateList ||
                                                                                      [],
                                                                                    search:
                                                                                      _vm.search,
                                                                                    "no-data-text": _vm.$t(
                                                                                      "keyOpt.noData"
                                                                                    ),
                                                                                    "footer-props": {
                                                                                      itemsPerPageText: _vm.$t(
                                                                                        "campaignGenerator.footerProps.itemsPerPageText"
                                                                                      ),
                                                                                      itemsPerPageAllText: _vm.$t(
                                                                                        "campaignGenerator.footerProps.itemsPerPageAllText"
                                                                                      ),
                                                                                    },
                                                                                  },
                                                                                  scopedSlots: _vm._u(
                                                                                    [
                                                                                      _vm._l(
                                                                                        Object.keys(
                                                                                          _vm.$scopedSlots
                                                                                        ),
                                                                                        function (
                                                                                          slot
                                                                                        ) {
                                                                                          return {
                                                                                            key: slot,
                                                                                            fn: function (
                                                                                              scope
                                                                                            ) {
                                                                                              return [
                                                                                                _vm._t(
                                                                                                  slot,
                                                                                                  null,
                                                                                                  null,
                                                                                                  scope
                                                                                                ),
                                                                                              ]
                                                                                            },
                                                                                          }
                                                                                        }
                                                                                      ),
                                                                                      {
                                                                                        key:
                                                                                          "item.resume",
                                                                                        fn: function (
                                                                                          ref
                                                                                        ) {
                                                                                          var item =
                                                                                            ref.item
                                                                                          return [
                                                                                            _c(
                                                                                              "v-icon",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "pa-0",
                                                                                                attrs: {
                                                                                                  text:
                                                                                                    "",
                                                                                                  color:
                                                                                                    "primary",
                                                                                                },
                                                                                                on: {
                                                                                                  click: function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.openDraft(
                                                                                                      item
                                                                                                    )
                                                                                                  },
                                                                                                },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "mdi-table-edit"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        },
                                                                                      },
                                                                                    ],
                                                                                    null,
                                                                                    true
                                                                                  ),
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                      ],
                                                                      2
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                            ],
                                                            2
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-dialog",
                                                    {
                                                      attrs: {
                                                        "max-width": "900px",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.editDataDialog,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.editDataDialog = $$v
                                                        },
                                                        expression:
                                                          "editDataDialog",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-card",
                                                        {
                                                          staticClass:
                                                            "v-modal-fixed style-2 app-setting-popup",
                                                        },
                                                        [
                                                          _c(
                                                            "v-card-title",
                                                            {
                                                              staticClass:
                                                                "headline v-modal-header sticky-header d-flex justify-space-between px-5 py-3",
                                                            },
                                                            [
                                                              _c("h5", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "campaignGenerator.setDefaultValues"
                                                                    )
                                                                  )
                                                                ),
                                                              ]),
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "black--text",
                                                                  attrs: {
                                                                    disabled:
                                                                      _vm.saving,
                                                                  },
                                                                  on: {
                                                                    click: function (
                                                                      $event
                                                                    ) {
                                                                      _vm.editDataDialog = false
                                                                    },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-close"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "v-card-text",
                                                            {
                                                              staticClass:
                                                                "pt-4 pb-0 px-0",
                                                            },
                                                            [
                                                              [
                                                                _c(
                                                                  "v-card",
                                                                  {
                                                                    staticClass:
                                                                      "rounded mt-0",
                                                                    attrs: {
                                                                      elevation:
                                                                        "0",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-card-subtitle",
                                                                      {
                                                                        staticClass:
                                                                          "px-8 py-0  text-body-2",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "p",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "campaignGenerator.setDefaultValuesSub"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "v-data-table",
                                                                      {
                                                                        staticClass:
                                                                          "elevation-0 pa-0 px-4",
                                                                        attrs: {
                                                                          "items-per-page": 5,
                                                                          headers:
                                                                            _vm.countryListHeaders,
                                                                          items:
                                                                            _vm.appCountriesListing ||
                                                                            [],
                                                                          search:
                                                                            _vm.countrySearch,
                                                                          "fixed-header":
                                                                            "",
                                                                          height:
                                                                            "18rem",
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "top",
                                                                              fn: function () {
                                                                                return [
                                                                                  _c(
                                                                                    "div",
                                                                                    {
                                                                                      staticClass:
                                                                                        "w-100 d-flex justify-end px-4",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-text-field",
                                                                                        {
                                                                                          attrs: {
                                                                                            "append-icon":
                                                                                              "mdi-magnify",
                                                                                            label: _vm.$t(
                                                                                              "campaignGenerator.draftSearch"
                                                                                            ),
                                                                                            "hide-details":
                                                                                              "",
                                                                                          },
                                                                                          model: {
                                                                                            value:
                                                                                              _vm.countrySearch,
                                                                                            callback: function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.countrySearch = $$v
                                                                                            },
                                                                                            expression:
                                                                                              "countrySearch",
                                                                                          },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              },
                                                                              proxy: true,
                                                                            },
                                                                            _vm._l(
                                                                              Object.keys(
                                                                                _vm.$scopedSlots
                                                                              ),
                                                                              function (
                                                                                slot
                                                                              ) {
                                                                                return {
                                                                                  key: slot,
                                                                                  fn: function (
                                                                                    scope
                                                                                  ) {
                                                                                    return [
                                                                                      _vm._t(
                                                                                        slot,
                                                                                        null,
                                                                                        null,
                                                                                        scope
                                                                                      ),
                                                                                    ]
                                                                                  },
                                                                                }
                                                                              }
                                                                            ),
                                                                            {
                                                                              key:
                                                                                "item.Name",
                                                                              fn: function (
                                                                                ref
                                                                              ) {
                                                                                var item =
                                                                                  ref.item
                                                                                return [
                                                                                  _c(
                                                                                    "v-row",
                                                                                    [
                                                                                      _c(
                                                                                        "v-col",
                                                                                        {
                                                                                          staticClass:
                                                                                            "d-flex align-center my-3",
                                                                                        },
                                                                                        [
                                                                                          [
                                                                                            _c(
                                                                                              "country-flag",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "ml2",
                                                                                                attrs: {
                                                                                                  country: item.Code.toLowerCase(),
                                                                                                },
                                                                                              }
                                                                                            ),
                                                                                            _c(
                                                                                              "span",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    item.Name
                                                                                                  )
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ],
                                                                                        ],
                                                                                        2
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              },
                                                                            },
                                                                            {
                                                                              key:
                                                                                "item.totalBudget",
                                                                              fn: function (
                                                                                ref
                                                                              ) {
                                                                                var item =
                                                                                  ref.item
                                                                                return [
                                                                                  _c(
                                                                                    "v-row",
                                                                                    [
                                                                                      _c(
                                                                                        "v-col",
                                                                                        {
                                                                                          staticClass:
                                                                                            "edit-td",
                                                                                          attrs: {
                                                                                            cols:
                                                                                              "6",
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-text-field",
                                                                                            {
                                                                                              attrs: {
                                                                                                "hide-details":
                                                                                                  "",
                                                                                                solo:
                                                                                                  "",
                                                                                                oninput:
                                                                                                  "if(this.value < 0) this.value = 0;",
                                                                                                type:
                                                                                                  "number",
                                                                                              },
                                                                                              on: {
                                                                                                change:
                                                                                                  _vm.budgetCheck,
                                                                                              },
                                                                                              model: {
                                                                                                value:
                                                                                                  item.totalBudget,
                                                                                                callback: function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    item,
                                                                                                    "totalBudget",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                                expression:
                                                                                                  "item.totalBudget",
                                                                                              },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              },
                                                                            },
                                                                            {
                                                                              key:
                                                                                "item.dailyCap",
                                                                              fn: function (
                                                                                ref
                                                                              ) {
                                                                                var item =
                                                                                  ref.item
                                                                                return [
                                                                                  _c(
                                                                                    "v-row",
                                                                                    [
                                                                                      _c(
                                                                                        "v-col",
                                                                                        {
                                                                                          staticClass:
                                                                                            "edit-td",
                                                                                          attrs: {
                                                                                            cols:
                                                                                              "6",
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-text-field",
                                                                                            {
                                                                                              attrs: {
                                                                                                "hide-details":
                                                                                                  "",
                                                                                                solo:
                                                                                                  "",
                                                                                                oninput:
                                                                                                  "if(this.value < 0) this.value = 0;",
                                                                                                type:
                                                                                                  "number",
                                                                                              },
                                                                                              model: {
                                                                                                value:
                                                                                                  item.dailyCap,
                                                                                                callback: function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    item,
                                                                                                    "dailyCap",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                                expression:
                                                                                                  "item.dailyCap",
                                                                                              },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              },
                                                                            },
                                                                            {
                                                                              key:
                                                                                "item.cpt",
                                                                              fn: function (
                                                                                ref
                                                                              ) {
                                                                                var item =
                                                                                  ref.item
                                                                                return [
                                                                                  _c(
                                                                                    "v-row",
                                                                                    [
                                                                                      _c(
                                                                                        "v-col",
                                                                                        {
                                                                                          staticClass:
                                                                                            "edit-td",
                                                                                          attrs: {
                                                                                            cols:
                                                                                              "6",
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-text-field",
                                                                                            {
                                                                                              attrs: {
                                                                                                "hide-details":
                                                                                                  "",
                                                                                                solo:
                                                                                                  "",
                                                                                                oninput:
                                                                                                  "if(this.value < 0) this.value = 0;",
                                                                                                type:
                                                                                                  "number",
                                                                                              },
                                                                                              model: {
                                                                                                value:
                                                                                                  item.cpt,
                                                                                                callback: function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.$set(
                                                                                                    item,
                                                                                                    "cpt",
                                                                                                    $$v
                                                                                                  )
                                                                                                },
                                                                                                expression:
                                                                                                  "item.cpt",
                                                                                              },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ]
                                                                              },
                                                                            },
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "bottom right sticky-footer",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-card-actions",
                                                                      {
                                                                        staticClass:
                                                                          "justify-end mt-0 px-8 py-4",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            attrs: {
                                                                              width:
                                                                                "127",
                                                                              color:
                                                                                "grey",
                                                                              outlined:
                                                                                "",
                                                                              depressed:
                                                                                "",
                                                                            },
                                                                            on: {
                                                                              click: function (
                                                                                $event
                                                                              ) {
                                                                                _vm.editDataDialog = false
                                                                              },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "Cancel"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _c(
                                                                          "v-btn",
                                                                          {
                                                                            attrs: {
                                                                              width:
                                                                                "127",
                                                                              color:
                                                                                "primary",
                                                                              depressed:
                                                                                "",
                                                                              disabled:
                                                                                _vm.disableButton,
                                                                            },
                                                                            on: {
                                                                              click: function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.changeValues()
                                                                              },
                                                                            },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "Save"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                            ],
                                                            2
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "primary ml-3",
                                            depressed: "",
                                          },
                                          on: {
                                            click: _vm.openCampaignViewDialog,
                                          },
                                        },
                                        [_vm._v(" Add Manual Campaign ")]
                                      ),
                                      _c("CampaginViewDialog", {
                                        attrs: {
                                          campaignViewMetadata:
                                            _vm.campaignViewMetadata,
                                        },
                                        on: {
                                          emitNextStep: function ($event) {
                                            return _vm.openSearchTabDialog(
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                      _c("SearchTabDialog", {
                                        attrs: {
                                          searchTabMetadata:
                                            _vm.searchTabMetadata,
                                        },
                                        on: {
                                          emitBackStep:
                                            _vm.openCampaignViewDialog,
                                        },
                                      }),
                                      _c("SearchResultDialog", {
                                        attrs: {
                                          searchResultMetadata:
                                            _vm.searchResultMetadata,
                                        },
                                        on: {
                                          emitBackStep:
                                            _vm.openCampaignViewDialog,
                                        },
                                      }),
                                      _c("TodayTabDialog", {
                                        attrs: {
                                          todayTabMetadata:
                                            _vm.todayTabMetadata,
                                        },
                                        on: {
                                          emitBackStep:
                                            _vm.openCampaignViewDialog,
                                        },
                                      }),
                                      _c("ProductPageDialog", {
                                        attrs: {
                                          productPageMetadata:
                                            _vm.productPageMetadata,
                                        },
                                        on: {
                                          emitBackStep:
                                            _vm.openCampaignViewDialog,
                                        },
                                      }),
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              color: "primary ml-3",
                                              depressed: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openAppSetting()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("pages.appSettings")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ],
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            { staticClass: "position-relative" },
                            _vm._l(_vm.campGenList, function (item, index) {
                              return _c(
                                "v-col",
                                {
                                  key: item.templateId,
                                  staticClass: "  ",
                                  attrs: {
                                    cols: "12",
                                    xl: "4",
                                    lg: "6",
                                    md: "6",
                                    sm: "12",
                                  },
                                },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "campgen-card ",
                                      attrs: { ripple: false },
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          staticClass: "top-section",
                                          attrs: { elevation: "0" },
                                        },
                                        [
                                          _c(
                                            "v-card-actions",
                                            {
                                              staticClass:
                                                "d-flex justify-end pa-0 card-action",
                                            },
                                            [
                                              _vm.showFullCard == false &&
                                              item.progress < 100
                                                ? _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "mr-2 primarybase--text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.progress) +
                                                          "%"
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.showFullCard == false &&
                                              item.progress < 100
                                                ? _c("v-progress-circular", {
                                                    staticClass: "ma-0",
                                                    attrs: {
                                                      size: "25",
                                                      width: "3",
                                                      color: item.allDone
                                                        ? "primary"
                                                        : "primary",
                                                      value: item.progress,
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.showFullCard == true &&
                                              _vm.cardIndex != index &&
                                              item.progress < 100
                                                ? _c(
                                                    "label",
                                                    { staticClass: "mr-3" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.progress) +
                                                          "%"
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm.showFullCard == true &&
                                              _vm.cardIndex != index &&
                                              item.progress < 100
                                                ? _c("v-progress-circular", {
                                                    staticClass: "ma-0",
                                                    attrs: {
                                                      size: "25",
                                                      width: "3",
                                                      color: item.allDone
                                                        ? "primary"
                                                        : "primary",
                                                      value: item.progress,
                                                    },
                                                  })
                                                : _vm._e(),
                                              _c(
                                                "v-tooltip",
                                                {
                                                  attrs: { bottom: "" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function (ref) {
                                                          var on = ref.on
                                                          var attrs = ref.attrs
                                                          return [
                                                            _c(
                                                              "v-icon",
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "ml-2 icon-hover",
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                    },
                                                                    on: {
                                                                      click: function (
                                                                        $event
                                                                      ) {
                                                                        $event.stopPropagation()
                                                                        return _vm.askConfirm(
                                                                          item._id,
                                                                          1
                                                                        )
                                                                      },
                                                                    },
                                                                  },
                                                                  "v-icon",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _vm._v(
                                                                  "mdi-delete-outline"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [_c("span", [_vm._v("Delete")])]
                                              ),
                                              _vm.showFullCard == true &&
                                              _vm.cardIndex == index
                                                ? _c(
                                                    "v-tooltip",
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function (ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                _c(
                                                                  "v-icon",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        staticClass:
                                                                          "ml-0 icon-hover",
                                                                        attrs: {
                                                                          color:
                                                                            "primary",
                                                                        },
                                                                        on: {
                                                                          click: function (
                                                                            $event
                                                                          ) {
                                                                            _vm.showFullCard = false
                                                                          },
                                                                        },
                                                                      },
                                                                      "v-icon",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-close"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v("Close"),
                                                      ]),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "card-list cursor-pointer",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openShowFullCard(
                                                    index,
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item",
                                                { staticClass: "mb-0 pa-0" },
                                                [
                                                  _c(
                                                    "v-avatar",
                                                    {
                                                      staticClass: "mr-4",
                                                      attrs: {
                                                        width: "49px",
                                                        height: "49px",
                                                      },
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: item.appIcon,
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-list-item-content",
                                                    { staticClass: "py-0" },
                                                    [
                                                      !item.editTemplateName
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "v-list-item-title",
                                                                {
                                                                  staticClass:
                                                                    "h6--text mb-2 primarybase--text",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        item.templateName ||
                                                                          "Generated Campaign Group"
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "ml-2  primary--text",
                                                                      on: {
                                                                        click: function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.openEditTemplate(
                                                                            item
                                                                          )
                                                                        },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-pencil-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      item.editTemplateName
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex align-baseline flex-row camp-group-name",
                                                            },
                                                            [
                                                              item.editTemplateName
                                                                ? _c(
                                                                    "v-text-field",
                                                                    {
                                                                      staticClass:
                                                                        "max-275",
                                                                      attrs: {
                                                                        dense:
                                                                          "",
                                                                        placeholder:
                                                                          "Generated Campaign Group",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          item.tempTemplateName,
                                                                        callback: function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item,
                                                                            "tempTemplateName",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "item.tempTemplateName",
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "ml-2  primary--text",
                                                                  on: {
                                                                    click: function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.updateCampaignTemplate(
                                                                        item
                                                                      )
                                                                    },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-check"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-icon",
                                                                {
                                                                  staticClass:
                                                                    "ml-2 primary--text",
                                                                  on: {
                                                                    click: function (
                                                                      $event
                                                                    ) {
                                                                      item.editTemplateName = false
                                                                    },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "mdi-close"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "v-list-item-subtitle",
                                                        {
                                                          staticClass:
                                                            " subtitle--text-2 primarybase--text",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.appName
                                                                  .length > 25
                                                                  ? item.appName
                                                                      .substring(
                                                                        0,
                                                                        25
                                                                      )
                                                                      .concat(
                                                                        ".."
                                                                      )
                                                                  : item.appName
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass:
                                                    "mt-6 pa-0 d-flex justify-space-between",
                                                  attrs: { elevation: "0" },
                                                },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-start",
                                                      attrs: {
                                                        cols: "12",
                                                        lg: "6",
                                                        md: "6",
                                                        sm: "6",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-card",
                                                        {
                                                          staticClass:
                                                            "py-0 left-col",
                                                          attrs: {
                                                            elevation: "0",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-list",
                                                            {
                                                              staticClass:
                                                                "transparent",
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item",
                                                                {
                                                                  staticClass:
                                                                    "primarybase--text",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    {
                                                                      staticClass:
                                                                        "subtitle--text-2  ",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "createAR.cptBid"
                                                                            )
                                                                          ) +
                                                                          ": "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        bottom:
                                                                          "",
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function (
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              var attrs =
                                                                                ref.attrs
                                                                              return [
                                                                                _c(
                                                                                  "v-list-item-subtitle",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        staticClass:
                                                                                          "grey--text body--text-2",
                                                                                      },
                                                                                      "v-list-item-subtitle",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _vm._v(
                                                                                      " $" +
                                                                                        _vm._s(
                                                                                          item
                                                                                            .cpt
                                                                                            .amount
                                                                                        ) +
                                                                                        " " +
                                                                                        _vm._s(
                                                                                          item
                                                                                            .cpt
                                                                                            .currency
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " $" +
                                                                              _vm._s(
                                                                                item
                                                                                  .cpt
                                                                                  .amount
                                                                              ) +
                                                                              " " +
                                                                              _vm._s(
                                                                                item
                                                                                  .cpt
                                                                                  .currency
                                                                              )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-list-item",
                                                                {
                                                                  staticClass:
                                                                    "primarybase--text",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    {
                                                                      staticClass:
                                                                        "subtitle--text-2  ",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "createCampaign.budget"
                                                                            )
                                                                          ) +
                                                                          ": "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        bottom:
                                                                          "",
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function (
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              var attrs =
                                                                                ref.attrs
                                                                              return [
                                                                                _c(
                                                                                  "v-list-item-subtitle",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        staticClass:
                                                                                          "grey--text body--text-2",
                                                                                      },
                                                                                      "v-list-item-subtitle",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _vm._v(
                                                                                      " $" +
                                                                                        _vm._s(
                                                                                          item
                                                                                            .totalBudget
                                                                                            .amount
                                                                                        ) +
                                                                                        " " +
                                                                                        _vm._s(
                                                                                          item
                                                                                            .totalBudget
                                                                                            .currency
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " $" +
                                                                              _vm._s(
                                                                                item
                                                                                  .totalBudget
                                                                                  .amount
                                                                              ) +
                                                                              " " +
                                                                              _vm._s(
                                                                                item
                                                                                  .totalBudget
                                                                                  .currency
                                                                              )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-list-item",
                                                                {
                                                                  staticClass:
                                                                    "primarybase--text",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    {
                                                                      staticClass:
                                                                        "subtitle--text-2  ",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "campaignHeaders.dailyCap"
                                                                            )
                                                                          ) +
                                                                          ": "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        bottom:
                                                                          "",
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function (
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              var attrs =
                                                                                ref.attrs
                                                                              return [
                                                                                _c(
                                                                                  "v-list-item-subtitle",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        staticClass:
                                                                                          "grey--text body--text-2",
                                                                                      },
                                                                                      "v-list-item-subtitle",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _vm._v(
                                                                                      " $" +
                                                                                        _vm._s(
                                                                                          item
                                                                                            .dailyCap
                                                                                            .amount
                                                                                        ) +
                                                                                        " " +
                                                                                        _vm._s(
                                                                                          item
                                                                                            .dailyCap
                                                                                            .currency
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " $" +
                                                                              _vm._s(
                                                                                item
                                                                                  .dailyCap
                                                                                  .amount
                                                                              ) +
                                                                              " " +
                                                                              _vm._s(
                                                                                item
                                                                                  .dailyCap
                                                                                  .currency
                                                                              )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-list-item",
                                                                {
                                                                  staticClass:
                                                                    "primarybase--text",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    {
                                                                      staticClass:
                                                                        "subtitle--text-2  ",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "campaignGenerator.CreatedBy"
                                                                            )
                                                                          ) +
                                                                          ":"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        bottom:
                                                                          "",
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function (
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              var attrs =
                                                                                ref.attrs
                                                                              return [
                                                                                _c(
                                                                                  "v-list-item-subtitle",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        staticClass:
                                                                                          "grey--text body--text-2",
                                                                                      },
                                                                                      "v-list-item-subtitle",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          item.createdBy
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item.createdBy
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-end",
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                        lg: "6",
                                                        sm: "6",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "v-card",
                                                        {
                                                          staticClass:
                                                            "py-0 right-col",
                                                          attrs: {
                                                            elevation: "0",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-list",
                                                            {
                                                              staticClass:
                                                                "transparent",
                                                            },
                                                            [
                                                              _c(
                                                                "v-list-item",
                                                                {
                                                                  staticClass:
                                                                    "primarybase--text",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    {
                                                                      staticClass:
                                                                        "subtitle--text-2  ",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "overview.gender"
                                                                            )
                                                                          ) +
                                                                          ": "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        bottom:
                                                                          "",
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function (
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              var attrs =
                                                                                ref.attrs
                                                                              return [
                                                                                _c(
                                                                                  "v-list-item-subtitle",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        staticClass:
                                                                                          "grey--text body--text-2",
                                                                                      },
                                                                                      "v-list-item-subtitle",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  ),
                                                                                  _vm._l(
                                                                                    item
                                                                                      .segmentationDetails
                                                                                      .selectedGender,
                                                                                    function (
                                                                                      g,
                                                                                      i
                                                                                    ) {
                                                                                      return _c(
                                                                                        "span",
                                                                                        {},
                                                                                        [
                                                                                          g ===
                                                                                          "Male"
                                                                                            ? [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    _vm.$t(
                                                                                                      "createCampaign.male"
                                                                                                    )
                                                                                                  )
                                                                                                ),
                                                                                              ]
                                                                                            : _vm._e(),
                                                                                          g ===
                                                                                          "Female"
                                                                                            ? [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    _vm.$t(
                                                                                                      "createCampaign.female"
                                                                                                    )
                                                                                                  )
                                                                                                ),
                                                                                              ]
                                                                                            : _vm._e(),
                                                                                          i !==
                                                                                          item
                                                                                            .segmentationDetails
                                                                                            .selectedGender
                                                                                            .length -
                                                                                            1
                                                                                            ? [
                                                                                                _vm._v(
                                                                                                  ","
                                                                                                ),
                                                                                              ]
                                                                                            : _vm._e(),
                                                                                        ],
                                                                                        2
                                                                                      )
                                                                                    }
                                                                                  ),
                                                                                  0
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        _vm._l(
                                                                          item
                                                                            .segmentationDetails
                                                                            .selectedGender,
                                                                          function (
                                                                            g,
                                                                            i
                                                                          ) {
                                                                            return _c(
                                                                              "span",
                                                                              {},
                                                                              [
                                                                                g ===
                                                                                "Male"
                                                                                  ? [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "createCampaign.male"
                                                                                          )
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  : _vm._e(),
                                                                                g ===
                                                                                "Female"
                                                                                  ? [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "createCampaign.female"
                                                                                          )
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  : _vm._e(),
                                                                                i !==
                                                                                item
                                                                                  .segmentationDetails
                                                                                  .selectedGender
                                                                                  .length -
                                                                                  1
                                                                                  ? [
                                                                                      _vm._v(
                                                                                        ","
                                                                                      ),
                                                                                    ]
                                                                                  : _vm._e(),
                                                                              ],
                                                                              2
                                                                            )
                                                                          }
                                                                        ),
                                                                        0
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-list-item",
                                                                {
                                                                  staticClass:
                                                                    "primarybase--text",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    {
                                                                      staticClass:
                                                                        "subtitle--text-2  ",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "campaignGenerator.age"
                                                                            )
                                                                          ) +
                                                                          ": "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        bottom:
                                                                          "",
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function (
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              var attrs =
                                                                                ref.attrs
                                                                              return [
                                                                                _c(
                                                                                  "v-list-item-subtitle",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        staticClass:
                                                                                          "grey--text body--text-2",
                                                                                      },
                                                                                      "v-list-item-subtitle",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _vm._l(
                                                                                      item
                                                                                        .segmentationDetails
                                                                                        .selectedAge,
                                                                                      function (
                                                                                        age
                                                                                      ) {
                                                                                        return [
                                                                                          _c(
                                                                                            "span",
                                                                                            {},
                                                                                            [
                                                                                              _vm._v(
                                                                                                "[" +
                                                                                                  _vm._s(
                                                                                                    age
                                                                                                      .toString()
                                                                                                      .replaceAll(
                                                                                                        ",",
                                                                                                        "-"
                                                                                                      )
                                                                                                  ) +
                                                                                                  "] "
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      }
                                                                                    ),
                                                                                  ],
                                                                                  2
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._l(
                                                                            item
                                                                              .segmentationDetails
                                                                              .selectedAge,
                                                                            function (
                                                                              age
                                                                            ) {
                                                                              return [
                                                                                _c(
                                                                                  "span",
                                                                                  {},
                                                                                  [
                                                                                    _vm._v(
                                                                                      "[" +
                                                                                        _vm._s(
                                                                                          age
                                                                                            .toString()
                                                                                            .replaceAll(
                                                                                              ",",
                                                                                              "-"
                                                                                            )
                                                                                        ) +
                                                                                        "] "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            }
                                                                          ),
                                                                        ],
                                                                        2
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-list-item",
                                                                {
                                                                  staticClass:
                                                                    "primarybase--text",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    {
                                                                      staticClass:
                                                                        "subtitle--text-2  ",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "overview.device"
                                                                            )
                                                                          ) +
                                                                          ": "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        bottom:
                                                                          "",
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function (
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              var attrs =
                                                                                ref.attrs
                                                                              return [
                                                                                _c(
                                                                                  "v-list-item-subtitle",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        staticClass:
                                                                                          "grey--text body--text-2",
                                                                                      },
                                                                                      "v-list-item-subtitle",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          item.segmentationDetails.selectedDevice
                                                                                            .join()
                                                                                            .replaceAll(
                                                                                              "I",
                                                                                              "I-"
                                                                                            )
                                                                                            .replaceAll(
                                                                                              ",",
                                                                                              ", "
                                                                                            )
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                item.segmentationDetails.selectedDevice
                                                                                  .join()
                                                                                  .replaceAll(
                                                                                    "I",
                                                                                    "I-"
                                                                                  )
                                                                                  .replaceAll(
                                                                                    ",",
                                                                                    ", "
                                                                                  )
                                                                              )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-list-item",
                                                                {
                                                                  staticClass:
                                                                    "primarybase--text",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-title",
                                                                    {
                                                                      staticClass:
                                                                        "subtitle--text-2  ",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "campaignGenerator.geo"
                                                                            )
                                                                          ) +
                                                                          ": "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        bottom:
                                                                          "",
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function (
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              var attrs =
                                                                                ref.attrs
                                                                              return [
                                                                                _c(
                                                                                  "v-list-item-subtitle",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        staticClass:
                                                                                          "grey--text body--text-2",
                                                                                      },
                                                                                      "v-list-item-subtitle",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _vm._v(
                                                                                      " " +
                                                                                        _vm._s(
                                                                                          item
                                                                                            .segmentationDetails
                                                                                            .selectedCountries
                                                                                        ) +
                                                                                        " "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              item
                                                                                .segmentationDetails
                                                                                .selectedCountries
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.showFullCard == true &&
                                      _vm.cardIndex == index
                                        ? _c(
                                            "v-card",
                                            {
                                              staticClass: "bottom-section",
                                              attrs: {
                                                elevation: "0",
                                                id: "div" + index,
                                              },
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { staticClass: " " },
                                                [
                                                  _c(
                                                    "v-card",
                                                    {
                                                      staticClass: "w-100",
                                                      attrs: { elevation: "0" },
                                                    },
                                                    [
                                                      _c(
                                                        "v-tabs",
                                                        {
                                                          staticClass:
                                                            "camp-gen-tab",
                                                          attrs: {
                                                            "background-color":
                                                              "transparent",
                                                            "center-active": "",
                                                            height: "85px",
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.detailsTab,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.detailsTab = $$v
                                                            },
                                                            expression:
                                                              "detailsTab",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.campTabList,
                                                          function (item) {
                                                            return _c(
                                                              "v-tab",
                                                              {
                                                                key: item.id,
                                                                staticClass:
                                                                  "h-100 py-2 pb-0 d-flex align-center flex-column",
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "secondarybase--text primarylight h6--text table-count",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.count
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "my-2  primarybase--text subtitle--text-2 ",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.tab
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                      _c(
                                                        "v-tabs-items",
                                                        {
                                                          model: {
                                                            value:
                                                              _vm.detailsTab,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.detailsTab = $$v
                                                            },
                                                            expression:
                                                              "detailsTab",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.campTabList,
                                                          function (item) {
                                                            return _c(
                                                              "v-tab-item",
                                                              { key: item.id },
                                                              [
                                                                _c(
                                                                  "v-data-table",
                                                                  {
                                                                    class:
                                                                      item.id ==
                                                                      3
                                                                        ? "keyword-tab elevation-0 pt-0 px-4 campgen-table"
                                                                        : "elevation-0 pt-0 px-4 campgen-table",
                                                                    attrs: {
                                                                      headers:
                                                                        item.id ===
                                                                        3
                                                                          ? _vm.tabHeadersWithBidAmount
                                                                          : _vm.tabHeaders,
                                                                      items:
                                                                        item.content,
                                                                      "items-per-page": 5,
                                                                      search:
                                                                        _vm.tabSearch,
                                                                      "footer-props": _vm.$t(
                                                                        "campaignGenerator.footerProps"
                                                                      ),
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "item.type",
                                                                          fn: function (
                                                                            ref
                                                                          ) {
                                                                            var item =
                                                                              ref.item
                                                                            return [
                                                                              item.type ===
                                                                              "Brand"
                                                                                ? [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "campaignGenerator.Brand"
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                : item.type ===
                                                                                  "Category"
                                                                                ? [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "campaignGenerator.Category"
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                : item.type ===
                                                                                  "Discovery"
                                                                                ? [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "campaignGenerator.Discovery"
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                : item.type ===
                                                                                  "Competitor"
                                                                                ? [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "campaignGenerator.Competitor"
                                                                                        )
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                : [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "campaignGenerator.custom"
                                                                                        )
                                                                                      ) +
                                                                                        " - " +
                                                                                        _vm._s(
                                                                                          item.type
                                                                                        )
                                                                                    ),
                                                                                  ],
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-card",
                                                    {
                                                      staticClass:
                                                        " my-2 w-100 d-flex align-end justify-space-between pr-6",
                                                      attrs: { elevation: "0" },
                                                    },
                                                    [
                                                      _c(
                                                        "v-timeline",
                                                        {
                                                          staticClass: "pt-0",
                                                          attrs: { dense: "" },
                                                        },
                                                        [
                                                          _vm._l(
                                                            item.categories,
                                                            function (
                                                              message,
                                                              i
                                                            ) {
                                                              return message !==
                                                                "Category" &&
                                                                message !==
                                                                  "Competitor"
                                                                ? _c(
                                                                    "v-timeline-item",
                                                                    {
                                                                      key: i,
                                                                      attrs: {
                                                                        color:
                                                                          "primarylight",
                                                                        icon:
                                                                          "mdi-checkbox-marked-circle",
                                                                        small:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex align-center ",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticClass:
                                                                                "mr-7 ",
                                                                              attrs: {
                                                                                color:
                                                                                  "primarylight",
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-checkbox-marked-circle-outline"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          message ===
                                                                          "Brand"
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "timeline-text  body--text-2 primarybase--text",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "campaignGenerator.Brand"
                                                                                        )
                                                                                      ) +
                                                                                      " " +
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "campaignGenerator.keysReady"
                                                                                        )
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          message ===
                                                                          "Discovery"
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "timeline-text  body--text-2 primarybase--text",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "campaignGenerator.Discovery"
                                                                                        )
                                                                                      ) +
                                                                                      " " +
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "campaignGenerator.keysReady"
                                                                                        )
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                          message !==
                                                                            "Brand" &&
                                                                          message !==
                                                                            "Discovery"
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "timeline-text  body--text-2 primarybase--text",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        message
                                                                                      ) +
                                                                                      " " +
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "campaignGenerator.custom"
                                                                                        )
                                                                                      ) +
                                                                                      " " +
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "campaignGenerator.keysReady"
                                                                                        )
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            }
                                                          ),
                                                          _vm._l(
                                                            item.categories,
                                                            function (
                                                              message,
                                                              i
                                                            ) {
                                                              return message ===
                                                                "Category"
                                                                ? _c(
                                                                    "v-timeline-item",
                                                                    {
                                                                      key: i,
                                                                      attrs: {
                                                                        color: item.categoryKeysFetched
                                                                          ? "primary"
                                                                          : "#aeb0b2",
                                                                        small:
                                                                          "",
                                                                        icon:
                                                                          "mdi-checkbox-marked-circle",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex align-center ",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticClass:
                                                                                "mr-7 ",
                                                                              attrs: {
                                                                                color: item.categoryKeysFetched
                                                                                  ? "primarylight"
                                                                                  : "#aeb0b2",
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-checkbox-marked-circle-outline"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          item.categoryKeysFetched
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "timeline-text  body--text-2 primarybase--text",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "campaignGenerator.Category"
                                                                                        )
                                                                                      ) +
                                                                                      " " +
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "campaignGenerator.keysReady"
                                                                                        )
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "timeline-text  body--text-2 primarybase--text",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "campaignGenerator.keysFetch"
                                                                                        )
                                                                                      ) +
                                                                                      " " +
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "campaignGenerator.Category"
                                                                                        )
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            }
                                                          ),
                                                          _vm._l(
                                                            item.categories,
                                                            function (
                                                              message,
                                                              i
                                                            ) {
                                                              return message ===
                                                                "Competitor"
                                                                ? _c(
                                                                    "v-timeline-item",
                                                                    {
                                                                      key: i,
                                                                      attrs: {
                                                                        color:
                                                                          item.competitorKeysFetched &&
                                                                          item.categoryKeysFetched
                                                                            ? "primary"
                                                                            : "#aeb0b2",
                                                                        small:
                                                                          "",
                                                                        icon:
                                                                          "mdi-checkbox-marked-circle",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "d-flex align-center ",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            {
                                                                              staticClass:
                                                                                "mr-7 ",
                                                                              attrs: {
                                                                                color:
                                                                                  item.competitorKeysFetched &&
                                                                                  item.categoryKeysFetched
                                                                                    ? "primarylight"
                                                                                    : "#aeb0b2",
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "mdi-checkbox-marked-circle-outline"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          item.competitorKeysFetched &&
                                                                          item.categoryKeysFetched
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "timeline-text  body--text-2 primarybase--text",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "campaignGenerator.Competitor"
                                                                                        )
                                                                                      ) +
                                                                                      " " +
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "campaignGenerator.keysReady"
                                                                                        )
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "timeline-text  body--text-2 primarybase--text",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "campaignGenerator.keysFetch"
                                                                                        )
                                                                                      ) +
                                                                                      " " +
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "campaignGenerator.Competitor"
                                                                                        )
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            }
                                                          ),
                                                          _c(
                                                            "v-timeline-item",
                                                            {
                                                              attrs: {
                                                                color: item.campaignsCreated
                                                                  ? "primary"
                                                                  : "#aeb0b2",
                                                                small: "",
                                                                icon:
                                                                  "mdi-checkbox-marked-circle",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex align-center ",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "mr-7 ",
                                                                      attrs: {
                                                                        color: item.campaignsCreated
                                                                          ? "primarylight"
                                                                          : "#aeb0b2",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-checkbox-marked-circle-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  item.campaignsCreated
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "timeline-text  body--text-2 primarybase--text",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "campaignGenerator.Campaignsarecreated"
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "timeline-text  body--text-2 primarybase--text",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "campaignGenerator.Creatingcampaigns"
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-timeline-item",
                                                            {
                                                              attrs: {
                                                                color: item.adGroupsCreated
                                                                  ? "primary"
                                                                  : "#aeb0b2",
                                                                small: "",
                                                                icon:
                                                                  "mdi-checkbox-marked-circle",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex align-center ",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "mr-7 ",
                                                                      attrs: {
                                                                        color: item.adGroupsCreated
                                                                          ? "primarylight"
                                                                          : "#aeb0b2",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-checkbox-marked-circle-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  item.adGroupsCreated
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "timeline-text  body--text-2 primarybase--text",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "campaignGenerator.Adgroupsarecreated"
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "timeline-text  body--text-2 primarybase--text",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "campaignGenerator.Creatingadgroups"
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                  _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        bottom:
                                                                          "",
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function (
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              var attrs =
                                                                                ref.attrs
                                                                              return [
                                                                                !item.adGroupsCreated
                                                                                  ? _c(
                                                                                      "v-icon",
                                                                                      _vm._g(
                                                                                        _vm._b(
                                                                                          {
                                                                                            staticClass:
                                                                                              "ml-2",
                                                                                            attrs: {
                                                                                              color:
                                                                                                "primarylight",
                                                                                              disabled:
                                                                                                Date.now() -
                                                                                                  item.lastRetryAt <
                                                                                                _vm.retryAfter,
                                                                                            },
                                                                                            on: {
                                                                                              click: function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.adKeyRetry(
                                                                                                  item.templateId
                                                                                                )
                                                                                              },
                                                                                            },
                                                                                          },
                                                                                          "v-icon",
                                                                                          attrs,
                                                                                          false
                                                                                        ),
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _vm._v(
                                                                                          "mdi-refresh "
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Refresh"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "v-timeline-item",
                                                            {
                                                              attrs: {
                                                                color: item.allDone
                                                                  ? "primary"
                                                                  : "#aeb0b2",
                                                                small: "",
                                                                icon:
                                                                  "mdi-checkbox-marked-circle-outline",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex align-center ",
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      staticClass:
                                                                        "mr-7 ",
                                                                      attrs: {
                                                                        color: item.allDone
                                                                          ? "primarylight"
                                                                          : "#aeb0b2",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-checkbox-marked-circle-outline"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  item.allDone
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "timeline-text  body--text-2 primarybase--text",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "campaignGenerator.Keywordsarecreated"
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "timeline-text  body--text-2 primarybase--text",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "campaignGenerator.Creatingkeywords"
                                                                                )
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                  !item.allDone
                                                                    ? _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "ml-2",
                                                                              attrs: {
                                                                                color:
                                                                                  "primarylight",
                                                                                disabled:
                                                                                  Date.now() -
                                                                                    item.lastRetryAt <
                                                                                  _vm.retryAfter,
                                                                              },
                                                                              on: {
                                                                                click: function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.adKeyRetry(
                                                                                    item.templateId
                                                                                  )
                                                                                },
                                                                              },
                                                                            },
                                                                            "v-icon",
                                                                            _vm.attrs,
                                                                            false
                                                                          ),
                                                                          _vm.on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-refresh "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                      _c(
                                                        "v-card-text",
                                                        {
                                                          staticClass:
                                                            "d-flex flex-column align-center w-fit-content",
                                                        },
                                                        [
                                                          _c(
                                                            "v-progress-circular",
                                                            {
                                                              staticClass:
                                                                " ma-0 primary--text",
                                                              attrs: {
                                                                rotate: "360",
                                                                size: "64",
                                                                width: "6",
                                                                color: item.allDone
                                                                  ? "primary"
                                                                  : "primary",
                                                                value:
                                                                  item.progress,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.progress
                                                                ) + "% "
                                                              ),
                                                            ]
                                                          ),
                                                          !item.campaignsCreated
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "my-2 subtitle--text-2  primary--text",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Syncing "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          item.campaignsCreated &&
                                                          !item.allDone
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "my-2 body--text-1 primary--text",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "campaignGenerator.syncInProgress"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                  _c(
                                                                    "v-tooltip",
                                                                    {
                                                                      attrs: {
                                                                        bottom:
                                                                          "",
                                                                        "max-width":
                                                                          "300px",
                                                                        color:
                                                                          "primarydark",
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function (
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              var attrs =
                                                                                ref.attrs
                                                                              return [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        staticClass:
                                                                                          "ml-2 greyxdark--text",
                                                                                        attrs: {
                                                                                          small:
                                                                                            "",
                                                                                        },
                                                                                      },
                                                                                      "v-icon",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _vm._v(
                                                                                      "mdi-help-circle-outline"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "campaignGenerator.syncInProgressFewMin"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                          item.allDone
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "my-2 body--text-1 primary--text",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "campaignGenerator.syncComplete"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
              ],
              2
            ),
            _c("AppSettingPopup", {
              attrs: { appSettingData: _vm.appSettingData },
            }),
            _c("DecisionAlert", {
              attrs: {
                DecisionAlert: _vm.decisionAlertFlag,
                DecisionAlertTitle: _vm.decisionAlertTitle,
                DecisionAlertMessage: _vm.decisionAlertMessage,
                DecisionAlertIcon: _vm.decisionAlertIcon,
                DecisionAlertData: _vm.decisionAlertData,
              },
              on: {
                emitDecisionAlertCancelClicked: _vm.clearDecisionModel,
                emitDecisionAlertOkClicked: function ($event) {
                  return _vm.decisionOkAction($event)
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "1400px", persistent: "" },
          model: {
            value: _vm.showDetails,
            callback: function ($$v) {
              _vm.showDetails = $$v
            },
            expression: "showDetails",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "v-modal-fixed style-2 overflow-x-hidden" },
            [
              _c(
                "v-card-title",
                {
                  staticClass:
                    "headline sticky-header v-modal-header d-flex justify-space-between px-9 py-3",
                },
                [
                  _c("h2", { staticClass: "headline font-weight-medium" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("campaignGenerator.Campaigngenerationdetails")
                        ) +
                        " "
                    ),
                  ]),
                  _c(
                    "v-icon",
                    {
                      staticClass: "black--text",
                      on: {
                        click: function ($event) {
                          _vm.showDetails = false
                        },
                      },
                    },
                    [_vm._v("mdi-close")]
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "pt-4 pb-0" },
                [
                  [
                    _c(
                      "v-card",
                      { staticClass: "rounded", attrs: { elevation: "0" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "campaignLists card mt-0 py-5 br-0",
                            attrs: { elevation: "0", width: "100%" },
                          },
                          [
                            _c(
                              "v-card-text",
                              { staticClass: "pa-0" },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticStyle: { "flex-basis": "unset" },
                                        attrs: {
                                          xl: "5",
                                          lg: "5",
                                          md: "6",
                                          sm: "12",
                                          xs: "12",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "list-header-col d-flex",
                                            staticStyle: {
                                              "padding-left": "1rem",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex justify-space-between",
                                              },
                                              [
                                                _c("v-img", {
                                                  staticClass: "ma-0 mr-2",
                                                  attrs: {
                                                    "max-width": "50",
                                                    "max-height": "50",
                                                    src: _vm.details.appIcon,
                                                  },
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-column",
                                                  },
                                                  [
                                                    _c(
                                                      "v-card-title",
                                                      {
                                                        staticClass:
                                                          "text-h5 pa-0 ",
                                                      },
                                                      [
                                                        _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: {
                                                              bottom: "",
                                                              "max-width":
                                                                "300px",
                                                              color:
                                                                "primarybase",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function (
                                                                    ref
                                                                  ) {
                                                                    var on =
                                                                      ref.on
                                                                    var attrs =
                                                                      ref.attrs
                                                                    return [
                                                                      _c(
                                                                        "p",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "text-h5 font-weight-bold text--primary truncate",
                                                                            },
                                                                            "p",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm
                                                                                  .details
                                                                                  .appName
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ]
                                                            ),
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.details
                                                                      .appName
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-card-subtitle",
                                                      {
                                                        staticClass:
                                                          "title-1 pa-0",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "createAR.cptBid"
                                                            )
                                                          ) +
                                                            " : $" +
                                                            _vm._s(
                                                              _vm.details.cpt
                                                                .amount
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.details.cpt
                                                                .currency
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-card-subtitle",
                                                      {
                                                        staticClass:
                                                          "title-1 pa-0",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "editCampaign.budget"
                                                            )
                                                          ) +
                                                            " : $" +
                                                            _vm._s(
                                                              _vm.details
                                                                .totalBudget
                                                                .amount
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.details
                                                                .totalBudget
                                                                .currency
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "v-card-subtitle",
                                                      {
                                                        staticClass:
                                                          "title-1 pa-0",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "editCampaign.dailyCap"
                                                            )
                                                          ) +
                                                            " : $" +
                                                            _vm._s(
                                                              _vm.details
                                                                .dailyCap.amount
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.details
                                                                .dailyCap
                                                                .currency
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: " " },
                                                      [
                                                        !_vm.details
                                                          .campaignsCreated
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "primary--text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "campaignGenerator.waitingToSync"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                                _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      bottom:
                                                                        "",
                                                                      "max-width":
                                                                        "300px",
                                                                      color:
                                                                        "primarydark",
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "activator",
                                                                          fn: function (
                                                                            ref
                                                                          ) {
                                                                            var on =
                                                                              ref.on
                                                                            var attrs =
                                                                              ref.attrs
                                                                            return [
                                                                              _c(
                                                                                "v-icon",
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      staticClass:
                                                                                        "ml-2 greyxdark--text",
                                                                                      attrs: {
                                                                                        small:
                                                                                          "",
                                                                                      },
                                                                                    },
                                                                                    "v-icon",
                                                                                    attrs,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-help-circle-outline"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      false,
                                                                      1456260294
                                                                    ),
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "campaignGenerator.campSyncHour"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.details
                                                          .campaignsCreated &&
                                                        !_vm.details.allDone
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "primary--text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "campaignGenerator.syncInProgress"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                                _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      bottom:
                                                                        "",
                                                                      "max-width":
                                                                        "300px",
                                                                      color:
                                                                        "primarydark",
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "activator",
                                                                          fn: function (
                                                                            ref
                                                                          ) {
                                                                            var on =
                                                                              ref.on
                                                                            var attrs =
                                                                              ref.attrs
                                                                            return [
                                                                              _c(
                                                                                "v-icon",
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      staticClass:
                                                                                        "ml-2 greyxdark--text",
                                                                                      attrs: {
                                                                                        small:
                                                                                          "",
                                                                                      },
                                                                                    },
                                                                                    "v-icon",
                                                                                    attrs,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-help-circle-outline"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      false,
                                                                      1456260294
                                                                    ),
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "campaignGenerator.syncInProgressFewMin"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        _vm.details.allDone
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "primary--text",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "campaignGenerator.syncComplete"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                                _c(
                                                                  "v-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      bottom:
                                                                        "",
                                                                      "max-width":
                                                                        "300px",
                                                                      color:
                                                                        "primarydark",
                                                                    },
                                                                    scopedSlots: _vm._u(
                                                                      [
                                                                        {
                                                                          key:
                                                                            "activator",
                                                                          fn: function (
                                                                            ref
                                                                          ) {
                                                                            var on =
                                                                              ref.on
                                                                            var attrs =
                                                                              ref.attrs
                                                                            return [
                                                                              _c(
                                                                                "v-icon",
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      staticClass:
                                                                                        "ml-2 greyxdark--text",
                                                                                      attrs: {
                                                                                        small:
                                                                                          "",
                                                                                      },
                                                                                    },
                                                                                    "v-icon",
                                                                                    attrs,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _vm._v(
                                                                                    "mdi-help-circle-outline"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      false,
                                                                      1456260294
                                                                    ),
                                                                  },
                                                                  [
                                                                    _c("span", [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "campaignGenerator.allSyncCompleted"
                                                                          )
                                                                        )
                                                                      ),
                                                                    ]),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "progress-bar" },
                                              [
                                                _c(
                                                  "v-progress-circular",
                                                  {
                                                    staticClass: "my-0",
                                                    attrs: {
                                                      value:
                                                        _vm.details.progress,
                                                      size: 50,
                                                      width: 5,
                                                      color: _vm.details.allDone
                                                        ? "primary"
                                                        : "secondary",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.details.progress
                                                      ) + "% "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticStyle: { "flex-basis": "unset" },
                                        attrs: {
                                          xl: "2",
                                          lg: "2",
                                          md: "6",
                                          sm: "12",
                                          xs: "12",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "list-header-right" },
                                          [
                                            _c(
                                              "v-list",
                                              [
                                                _c(
                                                  "v-list-item",
                                                  {
                                                    staticStyle: {
                                                      padding:
                                                        "0px 0px 0px 16px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          width: "70px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "overview.gender"
                                                              )
                                                            ) +
                                                            ": "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._l(
                                                      _vm.details
                                                        .segmentationDetails
                                                        .selectedGender,
                                                      function (g, i) {
                                                        return _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "grey--text",
                                                          },
                                                          [
                                                            g === "Male"
                                                              ? [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "createCampaign.male"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              : _vm._e(),
                                                            g === "Female"
                                                              ? [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "createCampaign.female"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              : _vm._e(),
                                                            i !==
                                                            _vm.details
                                                              .segmentationDetails
                                                              .selectedGender
                                                              .length -
                                                              1
                                                              ? [_vm._v(",")]
                                                              : _vm._e(),
                                                          ],
                                                          2
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  {
                                                    staticStyle: {
                                                      padding:
                                                        "0px 0px 0px 16px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          width: "70px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "overview.device"
                                                              )
                                                            ) +
                                                            ": "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "grey--text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.details.segmentationDetails.selectedDevice
                                                              .join()
                                                              .replaceAll(
                                                                "I",
                                                                "I-"
                                                              )
                                                              .replaceAll(
                                                                ",",
                                                                ", "
                                                              )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  {
                                                    staticStyle: {
                                                      padding:
                                                        "0px 0px 0px 16px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          width: "70px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "campaignGenerator.geo"
                                                              )
                                                            ) +
                                                            ": "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "grey--text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.details
                                                              .segmentationDetails
                                                              .selectedCountries
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticStyle: { "flex-basis": "unset" },
                                        attrs: {
                                          xl: "3",
                                          lg: "3",
                                          md: "6",
                                          sm: "12",
                                          xs: "12",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "list-header-right" },
                                          [
                                            _c(
                                              "v-list",
                                              [
                                                _c(
                                                  "v-list-item",
                                                  {
                                                    staticStyle: {
                                                      padding:
                                                        "0px 0px 0px 16px",
                                                      "align-items": "start",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          width: "120px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "campaignGenerator.age"
                                                              )
                                                            ) +
                                                            ": "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._l(
                                                      _vm.details
                                                        .segmentationDetails
                                                        .selectedAge,
                                                      function (age) {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "grey--text",
                                                              staticStyle: {
                                                                "min-width":
                                                                  "fit-content",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "[" +
                                                                  _vm._s(
                                                                    age
                                                                      .toString()
                                                                      .replaceAll(
                                                                        ",",
                                                                        "-"
                                                                      )
                                                                  ) +
                                                                  "] "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  {
                                                    staticStyle: {
                                                      padding:
                                                        "0px 0px 0px 16px",
                                                      "align-items": "start",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          width: "140px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "labels.cusType"
                                                              )
                                                            ) +
                                                            ": "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "grey--text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.detailsCustTypes
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        staticStyle: { "flex-basis": "unset" },
                                        attrs: {
                                          xl: "2",
                                          lg: "2",
                                          md: "6",
                                          sm: "12",
                                          xs: "12",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "list-header-right" },
                                          [
                                            _c(
                                              "v-list",
                                              [
                                                _c(
                                                  "v-list-item",
                                                  {
                                                    staticStyle: {
                                                      padding:
                                                        "0px 0px 0px 16px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          width: "90px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "campaignGenerator.CreatedBy"
                                                              )
                                                            ) +
                                                            ": "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "grey--text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.details
                                                              .createdBy
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-list-item",
                                                  {
                                                    staticStyle: {
                                                      padding:
                                                        "0px 0px 0px 16px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          width: "90px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "campaignGenerator.CreatedOn"
                                                              )
                                                            ) +
                                                            ": "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "grey--text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.details
                                                              .createdOn
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-card-text",
                              { staticClass: "py-0 pt-10" },
                              [
                                _c(
                                  "v-row",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        staticClass: "pt-6",
                                        attrs: {
                                          xl: "3",
                                          lg: "3",
                                          md: "3",
                                          sm: "12",
                                          xs: "12",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-timeline",
                                          {
                                            staticClass: "pt-0",
                                            attrs: { dense: "" },
                                          },
                                          [
                                            _vm._l(
                                              _vm.details.categories,
                                              function (message, i) {
                                                return message !== "Category" &&
                                                  message !== "Competitor"
                                                  ? _c(
                                                      "v-timeline-item",
                                                      {
                                                        key: i,
                                                        attrs: {
                                                          color: "primary",
                                                          small: "",
                                                        },
                                                      },
                                                      [
                                                        _c("div", [
                                                          message === "Brand"
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "timeline-text ",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "campaignGenerator.Brand"
                                                                        )
                                                                      ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "campaignGenerator.keysReady"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          message ===
                                                          "Discovery"
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "timeline-text ",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "campaignGenerator.Discovery"
                                                                        )
                                                                      ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "campaignGenerator.keysReady"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                          message !== "Brand" &&
                                                          message !==
                                                            "Discovery"
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "timeline-text ",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        message
                                                                      ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "campaignGenerator.custom"
                                                                        )
                                                                      ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "campaignGenerator.keysReady"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _vm._e(),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e()
                                              }
                                            ),
                                            _vm._l(
                                              _vm.details.categories,
                                              function (message, i) {
                                                return message === "Category"
                                                  ? _c(
                                                      "v-timeline-item",
                                                      {
                                                        key: i,
                                                        attrs: {
                                                          color: _vm.details
                                                            .categoryKeysFetched
                                                            ? "primary"
                                                            : "#aeb0b2",
                                                          small: "",
                                                        },
                                                      },
                                                      [
                                                        _c("div", [
                                                          _vm.details
                                                            .categoryKeysFetched
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "timeline-text ",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "campaignGenerator.Category"
                                                                        )
                                                                      ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "campaignGenerator.keysReady"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "timeline-text ",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "campaignGenerator.keysFetch"
                                                                        )
                                                                      ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "campaignGenerator.Category"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e()
                                              }
                                            ),
                                            _vm._l(
                                              _vm.details.categories,
                                              function (message, i) {
                                                return message === "Competitor"
                                                  ? _c(
                                                      "v-timeline-item",
                                                      {
                                                        key: i,
                                                        attrs: {
                                                          color:
                                                            _vm.details
                                                              .competitorKeysFetched &&
                                                            _vm.details
                                                              .categoryKeysFetched
                                                              ? "primary"
                                                              : "#aeb0b2",
                                                          small: "",
                                                        },
                                                      },
                                                      [
                                                        _c("div", [
                                                          _vm.details
                                                            .competitorKeysFetched &&
                                                          _vm.details
                                                            .categoryKeysFetched
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "timeline-text ",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "campaignGenerator.Competitor"
                                                                        )
                                                                      ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "campaignGenerator.keysReady"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              )
                                                            : _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "timeline-text ",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "campaignGenerator.keysFetch"
                                                                        )
                                                                      ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "campaignGenerator.Competitor"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]
                                                              ),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e()
                                              }
                                            ),
                                            _c(
                                              "v-timeline-item",
                                              {
                                                attrs: {
                                                  color: _vm.details
                                                    .campaignsCreated
                                                    ? "primary"
                                                    : "#aeb0b2",
                                                  small: "",
                                                },
                                              },
                                              [
                                                _c("div", [
                                                  _vm.details.campaignsCreated
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "timeline-text ",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "campaignGenerator.Campaignsarecreated"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "timeline-text ",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "campaignGenerator.Creatingcampaigns"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                ]),
                                              ]
                                            ),
                                            _c(
                                              "v-timeline-item",
                                              {
                                                attrs: {
                                                  color: _vm.details
                                                    .adGroupsCreated
                                                    ? "primary"
                                                    : "#aeb0b2",
                                                  small: "",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                    },
                                                  },
                                                  [
                                                    _vm.details.adGroupsCreated
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "timeline-text ",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "campaignGenerator.Adgroupsarecreated"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "timeline-text ",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "campaignGenerator.Creatingadgroups"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "activator",
                                                            fn: function (ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                !_vm.details
                                                                  .adGroupsCreated
                                                                  ? _c(
                                                                      "v-icon",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            attrs: {
                                                                              disabled:
                                                                                Date.now() -
                                                                                  _vm
                                                                                    .details
                                                                                    .lastRetryAt <
                                                                                _vm.retryAfter,
                                                                            },
                                                                            on: {
                                                                              click: function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.adKeyRetry(
                                                                                  _vm
                                                                                    .details
                                                                                    .templateId
                                                                                )
                                                                              },
                                                                            },
                                                                          },
                                                                          "v-icon",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-refresh "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            },
                                                          },
                                                        ]),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Refresh"),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-timeline-item",
                                              {
                                                attrs: {
                                                  color: _vm.details.allDone
                                                    ? "primary"
                                                    : "#aeb0b2",
                                                  small: "",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                    },
                                                  },
                                                  [
                                                    _vm.details.allDone
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "timeline-text ",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "campaignGenerator.Keywordsarecreated"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      : _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "timeline-text ",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "campaignGenerator.Creatingkeywords"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                    _c(
                                                      "v-tooltip",
                                                      {
                                                        attrs: { bottom: "" },
                                                        scopedSlots: _vm._u([
                                                          {
                                                            key: "activator",
                                                            fn: function (ref) {
                                                              var on = ref.on
                                                              var attrs =
                                                                ref.attrs
                                                              return [
                                                                !_vm.details
                                                                  .allDone
                                                                  ? _c(
                                                                      "v-icon",
                                                                      _vm._g(
                                                                        _vm._b(
                                                                          {
                                                                            attrs: {
                                                                              disabled:
                                                                                Date.now() -
                                                                                  _vm
                                                                                    .details
                                                                                    .lastRetryAt <
                                                                                _vm.retryAfter,
                                                                            },
                                                                            on: {
                                                                              click: function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.adKeyRetry(
                                                                                  _vm
                                                                                    .details
                                                                                    .templateId
                                                                                )
                                                                              },
                                                                            },
                                                                          },
                                                                          "v-icon",
                                                                          attrs,
                                                                          false
                                                                        ),
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-refresh "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ]
                                                            },
                                                          },
                                                        ]),
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v("Refresh"),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "v-timeline-item",
                                              {
                                                attrs: {
                                                  color: _vm.details.allDone
                                                    ? "primary"
                                                    : "pink",
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "icon",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "v-progress-circular",
                                                          {
                                                            staticClass: "my-0",
                                                            attrs: {
                                                              value:
                                                                _vm.details
                                                                  .progress,
                                                              size: 100,
                                                              width: 1,
                                                              color: _vm.details
                                                                .allDone
                                                                ? "primary"
                                                                : "pink",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "i",
                                                              {
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "0.75rem",
                                                                  color:
                                                                    "white",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.details
                                                                      .progress
                                                                  ) + "%"
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ]),
                                              },
                                              [
                                                _c("div", [
                                                  _vm.details.allDone
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "timeline-text ",
                                                          staticStyle: {
                                                            "padding-top":
                                                              "0.5rem",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "campaignGenerator.ready"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "timeline-text ",
                                                          staticStyle: {
                                                            "padding-top":
                                                              "0.5rem",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "campaignGenerator.ready"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                ]),
                                              ]
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          xl: "9",
                                          lg: "9",
                                          md: "9",
                                          sm: "12",
                                          xs: "12",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex align-center camp-gen-table",
                                          },
                                          [
                                            [
                                              _c(
                                                "v-card",
                                                {
                                                  attrs: {
                                                    "min-width": "100%",
                                                    elevation: "0",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-tabs",
                                                    {
                                                      attrs: {
                                                        "background-color":
                                                          "transparent",
                                                        left: "",
                                                      },
                                                      model: {
                                                        value: _vm.detailsTab,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.detailsTab = $$v
                                                        },
                                                        expression:
                                                          "detailsTab",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.tabItems,
                                                      function (item) {
                                                        return _c(
                                                          "v-tab",
                                                          { key: item.id },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  item.tab
                                                                ) +
                                                                " "
                                                            ),
                                                            _c(
                                                              "v-avatar",
                                                              {
                                                                staticClass:
                                                                  "mx-3",
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                  size: "26",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "white--text caption",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.count
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                  _c(
                                                    "v-tabs-items",
                                                    {
                                                      model: {
                                                        value: _vm.detailsTab,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.detailsTab = $$v
                                                        },
                                                        expression:
                                                          "detailsTab",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.tabItems,
                                                      function (item) {
                                                        return _c(
                                                          "v-tab-item",
                                                          { key: item.id },
                                                          [
                                                            _c("v-data-table", {
                                                              attrs: {
                                                                headers:
                                                                  item.id === 3
                                                                    ? _vm.tabHeadersWithBidAmount
                                                                    : _vm.tabHeaders,
                                                                items:
                                                                  item.content,
                                                                "items-per-page": 5,
                                                                elevation: "0",
                                                                search:
                                                                  _vm.tabSearch,
                                                                "footer-props": _vm.$t(
                                                                  "campaignGenerator.footerProps"
                                                                ),
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "item.type",
                                                                    fn: function (
                                                                      ref
                                                                    ) {
                                                                      var item =
                                                                        ref.item
                                                                      return [
                                                                        item.type ===
                                                                        "Brand"
                                                                          ? [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "campaignGenerator.Brand"
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          : item.type ===
                                                                            "Category"
                                                                          ? [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "campaignGenerator.Category"
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          : item.type ===
                                                                            "Discovery"
                                                                          ? [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "campaignGenerator.Discovery"
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          : item.type ===
                                                                            "Competitor"
                                                                          ? [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "campaignGenerator.Competitor"
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          : [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "campaignGenerator.custom"
                                                                                  )
                                                                                ) +
                                                                                  " - " +
                                                                                  _vm._s(
                                                                                    item.type
                                                                                  )
                                                                              ),
                                                                            ],
                                                                      ]
                                                                    },
                                                                  },
                                                                ],
                                                                null,
                                                                true
                                                              ),
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                          ],
                                          2
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("PermissionReadOnlyPopup", {
        attrs: { permissionMetadata: _vm.permissionReadOnlyMetadata },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }