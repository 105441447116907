























































































  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-nocheck
import Vue from 'vue';
import { KEYWORD_RECOMENDS, APP_METADATA } from '../../utils/apptweakqueries';
import { COUNTRIES } from '../../utils/constants';

export default Vue.extend({
  name: 'AddKeys',
  data() {
    return {
      appId : null,
      appName : '',
      step : 1,
      first:'',
      searchedKeyword: '',
      recomendHeaders: [
          {
            text: 'Keyword',
            align: 'start',
            value: 'keyword',
          },
          { text: 'Popularity', value: 'volume' },
          {
            text: '',
            value: 'addKeywordPlus',
          },
      ],
      recomendItems: [],
      loadingRecomends:false,
      countryName: 'United States',
      countryCode: 'us',
      selectedAppIcon: '',
      selectedAppDev: '',

      matchTypes: ['EXACT', 'BROAD'],

      show: false,
      keywordInput: '',
      keywords: [],
      currency: 'USD',
    };
  },
  props: {
    addKeysData: {
      type: Object,
      required: true,
      default() {
        return;
      },
    },
  },
  watch: {
    addKeysData: function (data) {
      //console.log(data);
      this.show = true;
      this.appId = data.appId;
      this.currency = data.currency;
      this.appName = data.appName;
      this.countryName = this.getCountryName(data.countryCode);
      this.countryCode = data.countryCode;
      this.getApp();
    },
  },
  methods: {
    close() {
      this.show = false;
      this.keywordInput = '';
      this.keywords = [];
    },

    getCountryName(countryCode){
      const searchKey = { Code: countryCode };
      const countryName = COUNTRIES[COUNTRIES.map(function (item) { return item.Code; }).indexOf(searchKey.Code)];
      //console.log(countryName)
      return countryName.Name;
    },

    getAppDetails(){
      //console.log('getAppDetails');
    },

    addKeyword(): void {
      const keys = this.keywordInput.split(',');
      for (let index = 0; index < keys.length; index++) {
        const element = keys[index];
        //console.log(element);
        if (
          element &&
          !this.keywords.find((kw) => kw.text === element)
        ) {
          this.keywords.push({
            text: element,
            matchType: 'EXACT',
          });
        }
        this.keywordInput = '';
      }
    },

    deleteKeyword(keyword: string): void {
      this.keywords = this.keywords.filter((kw) => kw.text !== keyword);
    },

    save() {
      const keys = this.keywordInput.split(',');
      for (let index = 0; index < keys.length; index++) {
        const element = keys[index];
        //console.log(element);
        if (
          element &&
          !this.keywords.find((kw) => kw.text === element)
        ) {
          this.keywords.push({
            text: element,
            matchType: 'EXACT'
          });
        }
        this.keywordInput = '';
      }
      this.$emit('addKeysSaveClicked', this.keywords);
      this.close();
    },

    change() {
      this.$emit('addKeysCancelClicked');
      this.close();
    },

    getApp(){
      //console.log('getApp',this.appId, this.countryCode);
      this.loadingRecomends = true;
      // this.$apollo
      // .query({
      //   query: KEYWORD_RECOMENDS,
      //   variables: {
      //     appid: this.appId,
      //     country: this.countryCode.toLowerCase(),
      //     term: ""
      //   }
      // })
      // .then(res => {
      //   //console.log(res.data);
      //   this.loadingRecomends = false;
      //   this.recomendItems = res.data.KeywordRecomends.content?res.data.KeywordRecomends.content:[];
      // })
      // .catch(error => {
      //   //console.log(error);
      //   this.loadingRecomends = false;
      // });

      this.$apollo
      .query({
        query: APP_METADATA,
        variables: {
          appId : this.appId,
          location: this.countryCode.toLowerCase(),
          language: 'en',
          device: 'iphone',
          startDate: new Date().toISOString().substr(0, 10),
          endDate: new Date().toISOString().substr(0, 10),
        }
      })
      .then(data => {
        //console.log(data.data);
        if(data.data.getAppMetadata == null || data.data.getAppMetadata == undefined) {
          this.loadingKeys = false;
          return;
        }
        this.selectedAppIcon = data.data.getAppMetadata.content.icon;
        this.selectedAppDev = data.data.getAppMetadata.content.developer.name;
      })
      .catch(error => {
        //console.log(error);
        this.loadingKeys = false;
      });
    },

    addKeywordPlusClicked(item){
      //console.log('addKeywordPlusClicked',item);
      if (
        item.keyword &&
        !this.keywords.find((kw) => kw.text === item.keyword)
      ) {
        this.keywords.push({
          text: item.keyword,
          matchType: 'EXACT'
        });
      }
    },
  },

  mounted(){
    //this.getOwnApps();
  }
});
