





















































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { LSS } from '@core/services';
import Vue from 'vue';
export default Vue.extend({
  props: {
    campGenStepperDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      appIcon: '',
      dialog: false,
      item: null,
    };
  },
  watch: {
    campGenStepperDetails(data) {
        console.log(data)
      this.dialog = true;
      this.item = data.details;
      this.getAppIcon(data.details.adamId);
    },
  },
  methods: {
    getAppIcon(adamId) {
      console.log(adamId);
      const apps = LSS.ownApps;
      for (const app of apps) {
        if (app.adamId == adamId) {
          console.log(app.appIcon);
          this.appIcon = app.appIcon;
        }
      }
    },
  },
});
