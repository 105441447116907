var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "900" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "v-modal-fixed style-2" },
        [
          _c(
            "v-card-title",
            {
              staticClass:
                "headline d-flex justify-space-between px-5 py-3 v-modal-header",
            },
            [
              _c("h5", { staticClass: "text-h6" }, [
                _vm._v("Campaign Generator Stepper"),
              ]),
              _c(
                "v-icon",
                {
                  staticClass: "black--text",
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("mdi-close")]
              ),
            ],
            1
          ),
          _c(
            "v-card",
            {
              staticClass:
                "pa-4 rounded-lg outline d-flex flex-row align-center justify-space-between",
              attrs: { flat: "", elevation: "0" },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _c("v-img", {
                    staticClass: "ma-0",
                    attrs: { "max-width": "50", src: _vm.appIcon },
                  }),
                  _c(
                    "v-card-title",
                    {
                      staticClass:
                        "text-h5 pa-0 d-flex flex-column mx-3 align-start",
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-h5 font-weight-bold text--primary ma-0",
                        },
                        [_vm._v(" " + _vm._s(_vm.item.appName) + " ")]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "progress-bar" },
                [
                  _c(
                    "v-progress-circular",
                    {
                      attrs: {
                        value: _vm.item.progress,
                        size: 55,
                        width: 6,
                        color: _vm.item.allDone ? "#18438f" : "secondary",
                      },
                    },
                    [_vm._v(_vm._s(_vm.item.progress) + "% ")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "v-timeline",
            { staticClass: "pb-4", attrs: { "align-top": "", dense: "" } },
            [
              _vm._l(_vm.item.categories, function (message, i) {
                return message !== "Category" && message !== "Competitor"
                  ? _c(
                      "v-timeline-item",
                      { key: i, attrs: { color: "primary", small: "" } },
                      [
                        _c("div", [
                          message === "Brand" || message === "Discovery"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "timeline-text font-weight-normal",
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(message) + " keywords ready "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          message !== "Brand" && message !== "Discovery"
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "timeline-text font-weight-normal",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(message) +
                                      " custom keywords ready "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    )
                  : _vm._e()
              }),
              _vm._l(_vm.item.categories, function (message, i) {
                return message === "Category"
                  ? _c(
                      "v-timeline-item",
                      {
                        key: i,
                        attrs: {
                          color: _vm.item.categoryKeysFetched
                            ? "primary"
                            : "grey",
                          small: "",
                        },
                      },
                      [
                        _c("div", [
                          _vm.item.categoryKeysFetched
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "timeline-text font-weight-normal",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(message) +
                                      " keywords are ready "
                                  ),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "timeline-text font-weight-normal",
                                },
                                [
                                  _vm._v(
                                    " Fetching keywords for " +
                                      _vm._s(message) +
                                      " "
                                  ),
                                ]
                              ),
                        ]),
                      ]
                    )
                  : _vm._e()
              }),
              _vm._l(_vm.item.categories, function (message, i) {
                return message === "Competitor"
                  ? _c(
                      "v-timeline-item",
                      {
                        key: i,
                        attrs: {
                          color: _vm.item.competitorKeysFetched
                            ? "primary"
                            : "grey",
                          small: "",
                        },
                      },
                      [
                        _c("div", [
                          _vm.item.competitorKeysFetched
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "timeline-text font-weight-normal",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(message) +
                                      " keywords are ready "
                                  ),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "timeline-text font-weight-normal",
                                },
                                [
                                  _vm._v(
                                    " Fetching keywords for " +
                                      _vm._s(message) +
                                      " "
                                  ),
                                ]
                              ),
                        ]),
                      ]
                    )
                  : _vm._e()
              }),
              _c(
                "v-timeline-item",
                {
                  attrs: {
                    color: _vm.item.campaignsCreated ? "primary" : "grey",
                    small: "",
                  },
                },
                [
                  _c("div", [
                    _vm.item.campaignsCreated
                      ? _c(
                          "div",
                          { staticClass: "timeline-text font-weight-normal" },
                          [_vm._v(" Campaigns created ")]
                        )
                      : _c(
                          "div",
                          { staticClass: "timeline-text font-weight-normal" },
                          [_vm._v(" Creating campaigns ")]
                        ),
                  ]),
                ]
              ),
              _c(
                "v-timeline-item",
                {
                  attrs: {
                    color: _vm.item.adGroupsCreated ? "primary" : "grey",
                    small: "",
                  },
                },
                [
                  _c("div", [
                    _vm.item.adGroupsCreated
                      ? _c(
                          "div",
                          { staticClass: "timeline-text font-weight-normal" },
                          [_vm._v(" Adgroups created ")]
                        )
                      : _c(
                          "div",
                          { staticClass: "timeline-text font-weight-normal" },
                          [_vm._v(" Creating adgroups ")]
                        ),
                  ]),
                ]
              ),
              _c(
                "v-timeline-item",
                {
                  attrs: {
                    color: _vm.item.allDone ? "primary" : "grey",
                    small: "",
                  },
                },
                [
                  _c("div", [
                    _vm.item.allDone
                      ? _c(
                          "div",
                          { staticClass: "timeline-text font-weight-normal" },
                          [_vm._v(" Keywords created ")]
                        )
                      : _c(
                          "div",
                          { staticClass: "timeline-text font-weight-normal" },
                          [_vm._v(" Creating keywords ")]
                        ),
                  ]),
                ]
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }