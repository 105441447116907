







































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import { COUNTRIES } from '../utils/constants';
import {
  AutomateCampaignType,
  CriteriaOptions,
  CustomerTypes,
  Genders,
} from '../utils/enums';
import {
  GET_CAMPAIGN_GROUPS,
  APPS_BY_ORG,
  COUNTRIES_FOR_APP,
  CAMPAIGN_TEMPLATE_DRAFT_LIST,
  CAMPAIGN_TEMPLATE_DRAFT,
  CAMPAIGN_GEN_LIST,
  DELETE_TEMPLATE,
  CHECK_CAMPAIGN_NAME,
  AD_KEY_RETRY,
  UPDATE_CAMPAIGN_TEMPLATE,
} from '../utils/apollo-queries';
import { APP_METADATA } from 'src/utils/apptweakqueries';
import AddKeysForAutCamp from '../components/keywords/AddKeysForAutCamp.vue';
import CampGenStepper from '../components/campaign-generator/CampGenStepper.vue';
import {
  APP_SETTINGS_FOR_CAMPAIGN,
  DRAFT_TEMPLATE_FOR_CAMPAIGN,
  SAVE_TEMPLATE_FOR_CAMPAIGN,
} from 'src/utils/apollo-mutations';
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { LSS } from '@core/services';
import { decodeToken } from '@shared/utils/commom.util';
import AppSettingPopup from'../components/campaign-generator/AppSettingsPopup.vue';
import DecisionAlert from '../components/DecisionAlert.vue';
import * as _ from 'lodash';
import { KOCHAVA_BUDGET, KOCHAVA_COMPANY } from '@config';
import { updateCLF } from '../utils/util';
import CampaginViewDialog from '../components/campaign-generator/CampaignViewDialog.vue';
import SearchResultDialog from '../components/campaign-generator/SearchResultDialog.vue';
import SearchTabDialog from '../components/campaign-generator/SearchTabDailog.vue';
import ProductPageDialog from '../components/campaign-generator/ProductPageDialog.vue';
import TodayTabDialog from '../components/campaign-generator/TodayTabDailog.vue';
import { isReadOnly} from '../utils/util';
import PermissionReadOnlyPopup from './../components/PermissionReadOnlyPopup.vue';
export default Vue.extend({
  components: {
    AddKeysForAutCamp,
    CampGenStepper,
    AppSettingPopup,
    DecisionAlert,
    CampaginViewDialog,
   SearchTabDialog,
  SearchResultDialog,
   ProductPageDialog,
   TodayTabDialog,
    PermissionReadOnlyPopup
  },
  data() {
    return {
      permissionReadOnlyMetadata:{},
      showFullCard:false,
      cardIndex:99,
      abcccc: true,
      syncStatus: false,
      locale: 'en',
      retryAfter: 2700000,
      valid5: false,
      searchMain: '',
      decisionAlertFlag: false,
      decisionAlertTitle: this.$t('campaignGenerator.decisionMessage'),
      decisionAlertMessage: '',
      decisionAlertData: {},
      decisionAlertIcon: 1,
      cgDbId: '',
      scope: null,
      tabHeadersTemp: [],
      tabSearch: '',
      tabFilter: '',
      showDetails: false,
      detailsTab: 0,
      uid: 1234,
      message: null,
      details: {
        appName: '',
        cpt: {
          amount: '',
          currency: '',
        },
        totalBudget: {
          amount: '',
          currency: '',
        },
        dailyCap: {
          amount: '',
          currency: '',
        },
        segmentationDetails: {
          selectedGender: [],
          selectedAge: [],
          selectedDevice: [],
          selectedCountries: [],
          selectedCustomerType: [],
        },
        allDone: false,
        progress: 25,
        categories: [],
        segmentationRules: [],
        totalKeys: [],
        categoryKeysFetched: false,
        competitorKeysFetched: false,
        campaignsCreated: false,
        createdBy: '',
        createdOn: '',
      },
      value: 0,
      dialog: false,
      campGenList: [],
      segmentationDetails: null,
      messages: [
        {
          from: `Fetching keywords for Brand`,
        },
        {
          from: 'Fetching keywords for Discovery',
        },
        {
          from: 'Fetching keywords for Competitor',
        },
        {
          from: 'Fetching keywords for Custom campaign',
        },
        {
          from: 'Fetching keywords for Manesh’s Custom campaign',
        },
      ],

      step: 1,

      //start
      valid: true,

      //Campaign Generator
      e1: 1,
      //step 1
      valid1: false,
      orgId: null,
      getCampaignGroups: null,
      selectedAccount: null,
      appList: [],
      selectedApp: null,
      selectedAppData: null,
      campaignGroupRules: [(v: any) => !!v || 'Please select campaign group!'],
      appRules: [(v: any) => !!v || 'Please select an app!'],

      //Category 2 step
      valid2: false,
      brandCheck: false,
      categoryCheck: false,
      discoveryCheck: false,
      competitorCheck: false,
      customCheck: false,
      criteriaItemsTemp: [],

      organicRankSwitch: true,
      popularitySwitch: true,
      totalAppsSwitch: true,

      customSlugs: [],
      customCampaignSlug: null,
      customCampaignRule: [
        (v) =>
          !this.customSlugs.find((kw) => kw === v) ||
          'Campaign name already present',
      ],
      //organic
      organicSelect: null,
      organicValue: null,
      organicTo: null,
      organicFrom: null,

      //popularity
      popularitySelect: null,
      popularityValue: null,
      popularityFrom: null,
      popularityTo: null,

      //totalApps
      totalAppsSelect: null,
      totalAppsValue: null,
      totalAppsTo: null,
      totalAppsFrom: null,

      totalAppsToRules: [
        (v: any) => !!(v && v.length) || 'Please enter TO value!',
        (v: any) =>
          !!(v > parseInt(this.totalAppsFrom) + 10) ||
          'Difference between FROM and TO must be atleast 10',
      ],
      totalAppsFromRules: [
        (v: any) => !!(v && v.length) || 'Please enter FROM value!',
        // (v: any) =>
        //   !!(v > parseInt(this.totalAppsTo) - 10) ||
        //   'Difference between FROM and TO must be atleast 10',
      ],
      popularityToRules: [
        (v: any) =>
          !!(v > parseInt(this.popularityFrom) + 10) ||
          'Please enter value with difference greater than 10 from first value',
      ],
      popularityFromRules: [
        (v: any) => !!(v && v.length) || 'Please enter FROM value!',
      ],
      organicToRules: [
        (v: any) =>
          !!(v > parseInt(this.organicFrom) + 10) ||
          'Please enter value with difference greater than 10 from first value',
      ],
      organicFromRules: [
        (v: any) => !!(v && v.length) || 'Please enter FROM value!',
      ],
      manualKeyCategory: [],
      selectedCategories: [],
      //Segmentation 3 step
      valid3: false,
      ageItems: [
        { text: '18-25', value: [18, 25] },
        { text: '26-35', value: [26, 35] },
        { text: '36-45', value: [36, 45] },
        { text: '46-55', value: [46, 55] },
        { text: '56-65', value: [56, 65] },
      ],
      selectedAge: [],
      selectedGender: [],
      selectedCustomerType: null,

      Devices: [
         { text: 'IPAD', src: 'mdi-tablet' },
        { text: 'IPHONE', src: 'mdi-cellphone' },
        ],
      selectedDevice: null,
      deviceRules: [(v: any) => !!(v && v.length) || 'Please select devices!'],

      selectedCountries: [],
      selectedCountryName: null,
      Countries: COUNTRIES,
      appCountries: [],
      ageRules: [(v: any) => !!(v && v.length) || 'Please select age group!'],
      countryRules: [(v: any) => !!(v && v.length) || 'Please select country!'],
      genderRules: [(v: any) => !!(v && v.length) || 'Please select gender!'],
      customerRules: [
        (v: any) => !!(v && v.length) || 'Please select Customer type!',
      ],
      deviceRules: [(v: any) => !!(v && v.length) || 'Please select Device!'],

      finalAdgroups: [],
      countryDataAvailable: false,
      countryData: {
        cpt: null,
        dailyCap: null,
        totalBudget: null,
      },
      editDataDialog: false,
      countrySearch: '',
      appCountriesListing: [],
      countryListing: [],
      saving: false,

      //Add keywords 4 step
      selectedCampaignForAddKey: null,
      addKeywordsData: {},
      tKeys: [],
      currency: '',

      currentDate: moment().utc().format('YYYY-MM-DD'),
      currentTime: moment().utc().format('HH-mm'),

      //Draft
      draftList: false,
      search: '',
      draftId: null,
      fullscreenLoader: null,

      //Stepper popup
      campGenStepperDetails: {},

      //App Setting popup
      appSettingData: {},
      campGenListFull: [],
      campTabList:[],
      campList: [],
      adList: [],
      editMode1 : false,
      disableButton: false,

      //camp view dialog
      campaignViewMetadata: {},
      searchTabMetadata: {},
      searchResultMetadata: {},
      todayTabMetadata: {},
      productPageMetadata: {},

    };

  },

  computed: {
    editMode: function (): boolean {
      if(this.campList.some(e => e.editMode) 
        || this.campList.some(e => !e.name) 
        || this.adList.some(e => e.editMode) 
        || this.adList.some(e => !e.name )
        || (this.campList.map(e => e.name).length !== (Array.from(new Set(this.campList.map(e => e.name)))).length)
        || (this.adList.map(e => e.name).length !== (Array.from(new Set(this.adList.map(e => e.name)))).length)
        || (this.campList.some(e => !e.nameUnique))
      ){
        return true
      } else{
        return false
      }
    },
    detailsCustTypes: function (): string {
      const ct = this.details.segmentationDetails.selectedCustomerType;
      let ctt = [];
      ctt = ct.map((g) => {
        if (g === 'New Users') return this.$t('createCampaign.newUsers');
        else if (g === 'All Users') return this.$t('createCampaign.allUsers');
        else if (g === 'Existing Users')
          return this.$t('createCampaign.existingUsers');
        else if (g === 'Other Users') return this.$t('createCampaign.appUsers');
        else return '';
      });
      return ctt.join();
    },
    c: function (): string {
      return '';
    },
    tabItems: {
      get() {
        return [
          {
            tab: this.$t('tabs.campaigns'),
            content: this.details.campaigns || [],
            id: 1,
            count: this.details.categories.length,
          },
          {
            tab: this.$t('tabs.adgroups'),
            content: this.details.adGroups || [],
            id: 2,
            count: this.details.adGroups ? this.details.adGroups.length : 0,
          },
          {
            tab: this.$t('tabs.keywords'),
            content: this.details.keywords || [],
            id: 3,
            count: this.details.totalKeys.length,
          },
        ];
      },
    },
  
    //audience
    customerTypes: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('createCampaign.allUsers'),
            value: CustomerTypes.ALL,
          },
          {
            text: this.$t('createCampaign.newUsers'),
            value: CustomerTypes.NEW,
          },
          {
            text: this.$t('createCampaign.appUsers'),
            value: CustomerTypes.OTHER_APPS,
          },
          {
            text: this.$t('createCampaign.existingUsers'),
            value: CustomerTypes.EXISITING,
          },
        ];
      },
    },

    gender: {
      cache: false,
      get() {
        return [
          { text: this.$t('createCampaign.male'), value: Genders.MALE },
          { text: this.$t('createCampaign.female'), value: Genders.FEMALE },
        ];
      },
    },

    tabHeaders: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('aRules.name'),
            value: 'name',
            sortable: true,
            align: 'start',
          },
          {
            text: this.$t('settings.type'),
            value: 'type',
            sortable: true,
            align: 'start',
          },
        ];
      },
    },

    tabHeadersWithBidAmount: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('aRules.name'),
            value: 'name',
            sortable: true,
            align: 'start',
          },
          {
            text: this.$t('settings.type'),
            value: 'type',
            sortable: true,
            align: 'start',
          },
          {
            text: this.$t('keyHeaders.bidAmount'),
            value: 'bidAmount',
            align: 'start',
          },
        ];
      },
    },

    criteriaItems: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('createAR.allConOperationText2'),
            value: CriteriaOptions.LESS_THAN,
          },
          {
            text: this.$t('createAR.allConOperationText1'),
            value: CriteriaOptions.GREATER_THAN,
          },
          { text: this.$t('createAR.equalTo'), value: CriteriaOptions.EQUALS },
          {
            text: this.$t('createAR.allConOperationText3'),
            value: CriteriaOptions.IN_RANGE,
          },
        ];
      },
    },

    tKeysheaders: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('createCampaign.key'),
            sortable: true,
            value: 'key.text',
            filterable: false,
          },
          // {
          //   text: this.$t('createCampaign.matchType'),
          //   value: 'key.matchType',
          //   filterable: false,
          // },
          // {
          //   text: this.$t('createCampaign.amount'),
          //   value: 'key.bidAmount.amount',
          //   filterable: false,
          // },
          // {
          //   text: this.$t('createCampaign.currency'),
          //   value: 'key.bidAmount.currency',
          //   filterable: false,
          // },
          {
            text: 'Action',
            value: 'delete',
          },
        ];
      },
    },

    draftListHeaders: {
      get() {
        return [
          {
            text: this.$t('campaignGenerator.headerOrgName'),
            align: 'start',
            value: 'appData.selectedAccount',
          },
          {
            text: this.$t('campaignGenerator.headerAppName'),
            value: 'appData.selectedApp.appName',
          },
          {
            text: this.$t('campaignGenerator.headerResume'),
            value: 'resume',
          },
        ];
      },
    },

    countryListHeaders: {
      cache: false,
      get() {
        return [
          {
            text: 'Country',
            align: 'start',
            value: 'Name',
          },
          {
            text: 'Total Budget',
            value: 'totalBudget',
          },
          {
            text: 'Daily Cap',
            value: 'dailyCap',
          },
          {
            text: 'Cpt',
            value: 'cpt',
          },
          // {
          //   text: 'Action',
          //   value: 'action',
          // },
        ];
      },
    },
  },
  apollo: {
    getCampaignGroups: {
      query: GET_CAMPAIGN_GROUPS,
      skip(): boolean {
        return false;
      },
    },

    apps: {
      query: APPS_BY_ORG,
      variables() {
        return {
          orgId: this.orgId,
        };
      },
      skip() {
        return !this.orgId;
      },
      update(data) {
        this.appListing(data.apps);
      },
    },

    countriesForApp: {
      query: COUNTRIES_FOR_APP,
      variables() {
        return {
          adamId: this.selectedApp.adamId,
        };
      },
      skip() {
        return !this.selectedApp;
      },
      update(data) {
        this.setCountries(data.countriesForApp);
      },
    },

    getDraftTemplateList: {
      query: CAMPAIGN_TEMPLATE_DRAFT_LIST,
    },

    getDraftTemplate: {
      query: CAMPAIGN_TEMPLATE_DRAFT,
      variables() {
        return {
          draftId: this.draftId,
        };
      },
      skip() {
        return !this.draftId;
      },
      update(data) {
        this.bindDraftData(data.getDraftTemplate);
      },
    },

    getCampGenList: {
      query: CAMPAIGN_GEN_LIST,
      update(data) {
        this.campGenList = data.getCampGenList;
        this.updateTemplateList();
      },
    },
  },
  methods: {
    updateCampaignTemplate(item) {
      item.editTemplateName = false
      this.$apollo.query({
        query: UPDATE_CAMPAIGN_TEMPLATE,
        variables: {
          campTemplateName: item.tempTemplateName,
          templateId: item.templateId
        },
      })
      .then((res) => {
        if(res.data.updateCampTemplate.successMessage == 'Ok') {
            item.templateName = item.tempTemplateName
            this.$notify.success("Campaign template name successfully updated")
          }
          else {
            this.$notify.error("Error while updating campaign template name")
          }
      })
      .catch((error) => {
        this.$notify.error("Something went wrong")
      });
    },

    openEditTemplate(item) {
      item.editTemplateName = true
      item.tempTemplateName = item.templateName
    },

    isReadOnly(){
      return isReadOnly()
    },
    openPermissionReadOnlyPopup(){
      this.permissionReadOnlyMetadata={
        date: moment.now()
      }
    },
    goBack() {      
      if (this.e1 > 1 ) {
        this.e1-- ;
      }
    },



     openShowFullCard(index,item){
      this.showItemDetailsModel(item);
      this.showTabItems(item);
      this.cardIndex = index;
        if(this.showFullCard==false){
          this.showFullCard=true;
        }

     },
     closeCard(){
       if(this.showFullCard == true){
          this.showFullCard = false;
          // this.cardIndex=99;
      console.log("fytytdydyd",this.showFullCard);
        }
      
     },
     showTabItems(item){
     const itemDet =  this.showItemDetailsModel(item);
     console.log("itemDetcard",itemDet);
    
       this.campTabList =  [
          {
            tab: this.$t('tabs.campaigns'),
            content: itemDet.campaigns || [],
            id: 1,
            count: itemDet.categories?itemDet.categories.length:0,
          },
          {
            tab: this.$t('tabs.adgroups'),
            content: itemDet.adGroups || [],
            id: 2,
            count: itemDet.adGroups ? itemDet.adGroups.length : 0,
          },
          {
            tab: this.$t('tabs.keywords'),
            content: itemDet.keywords || [],
            id: 3,
            count: itemDet.totalKeys?itemDet.totalKeys.length:0,
          },
        ];
     
     },
    openCampaignViewDialog( ) {
      if(this.isReadOnly()){
        this.openPermissionReadOnlyPopup()
        return
      }
      this.campaignViewMetadata = {
        now: Date.now(),
      };
    },
    openSearchTabDialog( selectTab) {
      if (selectTab == 0) {
        this.searchResultMetadata = {
              now: Date.now(),
          };
      } else if (selectTab == 1) {
         this.searchTabMetadata = {
            now: Date.now(),
         };
      } else if (selectTab == 2) {
        this.todayTabMetadata = {
            now: Date.now(),
         };
      } else if (selectTab == 3) {
        this.productPageMetadata = {
            now: Date.now(),
         };
      }
     
    },
    budgetCheck(amount) {
      if(amount > KOCHAVA_BUDGET && LSS.company == KOCHAVA_COMPANY ) {
        this.disableButton = true
        this.$notify.error("The region should not have a Total budget, Daily Cap and cpt greater than $"+KOCHAVA_BUDGET)
      }
      else {
        this.disableButton = false
      }
    },

    adKeyRetry(tId){
      const r = Date.now()
      this.details.lastRetryAt = r
      this.$apollo.query({
        query: AD_KEY_RETRY,
        variables: {
          templateId: tId,
          retryAt: r
        },
      })
      .then((res) => {
        // console.log(res.data)
      })
      .catch((error) => {
        // console.log(error);
      });
    },
    async checkCampaignName(camp) {
      this.editMode1 = true
      const result = await this.$apollo.query({
        query: CHECK_CAMPAIGN_NAME,
        variables: {
          name: camp.name,
        },
      });
      this.editMode1 = false
      if (result.data.checkCampaignName.doesCampaignExist) {
        this.campList[camp.id].nameUnique = false
      } else {
        this.campList[camp.id].nameUnique = true
      }
      if (this.$refs.campgen5) this.$refs.campgen5.validate();
    },
    validateUniq(){
      // if((campList.map(e => e.name).length === (Array.from(new Set(campList.map(e => e.name)))).length))
      if (this.$refs.campgen5) this.$refs.campgen5.validate();
    },
    askConfirm(id, from) {
      if(this.isReadOnly()){
        this.openPermissionReadOnlyPopup()
        return
      }
      this.cgDbId = id
      this.decisionAlertFlag = true;
      this.decisionAlertTitle = this.$t('campaignGenerator.decisionMessage'),
      this.decisionAlertIcon = 1,
      this.decisionAlertData = {
        from: 'cg',
        now: Date.now(),
        data : {
          dbId : id,
          opId : from
        }
      };
    },
    decisionOkAction(event) {
      this.decisionAlertFlag = false;
      if(event.data){
        if(event.data.dbId && event.data.opId === 1){
          this.deleteTemplate(event.data.dbId)
        }
      }
    },
    clearDecisionModel() {
      this.decisionAlertFlag = false;
    },
    async deleteTemplate(id) {
      this.fullscreenLoader = this.$loading.show();
      const res = await this.$apollo
        .query({
          query: DELETE_TEMPLATE,
          variables: {
            dbId: id,
          },
        })
        .catch((error) => {          
          this.fullscreenLoader.hide();
        });
        this.fullscreenLoader.hide();

        if (res.data.deleteTemplate.code === 0) {
          this.$notify.success('Template deleted successfully');
          // await this.$apollo.queries.getCampGenList.refetch();
          this.campGenList = this.campGenList.filter(e => e._id != id)    
          this.campGenListFull = this.campGenList 
        } else {
          this.$notify.error('Error while deleting campaign template');
        }
    }, 
    openAppSetting() {
      if(this.isReadOnly()){
        this.openPermissionReadOnlyPopup()
        return
      }
      this.appSettingData = {
        now: Date.now()
      }
    },
    appChanged(e) {
      if (this.$refs.campgen2) this.$refs.campgen2.reset();
      if (this.$refs.campgen3) this.$refs.campgen3.reset();
      this.tKeys = [];
    },
    orgChanged(e) {
      if (this.$refs.campgen2) this.$refs.campgen2.reset();
      if (this.$refs.campgen3) this.$refs.campgen3.reset();
      this.tKeys = [];
    },
    async updateTemplateList() {
      
      this.campGenList = this.campGenList.map((e) => {
        let p = 10;
        let limCats = [];
        e.categories.map((e) => {
          if (limCats.length > 2) return;
          if (
            e != 'Brand' &&
            e != 'Category' &&
            e != 'Competitor' &&
            e != 'Discovery'
          ) {
            limCats.push(e);
          }
        });
        if (limCats.length < 3 && limCats.length < e.categories.length) {
          e.categories.map((e) => {
            if (e == 'Brand') limCats.push(e);
          });
        }
        if (limCats.length < 3 && limCats.length < e.categories.length) {
          e.categories.map((e) => {
            if (e == 'Discovery') limCats.push(e);
          });
        }
        if (limCats.length < 3 && limCats.length < e.categories.length) {
          e.categories.map((e) => {
            if (e == 'Category') limCats.push(e);
          });
        }
        if (limCats.length < 3 && limCats.length < e.categories.length) {
          e.categories.map((e) => {
            if (e == 'Competitor') limCats.push(e);
          });
        }
        let progressItems = 0;
        let custExist = false;
        if (e.categories.includes('Category'))
          progressItems = progressItems + 1;
        if (e.categories.includes('Competitor'))
          progressItems = progressItems + 1;
        if (
          e.categories.includes('Brand') ||
          e.categories.includes('Discovery')
        )
          progressItems = progressItems + 1;
        if (
          !e.categories.includes('Brand') &&
          !e.categories.includes('Discovery')
        ) {
          e.categories.forEach((f) => {
            if (
              f != 'Brand' &&
              f != 'Discovery' &&
              f != 'Category' &&
              f != 'Competitor'
            )
              custExist = true;
          });
        }
        if (custExist) progressItems = progressItems + 1;
        progressItems = progressItems + 3;
        const bp = Math.ceil(100 / progressItems);
        if (
          e.categories.includes('Brand') ||
          e.categories.includes('Discovery') || 
          custExist
        ) p = bp; else p = 0;
        if (e.categories.includes('Category')) {
          if (e.categoryKeysFetched) {
            p = p + bp;
            if (e.categories.includes('Competitor') && e.competitorKeysFetched)
              p = p + bp;
          }
        } else if (e.categories.includes('Competitor')) {
          if (e.competitorKeysFetched) p = p + bp;
        }
        if (e.campaignsCreated) p = p + bp;
        if (e.adGroupsCreated) p = p + bp;
        if (e.allDone) p = p + bp;
        if (e.allDone && e.adGroupsCreated) p = 100; 
        let appIcon = '';
        // appIcon = this.getAppIcon(e.adamId);
        
        if (this.details.adamId === e.adamId) this.details.progress = p;
        
        return {
          ...e,
          progress: p,
          limCats: limCats,
          appIcon: appIcon,
        };
      });
      for (let index = 0; index < this.campGenList.length; index++) {
        const element = this.campGenList[index];
        const icon = await this.getAppIcon(element.adamId);
        this.campGenList[index].appIcon = icon;
      }    
      console.log("campList",this.campGenList);  
      this.campGenListFull = this.campGenList
    },
    tabSearchChanged(e) {
      this.tabFilter = '';
    },
    tabFilterChanged(e) {
      this.tabSearch = e;
    },
    generateUUID() {
      this.uid = uuidv4();
      this.currentDate = moment().utc().format('YYYY-MM-DD');
      this.currentTime = moment().utc().format('HH-mm');
    },
    showItemDetailsModel(item){
    
      const uniqNkeys = [...new Set(item.nKeys)]
      const uniqCompetitorKeys = [...new Set(item.competitorKeys)]
      const uniqCategoryKeys = [...new Set(item.categoryKeys)]

      const totalKeys = uniqNkeys.concat(
        uniqCompetitorKeys.concat(uniqCategoryKeys)
      );
      const keywords = [];
      this.tabFilter = '';
      this.tabSearch = '';
      const cpt = '$ ' + (item.cpt.amount? Number(item.cpt.amount).toFixed(2) : '0.01') + ' '+ (item.cpt.currency? item.cpt.currency.toString() : 'USD')
      uniqCompetitorKeys.map((e) => {
        keywords.push({
          name: e,
          type: 'Competitor',
          bidAmount: cpt
        });
      });
      uniqCategoryKeys.map((e) => {
        keywords.push({
          name: e,
          type: 'Category',
          bidAmount: cpt
        });
      });
      uniqNkeys.map((e) => {
        keywords.push({
          name: e,
          type: 'Discovery',
          bidAmount: cpt
        });
      });
      item.tKeys.forEach((x) => {
        x.key.forEach((y) => {
          totalKeys.push(y.text);
          keywords.push({
            name: y.text,
            type: x.campaign,
            bidAmount: cpt
          });
        });
      });
       
     return  item=  {
        ...item,
        totalKeys:totalKeys,
        keywords:keywords,
      };
        console.log("itemDet",item);
    //  console.log("item",this.details );
      //return item;
    },
  
    showDetailsModal(item) {
      const uniqNkeys = [...new Set(item.nKeys)]
      const uniqCompetitorKeys = [...new Set(item.competitorKeys)]
      const uniqCategoryKeys = [...new Set(item.categoryKeys)]

      const totalKeys = uniqNkeys.concat(
        uniqCompetitorKeys.concat(uniqCategoryKeys)
      );
      const keywords = [];
      this.tabFilter = '';
      this.tabSearch = '';
      const cpt = '$ ' + (item.cpt.amount? Number(item.cpt.amount).toFixed(2) : '0.01') + ' '+ (item.cpt.currency? item.cpt.currency.toString() : 'USD')
      uniqCompetitorKeys.map((e) => {
        keywords.push({
          name: e,
          type: 'Competitor',
          bidAmount: cpt
        });
      });
      uniqCategoryKeys.map((e) => {
        keywords.push({
          name: e,
          type: 'Category',
          bidAmount: cpt
        });
      });
      uniqNkeys.map((e) => {
        keywords.push({
          name: e,
          type: 'Discovery',
          bidAmount: cpt
        });
      });
      item.tKeys.forEach((x) => {
        x.key.forEach((y) => {
          totalKeys.push(y.text);
          keywords.push({
            name: y.text,
            type: x.campaign,
            bidAmount: cpt
          });
        });
      });
      this.details = {
        ...item,
        totalKeys,
        keywords,
      };

      console.log("details",this.details);
      this.showDetails = true;
    },
    appListing(data) {
      for (const app of data) {
        this.$apollo
          .query({
            query: APP_METADATA,
            variables: {
              appId: app.adamId,
              location: 'us',
              language: 'en',
              device: 'iphone',
              startDate: new Date().toISOString().substr(0, 10),
              endDate: new Date().toISOString().substr(0, 10),
            },
          })
          .then((data) => {
            this.appList.push({
              appName: data.data.getAppMetadata.content.title,
              adamId: app.adamId,
              appIcon: data.data.getAppMetadata.content.icon,
              appGenre: +data.data.getAppMetadata.content.genres[0],
            });
            if (
              data.data.getAppMetadata == null ||
              data.data.getAppMetadata == undefined
            ) {
              return;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    appSelected() {
      this.e1 = 2;
    },

    addCustomSlug() {
      if (this.customCampaignSlug != null) {
        if (
          !this.customSlugs.find((item) => item === this.customCampaignSlug)
        ) {
          this.customSlugs.push(this.customCampaignSlug);
        }
      }
      this.customCampaignSlug = null;
    },

    deleteCustomSlug(campaign) {
      this.customSlugs = this.customSlugs.filter((kw) => kw !== campaign);
    },

    categorySelected() {
      this.selectedCategories = [];
      if (this.brandCheck == true) {
        this.selectedCategories.push(AutomateCampaignType.BRAND);
      }
      if (this.categoryCheck == true) {
        this.selectedCategories.push(AutomateCampaignType.CATEGORY);
      }
      if (this.discoveryCheck == true) {
        this.selectedCategories.push(AutomateCampaignType.DISCOVERY);
      }
      if (this.competitorCheck == true) {
        this.selectedCategories.push(AutomateCampaignType.COMPETITOR);
      }
      if (this.customCheck == true) {
        if (this.customSlugs.length == 0) {
          this.$notify.error('Enter atleast one custom campaign');
          return;
        }
        for (const campaign of this.customSlugs) {
          this.selectedCategories.push(campaign);
        }
      }
      const automateKeywordCategory = [
        AutomateCampaignType.CATEGORY,
        AutomateCampaignType.DISCOVERY,
        AutomateCampaignType.COMPETITOR,
      ];
      this.manualKeyCategory = this.selectedCategories;
      for (const category of automateKeywordCategory) {
        this.manualKeyCategory = this.manualKeyCategory.filter(
          (sc) => sc !== category
        );
      }
      this.e1 = 3;
    },

    setCountries(data) {
      this.selectedAppData = data[0];
      const Countries = data[0].countryOrRegionCodes;
      for (const countryCode of Countries) {
        for (const country of this.Countries) {
          if (country.Code == countryCode) {
            this.appCountries.push(country);
          }
        }
      }
     this.appCountries.sort((a,b) => (a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0))

    },

    segmentationSelected() {
      this.finalAdgroups = [];
      this.countryDataAvailable = false;
      this.countryData = {
        cpt: null,
        dailyCap: null,
        totalBudget: null,
      }

      for (const age of this.selectedAge) {
        for (const gender of this.selectedGender) {
          for (const device of this.selectedDevice) {
            for (const custType of this.selectedCustomerType) {
              this.finalAdgroups.push({
                age: age,
                gender: gender,
                device: device,
                country: this.selectedCountries,
                customerType: custType,
              });
            }
          }
        }
      }

      if (
          this.countryData.cpt > 0 &&
          this.countryData.totalBudget > 0 &&
          this.countryData.dailyCap > 0
        ) {
            if(
              LSS.company == KOCHAVA_COMPANY && 
              (this.countryData.cpt > KOCHAVA_BUDGET ||
              this.countryData.totalBudget > KOCHAVA_BUDGET ||
              this.countryData.dailyCap > KOCHAVA_BUDGET)
              ) {
              this.$notify.error("The region should not have a Total budget, Daily Cap and cpt greater than $"+KOCHAVA_BUDGET)
              this.countryDataAvailable = false;
            }
            else {
              this.countryDataAvailable = true;
            }
        }
      else {
        if (this.selectedAppData.valuesForAutoCampaign != null) {
          for (const defaultValues of this.selectedAppData
            .valuesForAutoCampaign) {
            if (defaultValues.Code == this.selectedCountries) {
              this.countryData.totalBudget = defaultValues.totalBudget;
              this.countryData.cpt = defaultValues.cpt;
              this.countryData.dailyCap = defaultValues.dailyCap;
              if (
                this.countryData.cpt > 0 &&
                this.countryData.totalBudget > 0 &&
                this.countryData.dailyCap > 0
              ) {
                if(
                  LSS.company == KOCHAVA_COMPANY && 
                  (this.countryData.cpt > KOCHAVA_BUDGET ||
                  this.countryData.totalBudget > KOCHAVA_BUDGET ||
                  this.countryData.dailyCap > KOCHAVA_BUDGET)
                  ) {
                  this.$notify.error("The region should not have a Total budget, Daily Cap and cpt greater than $"+KOCHAVA_BUDGET)
                  this.countryDataAvailable = false;
                }
                else {
                  this.countryDataAvailable = true;
                }
              }
            }
          }
        }
      }

      if (this.countryDataAvailable == true) {
        this.e1 = 4;
      } else {
        // this.$notify.error(
        //   'Default data not Available for selected country. Kindly update the data to proceed further'
        // );
        this.countrySearch = this.selectedCountryName;
        this.editDataDialog = true;
        this.editDefaultValues(this.selectedAppData);
      }
    },

    addTargetKeywords(item) {
      this.selectedCampaignForAddKey = item;
      this.addKeywordsData = {
        appId: this.selectedApp.adamId,
        appName: this.selectedApp.appName,
        currency: this.currency,
        countryCode: this.selectedCountries,
        now: Date.now(),
      };
    },

    addTargetKeysSaveClicked(event) {
      if (event.length == 0) {
        return;
      }
      let found = false;
      if (this.tKeys.length != 0) {
        for (const data of this.tKeys) {
          if (data.campaign == this.selectedCampaignForAddKey) {
            found = true;
            for (const item of event) {
              data.key.push(item);
            }
          }
        }
      }

      if (!found) {
        this.tKeys.push({
          campaign: this.selectedCampaignForAddKey,
          key: event,
        });
      }
    },

    deleteTkeyFromTable(item) {
      for (const keyData of this.tKeys) {
        if (keyData.campaign == item.campaign) {
          keyData.key = keyData.key.filter((kw) => kw !== item.key);
          if (keyData.key.length == 0) {
            this.tKeys = this.tKeys.filter(
              (kw) => kw.campaign != item.campaign
            );
          }
        }
      }
    },

    tarKey(item) {
      let keywords = [];
      for (const key of this.tKeys) {
        if (key.campaign == item) {
          for (const data of key.key) {
            keywords.push({
              campaign: key.campaign,
              key: data,
            });
          }
        }
      }
      return keywords;
    },

    keysAdded(){
      const campaigns = [];
      if(!this.campList.length) {
        this.selectedCategories.forEach((element, i) => {
          campaigns.push({
            name:
              this.selectedAccount +
              '-' +
              this.selectedApp.appName +
              '-' +
              element.toString() +
              '-' +
              this.currentDate +
              '-' +
              this.currentTime,
            type: element.toString(),
            id: i,
            editMode: false,
            nameUnique: true,
          });
        });
        this.campList = campaigns
      }
      
      if(!this.adList.length) {
        const adGroups = [];
        let id = 0
        this.selectedCategories.forEach((element, i) => { 
          this.finalAdgroups.forEach((item, j) => { 
            adGroups.push({
              name:
                item.age[0] +
                '-' +
                item.age[1] +
                '-' +
                item.gender +
                '-' +
                item.customerType +
                '-' +
                item.device +
                '-' +
                item.country +
                '-' +
                element.replaceAll(' ', '-') +
                '-' +
                this.currentDate +
                '-' +
                this.currentTime,
              type: element,
              id: id,
              editMode: false
            });
            id = id + 1
          });
        });
        this.adList = adGroups
      }
      this.e1 = 5;
    },
    editCampListItem(camp, i){
      this.campList[i].editMode = true
    },
    saveCampListItem(camp, i){
      this.campList[i].name = camp.name
      this.campList[i].editMode = false
    },

     editAdsListItem(ad, i){
      this.adList[i].editMode = true
    },
    saveAdsListItem(ad, i){
      this.adList[i].name = ad.name
      this.adList[i].editMode = false
    },
    async saveTemplate() {
      const competitorParam = {
        organic: {
          type: this.organicSelect,
          value: this.organicValue,
          toValue: this.organicTo,
          fromValue: this.organicFrom,
        },

        popularity: {
          type: this.popularitySelect,
          value: this.popularityValue,
          toValue: this.popularityTo,
          fromValue: this.popularityFrom,
        },

        totalApps: {
          type: this.totalAppsSelect,
          value: this.totalAppsValue,
          toValue: this.totalAppsTo,
          fromValue: this.totalAppsFrom,
        },
      };
      // const genre = this.appList.filter(e => e.adamId == this.selectedApp.adamId)
      const genders = this.selectedGender.map((e) => {
        if (e == 'M') return 'Male';
        if (e == 'F') return 'Female';
      });
      const types = this.selectedCustomerType.map((e) => {
        if (e == 0) return 'All Users';
        if (e == 1) return 'New Users';
        if (e == 2) return 'Other Users';
        if (e == 3) return 'Existing Users';
      });

      const segmentation = {
        selectedAge: this.selectedAge,
        selectedGender: genders,
        selectedCustomerType: types,
        selectedDevice: this.selectedDevice,
        selectedCountries: this.selectedCountries,
      };

      const totalBudget = {
        currency: this.currency || 'USD',
        amount: this.countryData.totalBudget || '1',
      };

      const cpt = {
        currency: this.currency || 'USD',
        amount: this.countryData.cpt || '1',
      };

      const dailyCap = {
        currency: this.currency || 'USD',
        amount: this.countryData.dailyCap || '1',
      };

      const campaigns = this.campList.map(({ editMode, nameUnique, ...keepAttrs }) => {
        return keepAttrs;
      })
      const adgroups = this.adList.map(({ editMode, ...keepAttrs }) => {
        return keepAttrs;
      })
      const createdBy = decodeToken(LSS.token).FirstName;
      const createdOn = new Date().toISOString().substring(0, 10);
      const campaignTemplateReq = {
        campaignTemplate: {
          adamId: this.selectedApp.adamId,
          appName: this.selectedApp.appName,
          appCatId: this.selectedApp.appGenre,
          orgId: this.orgId,
          orgName: this.selectedAccount,
          categories: this.selectedCategories,
          segmentationRules: this.finalAdgroups,
          segmentationDetails: segmentation,
          tKeys: this.tKeys,
          competitorParam: competitorParam,
          language: 'us',
          location: 'us',
          device: 'iphone',
          catType: 'free',
          totalBudget: totalBudget,
          cpt: cpt,
          dailyCap: dailyCap,
          campaigns: campaigns,
          adGroups: adgroups,
          _id: '',
          createdBy: createdBy,
          createdOn: createdOn,
        },
      };
      const result = await this.$apollo.mutate({
        mutation: SAVE_TEMPLATE_FOR_CAMPAIGN,
        variables: campaignTemplateReq,
      });

      if (result.data.saveTemplate.successMessage == 'TemplateSaved') {
        this.$notify.success('Template saved for automation campaign');
        this.dialog = false;
        updateCLF('campGen', this);
        // window.location.reload();
        this.fullscreenLoader = this.$loading.show();
        await this.$apollo.queries.getCampGenList.refetch();   
        this.fullscreenLoader.hide();
        
      } else {
        this.$notify.error('Error while saving campaign template');
      }
    },

    async draftTemplate() {
      const draftTemplateReq = {
        draftTemplate: {
          step: this.e1.toString(),
          appData: {
            selectedApp: this.selectedApp,
            orgId: this.orgId,
            selectedAccount: this.selectedAccount,
          },
          categories: {
            brandCheck: this.brandCheck,
            categoryCheck: this.categoryCheck,
            discoveryCheck: this.discoveryCheck,
            competitorCheck: this.competitorCheck,
            customCheck: this.customCheck,
            organicRankSwitch: this.organicRankSwitch,
            popularitySwitch: this.popularitySwitch,
            totalAppsSwitch: this.totalAppsSwitch,
            customSlugs: this.customSlugs,

            organic: {
              type: this.organicSelect,
              value: this.organicValue,
              toValue: this.organicTo,
              fromValue: this.organicFrom,
            },

            popularity: {
              type: this.popularitySelect,
              value: this.popularityValue,
              fromValue: this.popularityFrom,
              toValue: this.popularityTo,
            },

            totalApps: {
              type: this.totalAppsSelect,
              value: this.totalAppsValue,
              toValue: this.totalAppsTo,
              fromValue: this.totalAppsFrom,
            },

            manualKeyCategory: this.manualKeyCategory,
            selectedCategories: this.selectedCategories,
          },

          segmentation: {
            selectedAge: this.selectedAge,
            selectedGender: this.selectedGender,
            selectedCustomerType: this.selectedCustomerType,
            selectedDevice: this.selectedDevice,
            selectedCountries: this.selectedCountries,
            finalAdgroups: this.finalAdgroups,
          },

          countryDefaultData: this.countryData,

          keywords: {
            tKeys: this.tKeys,
            currency: this.currency,
          },
        },
      };

      const result = await this.$apollo.mutate({
        mutation: DRAFT_TEMPLATE_FOR_CAMPAIGN,
        variables: draftTemplateReq,
      });

      if (result.data.draftTemplate.successMessage == 'TemplateSaved') {
        this.$notify.success('Template saved as draft for automation campaign');
        this.dialog = false;
        this.$apollo.queries.getDraftTemplateList.refetch();
      } else {
        this.$notify.error('Error while saving campaign template as draft');
      }
    },

    openDraft(item) {
      this.draftList = false;
      this.draftId = item.draftId;
    },

    bindDraftData(data) {
      this.fullscreenLoader = this.$loading.show();
      this.e1 = data.step;
      this.selectedApp = data.appData.selectedApp;
      this.orgId = data.appData.orgId;
      this.selectedAccount = data.appData.selectedAccount;
      this.brandCheck = data.categories.brandCheck;
      this.categoryCheck = data.categories.categoryCheck;
      this.discoveryCheck = data.categories.discoveryCheck;
      this.competitorCheck = data.categories.competitorCheck;
      this.customCheck = data.categories.customCheck;
      this.organicRankSwitch = data.categories.organicRankSwitch;
      this.popularitySwitch = data.categories.popularitySwitch;
      this.totalAppsSwitch = data.categories.totalAppsSwitch;
      this.customSlugs = data.categories.customSlugs;

      this.organicSelect = data.categories.organic.type;
      this.organicValue = data.categories.organic.value;
      this.organicTo = data.categories.organic.toValue;
      this.organicFrom = data.categories.organic.fromValue;

      this.popularitySelect = data.categories.popularity.type;
      this.popularityValue = data.categories.popularity.value;
      this.popularityTo = data.categories.popularity.toValue;
      this.popularityFrom = data.categories.popularity.fromValue;

      this.totalAppsSelect = data.categories.totalApps.type;
      this.totalAppsValue = data.categories.totalApps.value;
      this.totalAppsTo = data.categories.totalApps.toValue;
      this.totalAppsFrom = data.categories.totalApps.fromValue;

      this.manualKeyCategory = data.categories.manualKeyCategory;
      this.selectedCategories = data.categories.selectedCategories;

      this.selectedAge = data.segmentation.selectedAge;
      this.selectedGender = data.segmentation.selectedGender;
      this.selectedCustomerType = data.segmentation.selectedCustomerType;
      this.selectedDevice = data.segmentation.selectedDevice;
      this.selectedCountries = data.segmentation.selectedCountries[0];
      this.finalAdgroups = data.segmentation.finalAdgroups;

      this.tKeys = data.keywords.tKeys;
      this.currency = data.keywords.currency;

      this.fullscreenLoader.hide();
    },

    editDefaultValues(data) {
      this.appCountriesListing = [];
      this.countryListing = [];

      if (data.valuesForAutoCampaign != null) {
        for (const data of data.valuesForAutoCampaign)
          this.appCountriesListing.push(data);
      }

      for (const countryCode of data.countryOrRegionCodes) {
        for (const country of this.Countries) {
          if (country.Code == countryCode) {
            this.countryListing.push(country);
            for (const ac of this.appCountriesListing) {
              this.countryListing = this.countryListing.filter(
                (cl) => cl.Code !== ac.Code
              );
            }
          }
        }
      }

      for (const data of this.countryListing) {
        this.appCountriesListing.push({
          Code: data.Code,
          Name: data.Name,
          totalBudget: null,
          dailyCap: null,
          cpt: null,
        });
      }
    },

    async changeValues(e) {
      const valuesForAutoCampaignReq = this.appCountriesListing;

      for (const country of this.appCountriesListing) {
        if (country.Code == this.selectedCountries) {
          this.countryData.totalBudget = country.totalBudget;
          this.countryData.cpt = country.cpt;
          this.countryData.dailyCap = country.dailyCap;
        }
      }

      const result = await this.$apollo.mutate({
        mutation: APP_SETTINGS_FOR_CAMPAIGN,
        variables: {
          adamId: this.selectedApp.adamId,
          valuesForAutoCampaign: valuesForAutoCampaignReq,
        },
      });

      if (result.data.appSettingsForCampaign.successMessage) {
        await this.$apollo.queries.countriesForApp.refetch(); 
        this.$notify.success('Data Updated');
        this.editDataDialog = false;
      } else {
        this.$notify.error('Error while updating data');
        this.editDataDialog = false;
      }
    },
    
    openCampGenSteper(item) {
      this.campGenStepperDetails = {
        details: item,
      };
    },
   
    async getAppIcon(appId) {
      let appIcon;
      const apps = LSS.ownApps;
      for (const app of apps) {
        if (app.adamId == appId) {
          appIcon = app.appIcon;
        }
      }
      if (appIcon) return appIcon;
      else {
        const data = await this.$apollo
          .query({
            query: APP_METADATA,
            variables: {
              appId: appId,
              location: 'us',
              language: 'en',
              device: 'iphone',
              startDate: new Date().toISOString().substr(0, 10),
              endDate: new Date().toISOString().substr(0, 10),
            },
          })
          .catch((error) => {
            // console.log(error);
          });
        appIcon = data.data.getAppMetadata.content.icon;
        return appIcon;
      }
    },
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.$refs.campgen1.reset();
        this.$refs.campgen2.reset();
        this.$refs.campgen3.reset();
        setTimeout(() => (this.e1 = 1), 1000);
      }
    },
    orgId(value) {
      // handling currency as per the org

      if (value) {
        this.currency = this.getCampaignGroups.find(
          (group) => group.orgId === value
        ).currency;

        this.selectedAccount = this.getCampaignGroups.find(
          (group) => group.orgId === value
        ).orgName;
      }
    },

    selectedCountries(value) {
      if (value) {
        const t = this.appCountries.find((group) => group.Code === value);
        this.selectedCountryName = t?.Name;
      }
    },
    searchMain(value){
      this.campGenList = this.campGenListFull
      if(!value) {        
        return
      }
      const campGenListFiltered = this.campGenList.filter(e => e.appName.toLowerCase().includes(value.toLowerCase()))
      this.campGenList = campGenListFiltered
    }
  },
  mounted() {
    this.syncStatus = LSS.activeSync
    Vue.prototype.$sckt.on("syncprogress:event", () => {
      this.syncStatus = LSS.activeSync
    });
    this.locale = localStorage.getItem('locale') || 'en'
    
    this.$root.$on('locale-changed', (locale) => {
      this.locale = locale.localeCode
    });

    this.$root.$on('socket-msg-cg-catKeys', (msg) => {
      if (msg) {
        this.campGenList.map((e, i) => {
          if (e.appCatId == msg.catId && e.catType == msg.catType) {
            this.campGenList[i].categoryKeysFetched = true;
            this.campGenList[i].categoryKeys = msg.keys;
          }
        });
        if (
          this.details.appCatId == msg.catId &&
          this.details.catType == msg.catType
        ) {
          this.details.categoryKeysFetched = true;
          this.details.categoryKeys = msg.keys;
        }
        this.updateTemplateList();
      }
    });
    this.$root.$on('socket-msg-cg-compKeys', (msg) => {
      if (msg) {
        this.campGenList.map((e, i) => {
          if (e._id == msg.templateId) {
            this.campGenList[i].competitorKeysFetched = true;
            this.campGenList[i].competitorKeys = msg.keys;
          }
        });
        if (this.details._id == msg.templateId) {
          this.details.competitorKeysFetched = true;
          this.details.competitorKeys = msg.keys;
        }
        this.updateTemplateList();
      }
    });
    this.$root.$on('socket-msg-cg-campaigns', (msg) => {
      if (msg) {
        this.campGenList.map((e, i) => {
          if (e.templateId == msg.templateId) {
            this.campGenList[i].campaignsCreated = true;
            // this.campGenList[i].campaigns = msg.opArray;
          }
        });
        if (this.details.templateId == msg.templateId) {
          this.details.campaignsCreated = true;
          // this.details.campaigns = msg.opArray;
        }
        this.updateTemplateList();
      }
    });
    this.$root.$on('socket-msg-cg-adgroups', (msg) => {
      if (msg) {
        this.campGenList.map((e, i) => {
          if (e.templateId == msg.templateId) {
            this.campGenList[i].adGroupsCreated = true;
            // this.campGenList[i].adGroups = msg.opArray;
          }
        });
        if (this.details.templateId == msg.templateId) {
          this.details.adGroupsCreated = true;
          // this.details.adGroups = msg.opArray;
        }
        this.updateTemplateList();
      }
    });
    this.$root.$on('socket-msg-cg-keywords', (msg) => {
      if (msg) {
        this.campGenList.map((e, i) => {
          if (e.templateId == msg.templateId) {
            this.campGenList[i].allDone = true;
            // this.campGenList[i].keywords = msg.opArray;
          }
        });
        if (this.details.templateId == msg.templateId) {
          this.details.allDone = true;
          // this.details.keywords = msg.opArray;
        }
        this.updateTemplateList();
      }
    });
  },
});
