var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "900px", persistent: "" },
      model: {
        value: _vm.appSettingsDialog,
        callback: function ($$v) {
          _vm.appSettingsDialog = $$v
        },
        expression: "appSettingsDialog",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "v-modal-fixed style-2 app-setting-popup" },
        [
          _c(
            "v-card-title",
            {
              staticClass:
                "headline sticky-header v-modal-header d-flex justify-space-between px-5 py-3",
            },
            [
              _c("h5", [_vm._v(_vm._s(_vm.$t("pages.appSettings")))]),
              _c(
                "v-icon",
                {
                  staticClass: "black--text",
                  on: {
                    click: function ($event) {
                      _vm.appSettingsDialog = false
                    },
                  },
                },
                [_vm._v("mdi-close")]
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pt-0 pb-0" },
            [
              [
                _c(
                  "v-card",
                  { staticClass: "rounded", attrs: { elevation: "0" } },
                  [
                    _c(
                      "div",
                      { attrs: { id: "app" } },
                      [
                        _c("v-card-title", { staticClass: "px-0 pt-0 pb-0" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "sub-heading-with-btn d-flex flex-wrap justify-end w-100",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "v-search-300" },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      width: "300",
                                      "append-icon": "mdi-magnify",
                                      label: _vm.$t("labels.search"),
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: _vm.searchApp,
                                      callback: function ($$v) {
                                        _vm.searchApp = $$v
                                      },
                                      expression: "searchApp",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                        _c("v-data-table", {
                          staticClass: "elevation-0 pa-0",
                          attrs: {
                            loading:
                              _vm.$apollo.queries.appsReportsForFilter.loading,
                            "items-per-page": 5,
                            headers: _vm.appListHeaders,
                            items:
                              (_vm.appsReportsForFilter &&
                                _vm.appsReportsForFilter.row) ||
                              [],
                            search: _vm.searchApp,
                            "footer-props": {
                              itemsPerPageText: _vm.$t(
                                "campaignGenerator.footerProps.itemsPerPageText"
                              ),
                              itemsPerPageAllText: _vm.$t(
                                "campaignGenerator.footerProps.itemsPerPageAllText"
                              ),
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              _vm._l(
                                Object.keys(_vm.$scopedSlots),
                                function (slot) {
                                  return {
                                    key: slot,
                                    fn: function (scope) {
                                      return [_vm._t(slot, null, null, scope)]
                                    },
                                  }
                                }
                              ),
                              {
                                key: "item.action",
                                fn: function (ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: " ",
                                            attrs: { cols: "6" },
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "font-inherit",
                                                attrs: {
                                                  small: "",
                                                  color: "primary",
                                                  depressed: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editData(item)
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("appSetting.manage")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "item.metadata.app.appName",
                                fn: function (ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "d-flex align-center my-3",
                                            attrs: { cols: "6" },
                                          },
                                          [
                                            _c("v-img", {
                                              staticClass: "rounded-lg ma-0",
                                              attrs: {
                                                color: "secondary",
                                                "lazy-src": _vm.getIcon(
                                                  item.metadata.app.adamId
                                                ),
                                                "max-width": "30",
                                                "max-height": "30",
                                                "min-width": "20",
                                                "min-height": "20",
                                                src: _vm.getIcon(
                                                  item.metadata.app.adamId
                                                ),
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "ml-2" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.metadata.app.appName
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "no-results",
                                fn: function () {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("appSetting.noRecords"))
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "900px", persistent: "" },
          model: {
            value: _vm.editDataDialog,
            callback: function ($$v) {
              _vm.editDataDialog = $$v
            },
            expression: "editDataDialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "v-modal-fixed style-2 app-setting-popup" },
            [
              _c(
                "v-card-title",
                {
                  staticClass:
                    "headline sticky-header v-modal-header d-flex justify-space-between px-5 py-3",
                },
                [
                  _c("h5", [
                    _vm._v(_vm._s(_vm.$t("appSetting.setDefaultValues"))),
                  ]),
                  _c(
                    "v-icon",
                    {
                      staticClass: "black--text",
                      attrs: { disabled: _vm.saving },
                      on: {
                        click: function ($event) {
                          return _vm.close()
                        },
                      },
                    },
                    [_vm._v("mdi-close")]
                  ),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "pa-0" },
                [
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "rounded px-8 py-0",
                        attrs: { elevation: "0" },
                      },
                      [
                        _c("v-data-table", {
                          staticClass: "elevation-0 pa-0 table-head",
                          attrs: {
                            loading:
                              _vm.$apollo.queries.appsReportsForFilter.loading,
                            "hide-default-footer": true,
                            "items-per-page": -1,
                            headers: _vm.countryListHeaders,
                            items: _vm.appCountries || [],
                            search: _vm.search,
                            "fixed-header": "",
                            height: "18rem",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "top",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "w-100 d-flex justify-end",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "v-search v-search-300 w-md-100 px-4 pt-2",
                                          },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                "append-icon": "mdi-magnify",
                                                label: _vm.$t(
                                                  "appSetting.searchByCountry"
                                                ),
                                                "hide-details": "",
                                              },
                                              model: {
                                                value: _vm.search,
                                                callback: function ($$v) {
                                                  _vm.search = $$v
                                                },
                                                expression: "search",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              _vm._l(
                                Object.keys(_vm.$scopedSlots),
                                function (slot) {
                                  return {
                                    key: slot,
                                    fn: function (scope) {
                                      return [_vm._t(slot, null, null, scope)]
                                    },
                                  }
                                }
                              ),
                              {
                                key: "item.Name",
                                fn: function (ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass:
                                              "d-flex align-center my-3",
                                          },
                                          [
                                            [
                                              _c("country-flag", {
                                                staticClass: "ml2",
                                                attrs: {
                                                  country: item.Code.toLowerCase(),
                                                },
                                              }),
                                              _c("span", [
                                                _vm._v(_vm._s(item.Name)),
                                              ]),
                                            ],
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "item.totalBudget",
                                fn: function (ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "edit-td",
                                            attrs: { cols: "6" },
                                          },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                oninput:
                                                  "if(this.value < 0) this.value = 0;",
                                                "hide-details": "",
                                                disabled: _vm.textEditable,
                                                solo: "",
                                                type: "number",
                                              },
                                              on: { change: _vm.budgetCheck },
                                              model: {
                                                value: item.totalBudget,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "totalBudget",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.totalBudget",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "item.dailyCap",
                                fn: function (ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "edit-td",
                                            attrs: { cols: "6" },
                                          },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                oninput:
                                                  "if(this.value < 0) this.value = 0;",
                                                "hide-details": "",
                                                disabled: _vm.textEditable,
                                                solo: "",
                                                type: "number",
                                              },
                                              on: { change: _vm.budgetCheck },
                                              model: {
                                                value: item.dailyCap,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "dailyCap",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.dailyCap",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "item.cpt",
                                fn: function (ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "v-row",
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "edit-td",
                                            attrs: { cols: "6" },
                                          },
                                          [
                                            _c("v-text-field", {
                                              attrs: {
                                                oninput:
                                                  "if(this.value < 0) this.value = 0;",
                                                "hide-details": "",
                                                disabled: _vm.textEditable,
                                                solo: "",
                                                type: "number",
                                              },
                                              on: { change: _vm.budgetCheck },
                                              model: {
                                                value: item.cpt,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "cpt", $$v)
                                                },
                                                expression: "item.cpt",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "no-results",
                                fn: function () {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("appSetting.noRecords"))
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "bottom right sticky-footer" },
                      [
                        _c(
                          "v-card-actions",
                          { staticClass: "justify-end mt-0 pa-4" },
                          [
                            _vm.textEditable
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      width: "127",
                                      color: "primary",
                                      depressed: "",
                                      outlined: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.textEditable = false
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("appSetting.edit")))]
                                )
                              : _vm._e(),
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  width: "127",
                                  color: "primary",
                                  depressed: "",
                                  disabled: _vm.disableButton,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeValues()
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.$t("appSetting.update")))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }