var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "600" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "v-modal-fixed style-2" },
        [
          _c(
            "v-card-title",
            {
              staticClass:
                "headline d-flex justify-space-between px-5 py-3 v-modal-header",
            },
            [
              _c("h5", { staticClass: "text-h6" }, [
                _vm._v(_vm._s(_vm.$t("addKeys.addKeywords"))),
              ]),
              _c(
                "v-icon",
                {
                  staticClass: "black--text",
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v("mdi-close")]
              ),
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "pt-4 pb-4" },
            [
              _c(
                "v-container",
                { staticClass: "stepper-template pa-0", attrs: { fluid: "" } },
                [
                  [
                    _c(
                      "div",
                      { staticClass: "my-2" },
                      [
                        _c(
                          "v-row",
                          [
                            _c(
                              "v-col",
                              { attrs: { cols: "12", sm: "12" } },
                              [
                                _c(
                                  "v-card",
                                  { attrs: { outlined: "", height: "100%" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-center justify-space-between",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "d-flex pa-2" },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "addKeys.addedKeywords"
                                                  )
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "pagination pr-2" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "pagination-total primary--text",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("addKeys.total")
                                                  ) + ": "
                                                ),
                                                _c("strong", [
                                                  _vm._v(
                                                    _vm._s(_vm.keywords.length)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "v-card",
                                      {
                                        staticClass:
                                          "pa-2 pb-2 rounded-0 border-x-0 no-border-bottom",
                                        attrs: { outlined: "", height: "58" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "addkeword" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "d-flex" },
                                              [
                                                _c("v-text-field", {
                                                  staticClass:
                                                    "rounded-0 rounded-l-lg no-field-details mb-0",
                                                  attrs: {
                                                    required: "",
                                                    outlined: "",
                                                    dense: "",
                                                    placeholder: _vm.$t(
                                                      "campaignGenerator.addKeyPlaceholder"
                                                    ),
                                                  },
                                                  on: {
                                                    keyup: function ($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.addKeyword()
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.keywordInput,
                                                    callback: function ($$v) {
                                                      _vm.keywordInput = $$v
                                                    },
                                                    expression: "keywordInput",
                                                  },
                                                }),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass:
                                                      "rounded-0 rounded-r-lg border-l-0 ml-3",
                                                    attrs: {
                                                      width: "36",
                                                      height: "40",
                                                      depressed: "",
                                                      outlined: "",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addKeyword()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "primary--text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "addKeys.add"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._l(_vm.keywords, function (keyword) {
                                      return _c(
                                        "div",
                                        {
                                          key: keyword.text,
                                          staticClass: " px-2 ",
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              staticClass:
                                                "icon-hover primary--text my-0 ",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteKeyword(
                                                    keyword.text
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("mdi-delete-outline")]
                                          ),
                                          _vm._v(
                                            " " + _vm._s(keyword.text) + " "
                                          ),
                                        ],
                                        1
                                      )
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "justify-end mt-7 pa-5" },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    width: "127",
                    color: "grey",
                    depressed: "",
                    outlined: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("addKeys.cancel")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    width: "127",
                    color: "primary",
                    depressed: "",
                    disabled: !_vm.keywords.length,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.save()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("addKeys.apply")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }