






































































































































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import {
  APPS_REPORTS,
  COUNTRIES_FOR_APP,
  TARGETING_KEYWORD_REPORTS,
  APPS_REPORTS_FOR_FILTER
} from 'src/utils/apollo-queries';
import * as moment from 'moment';
import Vue from 'vue';
import {
  APP_SETTINGS,
  APP_SETTINGS_FOR_CAMPAIGN,
  CHANGE_KEYWORD_BID_AMOUNT,
} from 'src/utils/apollo-mutations';
import { COUNTRIES } from 'src/utils/constants';
import { LSS } from '@core/services';
import { KOCHAVA_BUDGET, KOCHAVA_COMPANY } from '@config';

export default Vue.extend({
  name: 'KeywordTable',
  data() {
    const today = moment().format('YYYY-MM-DD');
    const day2MonthsAgo = moment().subtract(3, 'months').format('YYYY-MM-DD');
    return {
      filter: {
        dateRange: [day2MonthsAgo, today],
      },
      searchApp: '',
      search: '',
      saving: false,
      appSettingsDialog: false,
      editDataDialog: false,
      Countries: null,
      selectedApp: null,
      appCountries: [],
      textEditable: false,
      countryListing: [],
      disableButton: false,
    };
  },
  props: {
    appSettingData: {
      type: Object,
      required: false,
    },
  },
  watch: {
    appSettingData() {
      this.appSettingsDialog = true;
    },
  },
  computed: {
    countryListHeaders: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('kai.country'),
            align: 'start',
            value: 'Name',
          },
          {
            text: this.$t('campaignHeaders.totalBudget'),
            value: 'totalBudget',
          },
          {
            text: this.$t('campaignHeaders.dailyCap'),
            value: 'dailyCap',
          },
          {
            text: 'Cpt',
            value: 'cpt',
          },
          // {
          //   text: 'Action',
          //   value: 'action',
          // },
        ];
      },
    },

    appListHeaders: {
      cache: false,
      get() {
        return [
          {
            text: this.$t('appsHeaders.appName'),
            align: 'start',
            value: 'metadata.app.appName',
          },
          {
            text: this.$t('accountHeaders.account'),
            value: 'orgMetadata.orgName',
          },
          {
            text: this.$t('settings.action'),
            value: 'action',
          },
        ];
      },
    },
  },
  apollo: {
    appsReportsForFilter: {
      query: APPS_REPORTS_FOR_FILTER,
      variables() {
        return {
          filter: this.filter,
        };
      },
    },

    countriesForApp: {
      query: COUNTRIES_FOR_APP,
      variables() {
        return {
          adamId: this.selectedApp,
        };
      },
      skip() {
        return !this.selectedApp;
      },
      update(data) {
        this.setCountries(data.countriesForApp);
      },
    },
  },

  methods: {
    budgetCheck() {
      if (LSS.company == KOCHAVA_COMPANY) {
        const budgetLimitExceed = this.appCountries.filter(
          (country) => country.totalBudget > KOCHAVA_BUDGET
        );
        const cptLimitExceed = this.appCountries.filter(
          (country) => country.cpt > KOCHAVA_BUDGET
        );
        const dailyCapLimitExceed = this.appCountries.filter(
          (country) => country.dailyCap > KOCHAVA_BUDGET
        );

        if (
          budgetLimitExceed.length > 0 ||
          cptLimitExceed.length > 0 ||
          dailyCapLimitExceed.length > 0
        ) {
          this.disableButton = true;
          this.$notify.error(
            'No region may have a Total budget, Daily Cap and cpt greater than $' +
              KOCHAVA_BUDGET
          );
        } else {
          this.disableButton = false;
        }
      }
    },
    getIcon(appId) {
      const app = LSS.ownApps.filter((el) => el.adamId == appId);
      return app[0].appIcon || '';
    },
    editData(item) {
      this.Countries = COUNTRIES;
      this.editDataDialog = true;
      this.selectedApp = item.metadata.app.adamId;
    },
    setCountries(data) {
      this.appCountries = [];
      this.countryListing = [];

      if (data[0].valuesForAutoCampaign != null) {
        for (const data of data[0]?.valuesForAutoCampaign)
          this.appCountries.push(data);
      }

      for (const countryCode of data[0].countryOrRegionCodes) {
        for (const country of this.Countries) {
          if (country.Code == countryCode) {
            this.countryListing.push(country);
            for (const ac of this.appCountries) {
              this.countryListing = this.countryListing.filter(
                (cl) => cl.Code !== ac.Code
              );
            }
          }
        }
      }

      for (const data of this.countryListing) {
        this.appCountries.push({
          Code: data.Code,
          Name: data.Name,
          totalBudget: null,
          dailyCap: null,
          cpt: null,
        });
      }
      this.appCountries.sort((a, b) =>
        a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0
      );
    },

    async changeValues() {
      const valuesForAutoCampaignReq = this.appCountries;
      const result = await this.$apollo.mutate({
        mutation: APP_SETTINGS_FOR_CAMPAIGN,
        variables: {
          adamId: this.selectedApp,
          valuesForAutoCampaign: valuesForAutoCampaignReq,
        },
      });

      if (result.data.appSettingsForCampaign.successMessage) {
        this.$notify.success('Data Updated');
        this.close();
      } else {
        this.$notify.error('Error while updating data');
        this.close();
      }
    },

    close() {
      this.editDataDialog = false;
      this.textEditable = false;
    },
  },
});
